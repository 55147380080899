import { OrderFormat } from '@ancon/wildcat-types'

export const SupportedLanguage = {
  English: 'en',
  Swedish: 'sv',
  Norwegian: 'nb',
}

export const LanguageOptions = [
  {
    label: 'English',
    value: SupportedLanguage.English,
  },
  {
    label: 'Svenska',
    value: SupportedLanguage.Swedish,
  },
  {
    label: 'Norsk',
    value: SupportedLanguage.Norwegian,
  },
]

export const DefaultLanguage = SupportedLanguage.Swedish

export enum PortalHostId {
  Modal = 'ModalsPortal',
  Toast = 'ToastsPortal',
}

export enum AppRoutes {
  Home = '/',
  Outlets = '/outlets',
  Outlet = '/outlet',
  Checkout = '/checkout',
  Auth = '/auth',
  OrderStatus = '/order-status',
  QROrderStatus = '/order-status/qr',
  Account = '/account',
  Orders = '/orders',
  GiftCard = '/giftcard',
  PreOrder = '/pre-order',
  GroupOrder = '/group-order',
  PreOrderLink = '/pre-order/[preOrderId]',
  PreOrderManage = '/pre-order/manage',
  PreOrderStatus = '/pre-order/status',
  PayAndGoGroup = '/pay-and-go/[outletId]/group/[groupId]',
  PayAndGoPayment = '/pay-and-go/[outletId]/check/[checkId]/payment',
  PayAndGoStatus = '/pay-and-go/[outletId]/check/[checkId]/status',
}

/*
  NonOrderRoutes are routes that doesn't related to order process.
  - GiftCard: Preview of gift card
*/
export const NonOrderRoutes = [
  AppRoutes.Auth,
  AppRoutes.GiftCard,
  AppRoutes.PreOrderLink,
  AppRoutes.GroupOrder,
]

export const OrderFormatUrlSlug = {
  none: OrderFormat.None,
  'eat-in': OrderFormat.EatIn,
  'take-away': OrderFormat.TakeAway,
  'table-order': OrderFormat.TableOrder,
  delivery: OrderFormat.Delivery,
  'drive-thru': OrderFormat.DriveThru,
  'box-pickup': OrderFormat.BoxPickup,
}

export const GoogleMapsAPIUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places,geocoding,geometry&callback=Function.prototype`

export const OrderFormatSlugMap = {
  [OrderFormat.None]: 'none',
  [OrderFormat.EatIn]: 'eat-in',
  [OrderFormat.TakeAway]: 'take-away',
  [OrderFormat.TableOrder]: 'table-order',
  [OrderFormat.Delivery]: 'delivery',
  [OrderFormat.DriveThru]: 'drive-thru',
  [OrderFormat.BoxPickup]: 'box-pickup',
}

export const NotAvailable = 'N/A'

export const StockholmCoordinates = {
  latitude: 59.32932349999999,
  longitude: 18.0685808,
}

// TODO: Remove this once BoxPickup is supported for AOA
export const AOWAllOrderFormats = OrderFormat.All | OrderFormat.BoxPickup

export const AppTestIds = {
  Home: {
    OutletSearchInput: 'home-page-outlet-inline-search-input',
  },

  FormInputErrorLabel: 'form-input-error-label-', // + fieldName

  Toaster: {
    Toast: 'toast-message-', // + message
  },
  ActiveOrders: 'active-orders',
  OrderSummaryCard: {
    ViewOrderButton: 'order-summary-card-view-order-button',
  },
  OrderListPage: {
    OrderTabButton: 'order-tab-button',
  },

  OutletSearchResultItem: 'account-outlet-search-result-item-', // + index
  Account: {
    BasicDetails: {
      UserNameValue: 'account-basic-details-user-name-value',
      PhoneNumberValue: 'account-basic-details-phone-number-value',
      EmailValue: 'account-basic-details-email-value',

      EditButton: 'account-basic-details-edit-button',
    },
    Addresses: {
      AddAddressButton: 'account-addresses-add-address-button',
      SetAddressButton: 'account-addresses-set-address-button-',
      ConfigureAddressModal: {
        TitleInput: 'account-addresses-configure-address-modal-title-input',
        DeliveryAddress:
          'account-addresses-configure-address-modal-delivery-address',
        AdditionalInstructions:
          'account-addresses-configure-address-modal-additional-instructions',
        SaveButton: 'account-addresses-configure-address-modal-save-button',
        CancelButton: 'account-addresses-configure-address-modal-cancel-button',
      },
      MoreOptionsButton: 'account-addresses-more-options-button',
      DeleteAddressButton:
        'account-addresses-configure-address-modal-delete-address-button',
    },

    EditProfileDetailsModal: {
      CancelButton: 'edit-profile-details-modal-cancel-button',
      SaveButton: 'edit-profile-details-modal-save-button',
    },
  },

  Cart: {
    CartButton: 'navbar-cart-button',
    GoToCheckoutButton: 'go-to-checkout-button',
  },
  NavBar: {
    OutletSearch: 'navbar-outlet-search',

    Dropdown: {
      Option: 'dropdown-option-', // + value
    },

    Button: {
      ProfileName: 'navbar-profile-name-button',
      ProfileDropdown: 'navbar-profile-dropdown-button',
      SignIn: 'navbar-sign-in-button',
    },
  },

  AuthModal: {
    Input: {
      Email: 'auth-modal-email-input',
      Password: 'auth-modal-password-input',
    },
    Button: {
      SignIn: 'auth-modal-sign-in-button',
    },
  },

  UserInfoFields: {
    FirstName: 'checkout-page-order-contact-first-name',
    LastName: 'checkout-page-order-contact-last-name',
    PhoneNumber: 'checkout-page-order-contact-phone-number',
  },

  Checkout: {
    CancelOrderButton: 'checkout-cancel-order-button',
    CancelOrderModalButton: 'checkout-cancel-order-modal-button',
    CancelOrderCountdown: 'checkout-cancel-order-countdown-', // + time
    OrderDiscountButton: 'checkout-order-discount-button',
    OrderDiscountInput: 'checkout-order-discount-input',
    OrderDiscountButtonSubmit: 'checkout-order-discount-button-submit',
    CustomerInfoForm: {
      SubmitButton: 'ContinueButton',
    },
    DeleteOrderButton: 'checkout-delete-order-button',
  },

  deleteOrderModal: {
    DeleteOrderButton: 'delete-order-modal-delete-order-button',
  },

  Order: {
    Modal: {
      OrderFormatButton: 'order-format-button',

      NewOrderTimeConfigureModal: {
        ContinueButton: 'new-order-time-configure-modal-continue-button',
        OrderLaterButton: 'new-order-time-configure-modal-order-later-button',
        Schedule: 'order-configure-time-info-schedule',
        ScheduleOrder: 'order-configure-time-info-schedule-order',
        PickTime: 'order-configure-time-info-time-selection',
        PickDay: 'order-configure-time-info-pick-day',
      },
      ProductFooterForm: {
        AddToCartButton: 'product-footer-form-add-to-cart-button',
      },

      ProductSelection: {
        Container: 'product-selection-container-', // + label
        Selectable: 'product-selection-selectable-', // + label
      },
    },
  },
  ProductConfigurationAccordionHeader: 'product-configuration-accordion-header',
  ProductConfigurationAccordion: 'product-configuration-section-', // + section title add-on
  ProductConfigurationAccordionChildren:
    'product-configuration-section-children-', // + section title add-on
  ProductConfigurationAccordionError: 'product-configuration-section-error',

  Outlet: {
    OutletCardListContainer: 'outlet-outlet-card-list-container',
    LoadMoreButton: 'outlet-load-more-button',
    OutletMenu: {
      ProductList: {
        ProductCard: 'outlet-menu-product-list-product-card',
      },
    },

    Menu: {
      ProductList: {
        ProductCard: 'outlet-menu-product-list-product-card',
      },
    },
  },
  OrderDetailsActionItem: {
    ActionButton: 'order-details-action-item-action-button',
  },

  TableOrderConfigureModal: {
    TableNumberInput: 'table-order-configure-modal-table-number-input',
    ContinueButton: 'table-order-configure-modal-continue-button',
  },

  DeliveryLocationPickerModal: {
    ContinueButton: 'delivery-location-picker-modal-continue-button',
  },
}
