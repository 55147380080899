import { useEffect } from 'react'
import { ProductDetailsAddonIngredient } from '@ancon/wildcat-types'
import { useController, useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { ConfigureAddonIngredientFormState } from '@ancon/wildcat-utils/product/types'

import ProductSelection from '../../components/ProductSelection'
import { SelectableType } from '../../../app/components/Selectable'

type RadioButtonTypeAddonGroupProps = {
  ingredients: ProductDetailsAddonIngredient[]
  addonIndex: number
}

export default function RadioButtonTypeAddonGroup({
  ingredients,
  addonIndex,
}: RadioButtonTypeAddonGroupProps) {
  const addOnSelectedCountFieldName = `selectedAddons.${addonIndex}.selectedCount`
  const addOnSettingsFieldName = `selectedAddons.${addonIndex}.addOnSettings`
  const addOnIngredientsFieldName = `selectedAddons.${addonIndex}.ingredients`
  const addOnSelectedIngredientIdFieldName = `selectedAddons.${addonIndex}.selectedIngredientId`

  const { trigger, setValue, watch, getValues } = useFormContext()
  const addOnSettings = watch(addOnSettingsFieldName)
  const hasFreeAddons = !!addOnSettings.freeAmount

  const { t } = useTranslation('common')
  const {
    field: { value, onChange },
  } = useController({
    name: addOnSelectedIngredientIdFieldName,
    rules: {
      required: t('components.productDetailsModal.mandatoryChoice').toString(),
    },
  })

  useEffect(() => {
    // Trigger Initial Validation Cycle
    trigger(`selectedAddons.${addonIndex}.selectedIngredientId`)
  }, [trigger, addonIndex])

  function handleRadioOptionChange(ingredientId: string) {
    onChange(ingredientId)

    setValue(addOnSelectedCountFieldName, 1)

    // Modify free addon state - Start
    if (hasFreeAddons) {
      const ingredientsFormState: ConfigureAddonIngredientFormState[] =
        getValues(addOnIngredientsFieldName)
      setValue(
        addOnIngredientsFieldName,
        ingredientsFormState.map(ing => ({
          ...ing,
          freeAmount: ing.id === ingredientId ? 1 : 0,
        })),
      )
    }
    // Modify free addon state - End

    // Trigger Validation Cycle
    trigger(`selectedAddons.${addonIndex}.selectedIngredientId`)
  }

  return ingredients.map(({ ingredientName, id, price, currency }) => (
    <ProductSelection
      label={ingredientName}
      key={id}
      selected={value === id}
      selectionType={SelectableType.RadioButton}
      onChange={checked => (checked ? handleRadioOptionChange(id) : () => {})}
      priceLabel={
        hasFreeAddons || price === 0
          ? t('components.productDetailsModal.free')
          : getFormattedCurrency(price, currency)
      }
    />
  ))
}
