import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../app/components/BodyText'
import HeadingText from '../../app/components/HeadingText'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { checkoutUnavailableProductsSelector } from '../store/checkoutSelectors'

import styles from './BoxPickupUnavailableProductList.module.scss'

export default function BoxPickupUnavailableProductList() {
  const { t } = useTranslation('common')

  const unavailableProducts = useAppSelector(
    checkoutUnavailableProductsSelector,
  )

  return (
    !!unavailableProducts.length && (
      <div className={styles.container}>
        <HeadingText as="h3" className={styles.title}>
          {t('boxPickup.unavailableProducts')}
        </HeadingText>
        <div className={styles.productListWrapper}>
          {unavailableProducts.map(product => (
            <div key={product.id} className={styles.productItem}>
              <BodyText>{product.name}</BodyText>
            </div>
          ))}
        </div>
      </div>
    )
  )
}
