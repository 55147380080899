export default async function getCurrentLocationPosition(): Promise<GeolocationCoordinates> {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          resolve(position.coords)
        },
        (positionError: GeolocationPositionError) => {
          reject(new Error(positionError.message))
        },
      )
    })
  }

  return Promise.reject(
    new Error('Geolocation is not supported by this browser.'),
  )
}
