/* eslint-disable no-console */
import { LogLevel, LogStrategy } from '@ancon/wildcat-utils/logger/types'
import type { Event } from '@bugsnag/js'

import bugsnag from '../../bugsnag'
import isAppProduction from '../features/app/utils/isAppProduction'
import isAppDevelopment from '../features/app/utils/isAppDevelopment'

type LogOptions = {
  /** Whether or not to show warning (YellowBox) */
  warn?: boolean
  /** Whether or not to ignore if error is Axios request */
  ignoreIfRequest?: boolean
}

function resolveRequestMetadata(report: Event, properties: any) {
  if (typeof properties !== 'object' || !properties.config) return

  const { url, baseURL, method, params, data, headers } = properties.config

  report.addMetadata('request', {
    url,
    baseURL,
    method,
    params,
    data,
    headers,
  })
}

function resolveResponseMetadata(report: Event, properties: any) {
  if (typeof properties !== 'object' || !properties.response) return

  const { status, headers, data } = properties.response

  report.addMetadata('response', {
    status,
    headers,
    data,
  })
}

function resolveAdditionalDataMetadata(report: Event, properties: any) {
  if (typeof properties !== 'object' || !properties.additionalData) return

  report.addMetadata('additional data', properties.additionalData)
}

class AppLogStrategy implements LogStrategy {
  public client: typeof bugsnag

  constructor() {
    this.client = bugsnag
  }

  execute(
    logLevel: LogLevel,
    message: string,
    properties?: unknown,
    options?: LogOptions,
  ) {
    switch (logLevel) {
      case LogLevel.Error: {
        this.error(message, properties, options)
        break
      }

      case LogLevel.Information:
        this.info(message, properties)
        break

      case LogLevel.Warning:
        this.warn(message, properties)
        break

      default:
        break
    }
  }

  /**
   * Log messages to console
   * @param title - Title message for the log
   * @param properties - Additional properties to log
   */
  info(title: string, properties: any) {
    try {
      if (!isAppProduction()) {
        console.log(title, properties)
      }

      if (isAppDevelopment()) return

      if (properties) {
        /** Bugsnag only accepts object metadata */
        const isObject =
          typeof properties === 'object' && !Array.isArray(properties)

        if (isObject) {
          this.client.leaveBreadcrumb(title, properties)
        } else {
          console.warn(
            '[Logger] leaveBreadcrumb received metadata array, expected object',
          )
          this.client.leaveBreadcrumb(title, { ...properties })
        }
      } else {
        this.client.leaveBreadcrumb(title, undefined, 'log')
      }
    } catch (err) {
      this.error('[Logger] Error when logging info', err as Error)
    }
  }

  /**
   * Send error to Bugsnag and optionally show YellowBox warning
   * @param title Title message for the error
   * @param err Error object to send to Bugsnag
   * @param options Additional options to support logging
   */
  error = (
    title: string,
    properties: any,
    options: LogOptions = {
      warn: true,
      ignoreIfRequest: true,
    },
  ) => {
    const { warn, ignoreIfRequest } = options

    if (warn) {
      console.warn(title, properties)
    }

    if (isAppDevelopment()) {
      return
    }

    this.client.notify(title, report => {
      try {
        if (typeof properties === 'object') {
          // Don't report to Bugsnag if Axios request error, unless ignoreIfRequest = false
          if (ignoreIfRequest && properties.config) {
            return false
          }

          resolveRequestMetadata(report, properties)
          resolveResponseMetadata(report, properties)
          resolveAdditionalDataMetadata(report, properties)
        }

        return true
      } catch (e) {
        return true
      }
    })
  }

  warn = (title: string, objOrString: any) => {
    console.warn(title, objOrString)
  }
}

export default AppLogStrategy
