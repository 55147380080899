import { APIGetRequest, ClientContextResponseV2 } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type ClientContextAPI = {
  get: {
    details: APIGetRequest<ClientContextResponseV2>
  }
}

const clientContext: ClientContextAPI = {
  get: {
    details: new Request(endpoints.core.clientContexts.list).get,
  },
}

export default clientContext
