import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import getConfig from 'next/config'

import isAppDevelopment from './src/features/app/utils/isAppDevelopment'

const { publicRuntimeConfig } = getConfig()

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  releaseStage: isAppDevelopment()
    ? 'development'
    : process.env.NEXT_PUBLIC_ENVIRONMENT,
  appVersion: publicRuntimeConfig.version,
  plugins: [new BugsnagPluginReact(React)],
  maxBreadcrumbs: 50,
})

export default Bugsnag
