import { createSlice } from '@reduxjs/toolkit'

import { AppReducerState } from '../types'
import { DefaultLanguage } from '../constants'

import appReducers from './appReducers'

const appInitialState: AppReducerState = {
  isErrorModalVisible: false,
  errorModalConfig: null,

  language: DefaultLanguage,

  isCartDrawerVisible: false,
  isMobileDrawerVisible: false,
  isSwitchLanguageModalVisible: false,
  orderDrawerOrderId: null,

  isSignalROnline: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: appReducers,
})

export const {
  appSetIsErrorModalVisible,
  appShowError,
  appSetIsCartDrawerVisible,
  appSetOrderDrawerOrderId,
  appSetLanguage,
  appSetIsMobileDrawerVisible,
  appSetIsSwitchLanguageModalVisible,
  appSetIsSignalROnline,
} = appSlice.actions

export default appSlice.reducer
