import React from 'react'
import clsx from 'clsx'
import { Amount, CheckoutDetails, OrderFormat } from '@ancon/wildcat-types'
import getCheckoutGrandTotalAmount from '@ancon/wildcat-utils/checkout/getCheckoutGrandTotalAmount'
import useTranslation from 'next-translate/useTranslation'

import { OrderSummaryTotalsType } from '../../types'

import OrderAmountField from './OrderAmountField'
import OrderGrandTotal from './OrderGrandTotal'
import styles from './OrderTotal.module.scss'

type OrderTotalProps = {
  separateTotal?: boolean
  orderSummaryTotals: OrderSummaryTotalsType
  className?: string
  tip?: Amount & {
    percentage?: number
  }
}

export default function OrderTotal({
  separateTotal = false,
  orderSummaryTotals,
  className,
  tip,
}: OrderTotalProps) {
  const { t } = useTranslation('common')

  const {
    subtotalInclTax,
    minimumOrderDifferenceAmount,
    feeAmountInclTax,
    discount,
    subTotalDiscountInclTax,
    orderFormat,
  } = orderSummaryTotals

  const isDeliveryOrder = orderFormat === OrderFormat.Delivery

  const showDiscount =
    subTotalDiscountInclTax && subTotalDiscountInclTax.amount > 0
  const showMinOrderDifference =
    minimumOrderDifferenceAmount && minimumOrderDifferenceAmount.amount > 0
  const showDeliveryFee = isDeliveryOrder && feeAmountInclTax

  const checkoutTotal = getCheckoutGrandTotalAmount(
    orderSummaryTotals as CheckoutDetails,
    tip?.amount,
  )

  return (
    <div className={clsx(styles.container, className)}>
      {showDiscount && (
        <OrderAmountField
          name={t(
            discount?.name
              ? 'components.order.discountWithName'
              : 'components.order.discount',
            {
              name: discount?.name || '',
            },
          )}
          amount={subTotalDiscountInclTax}
          showAsDeduction
        />
      )}
      {subtotalInclTax && (
        <OrderAmountField
          name={t('components.order.subTotal')}
          amount={subtotalInclTax}
        />
      )}
      {showMinOrderDifference && (
        <OrderAmountField
          name={t('components.order.difference')}
          amount={minimumOrderDifferenceAmount}
        />
      )}
      {showDeliveryFee && (
        <OrderAmountField
          name={t('components.order.delivery')}
          amount={feeAmountInclTax}
        />
      )}
      {tip && (
        <OrderAmountField
          name={
            tip?.percentage
              ? t('components.order.tipWithPercentage', {
                  percentage: tip.percentage,
                })
              : t('components.order.tip')
          }
          amount={tip}
        />
      )}
      <OrderGrandTotal total={checkoutTotal} showSeparator={separateTotal} />
    </div>
  )
}
