import config from '../../config/generated.json'

import isAppWhiteLabeled from './isAppWhiteLabeled'

type ParsedThemeHeaders = {
  'white-label'?: string
}

export default function getWhiteLabeledAppName(): string {
  if (isAppWhiteLabeled()) {
    const headers = config.headers as ParsedThemeHeaders

    return headers?.['white-label'] || ''
  }

  return ''
}
