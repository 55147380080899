import { HTMLAttributes, PropsWithChildren, createElement } from 'react'
import clsx from 'clsx'

import InteractiveDiv from '../InteractiveDiv'

import styles from './Card.module.scss'

interface CardProps
  extends PropsWithChildren<Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>> {
  onClick?: () => void
  disabled?: boolean
}

export default function Card({
  disabled,
  children,
  className,
  onClick,
  ...otherProps
}: CardProps) {
  return createElement(
    onClick != null || disabled != null ? InteractiveDiv : 'div',
    {
      ...otherProps,
      className: clsx(styles.container, className),
      disabled,
      onClick,
    },
    children,
  )
}
