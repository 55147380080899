'use client'

import LocationPinIcon from '@ancon/wildcat-ui/shared/icons/location-pin-outline.svg'
import OutletIcon from '@ancon/wildcat-ui/shared/icons/storefront-outline.svg'
import clsx from 'clsx'
import SearchIcon from '@ancon/wildcat-ui/shared/icons/i-search.svg'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Spinner from '@ancon/wildcat-ui/web/orderweb/Spinner'

import BodyText from '../../../../app/components/BodyText'
import Button from '../../../../app/components/Button'
import { outletsFiltersSelector } from '../../../../outlets/store/outletsSelectors'
import { outletSelectedOutletNameSelector } from '../../../../outlet/store/outletSelector'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { AppRoutes, AppTestIds } from '../../../../app/constants'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { outletsSetVisibleOutletSearchModal } from '../../../../outlets/store/outletsSlice'
import { OutletSearchModalType } from '../../../../outlets/types'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import { locationPendingSelector } from '../../../../location/store/locationSelectors'
import { clientContextIsCompanyUserSelector } from '../../../../clientContext/store/clientContextSelectors'
import {
  isPreOrderCheckoutSelector,
  preOrderValidatePendingSelector,
} from '../../../../preOrder/store/preOrderSelectors'

import styles from './NavbarOutletSearch.module.scss'

type NavbarSearchProps = {
  isInNavBar?: boolean
}

const OutletSearchEnabledPaths: string[] = [
  AppRoutes.Outlets,
  `${AppRoutes.Outlet}/`,
]

if (isAppWhiteLabeled()) {
  OutletSearchEnabledPaths.push('/')
}

export default function NavbarOutletSearch({
  isInNavBar = true,
}: NavbarSearchProps) {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const router = useRouter()

  const isOutletSearchEnabled = useRouteMatch(OutletSearchEnabledPaths)

  const isOutletDetailsPage = useRouteMatch([`${AppRoutes.Outlet}/`])

  const outletsFilters = useAppSelector(outletsFiltersSelector)

  const outletName = useAppSelector(outletSelectedOutletNameSelector)

  const isLocationPending = useAppSelector(locationPendingSelector)

  const isCompanyUser = useAppSelector(clientContextIsCompanyUserSelector)

  const isPreOrderCheckout = useAppSelector(isPreOrderCheckoutSelector)

  const isPreOrderValidatePending = useAppSelector(
    preOrderValidatePendingSelector,
  )

  const isPlace = !!outletsFilters?.location

  const isWhiteLabeled = isAppWhiteLabeled()

  function getSearchText() {
    if (isOutletDetailsPage) {
      return outletName
    }

    if (isPlace && outletsFilters.location?.visibleSearchTerm) {
      return outletsFilters.location.visibleSearchTerm
    }

    if (isWhiteLabeled) {
      return t('components.navBar.setLocation')
    }

    return outletsFilters.searchTerm || t('components.navBar.search')
  }

  function getLeftAccessory() {
    if (isOutletDetailsPage) {
      return <OutletIcon />
    }

    if (isPlace || isWhiteLabeled) {
      return isWhiteLabeled && isLocationPending ? (
        <Spinner size="xSmall" className={styles.spinner} />
      ) : (
        <LocationPinIcon />
      )
    }

    return <SearchIcon />
  }

  function handleOnClick() {
    if (!isOutletDetailsPage) {
      dispatch(
        outletsSetVisibleOutletSearchModal(
          isPlace || isWhiteLabeled
            ? OutletSearchModalType.PlaceSearch
            : OutletSearchModalType.OutletSearch,
        ),
      )
    } else {
      router.replace({
        pathname: AppRoutes.Outlets,
        query: {
          q: outletsFilters.searchTerm,
        },
      })
    }
  }

  if (
    !isOutletSearchEnabled ||
    (isWhiteLabeled && !isOutletDetailsPage && isCompanyUser)
  ) {
    return null
  }

  return (
    <Button
      variant="secondary"
      className={clsx(styles.container, {
        [styles.navBarSearch]: isInNavBar,
        [styles.outletDetailsPage]: isOutletDetailsPage,
        [styles.whiteLabeled]: isWhiteLabeled,
        [styles.hasPreOrderCheckout]:
          isPreOrderCheckout || isPreOrderValidatePending,
      })}
      onClick={handleOnClick}
      outlined={!isInNavBar}
      data-testid={AppTestIds.NavBar.OutletSearch}
    >
      {getLeftAccessory()}
      <BodyText as="span">{getSearchText()}</BodyText>
    </Button>
  )
}
