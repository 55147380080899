import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../app/components/BodyText'
import Button from '../../app/components/Button'
import Modal from '../../app/components/modal/Modal'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  locationPendingSelector,
  locationPermissionModalVisibleSelector,
} from '../store/locationSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { getCurrentGeoPosition } from '../store/locationThunks'
import { locationSetPermissionModalVisible } from '../store/locationSlice'

import styles from './LocationPermissionModal.module.scss'

export default function LocationPermissionModal() {
  const { t } = useTranslation('common')
  const isVisible = useAppSelector(locationPermissionModalVisibleSelector)
  const locationPending = useAppSelector(locationPendingSelector)
  const dispatch = useAppDispatch()

  async function handleGrantPermission() {
    await dispatch(getCurrentGeoPosition()).unwrap()

    dispatch(locationSetPermissionModalVisible(false))
  }

  function handleModalDismiss() {
    dispatch(locationSetPermissionModalVisible(false))
  }

  return (
    <Modal
      title={t('components.locationPermissionModal.title')}
      isOpen={isVisible}
      onClose={handleModalDismiss}
      mobileModalMode="bottom-sheet"
    >
      <div className={styles.modalBody}>
        <BodyText>
          {t('components.locationPermissionModal.description')}
        </BodyText>
        <br />
        <BodyText>
          {t('components.locationPermissionModal.alternativeDescription')}
        </BodyText>
      </div>
      <div className={styles.modalFooter}>
        <Button
          size="large"
          variant="secondary"
          onClick={handleModalDismiss}
          disabled={locationPending}
        >
          {t('components.locationPermissionModal.dismissText')}
        </Button>
        <Button
          size="large"
          onClick={handleGrantPermission}
          loading={locationPending}
        >
          {t('components.locationPermissionModal.primaryActionText')}
        </Button>
      </div>
    </Modal>
  )
}
