import { useCallback, useEffect, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { ScriptStatus, useScript } from '../../app/hooks/useScript'
import { GeoPosition } from '../types'
import getLanguageCode from '../../app/utils/getLanguageCode'
import { GoogleMapsAPIUrl } from '../../app/constants'

export default function useGeocodeService() {
  const { lang } = useTranslation()
  const geocodeService = useRef<google.maps.Geocoder>()

  const scriptStatus = useScript(GoogleMapsAPIUrl)

  const fetchGeocodeResults = useCallback(
    async (geoPosition: GeoPosition) => {
      if (geocodeService.current) {
        try {
          const results = await geocodeService.current.geocode({
            location: { lat: geoPosition.latitude, lng: geoPosition.longitude },
            language: getLanguageCode(lang),
          })

          return results.results?.[0]
        } catch (error) {
          return undefined
        }
      }

      return undefined
    },
    [lang],
  )

  const fetchGeocodeByPlaceId = useCallback(
    async (placeId: string) => {
      if (geocodeService.current) {
        try {
          const results = await geocodeService.current.geocode({
            placeId,
            language: getLanguageCode(lang),
          })
          return results.results?.[0]
        } catch (error) {
          return undefined
        }
      }

      return undefined
    },
    [lang],
  )

  useEffect(() => {
    if (scriptStatus === ScriptStatus.Ready) {
      if (!window.google) {
        throw new Error('Google Maps JavaScript API library must be loaded.')
      }

      if (!window.google.maps.Geocoder) {
        throw new Error(
          'Google Maps Geocoding library must be loaded. Please add `libraries=geocoding` to the src URL',
        )
      }

      geocodeService.current = new window.google.maps.Geocoder()
    }
  }, [scriptStatus])

  return {
    scriptStatus,
    fetchGeocodeResults,
    fetchGeocodeByPlaceId,
  }
}
