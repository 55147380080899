import { PayloadAction } from '@reduxjs/toolkit'

import {
  AccountInformationStatus,
  AuthModalType,
  AuthReducerState,
  ProfileSelectionModalType,
} from '../types'

const authReducers = {
  /**
   * Payload is whether to show the sign in or sign up modal.
   * default is sign in.
   */
  showAuthModal(
    state: AuthReducerState,
    action: PayloadAction<AuthModalType | undefined>,
  ) {
    state.showAuthModal = true
    state.authModalType = action.payload
  },
  hideAuthModal(state: AuthReducerState) {
    state.showAuthModal = false
    state.authModalType = undefined
  },
  setAuthClientSDKInitialized(state: AuthReducerState) {
    state.clientSDKLoaded = true
  },
  setAuthProfileSelectionModalType(
    state: AuthReducerState,
    action: PayloadAction<ProfileSelectionModalType>,
  ) {
    state.profileSelectionModalType = action.payload
  },
  setAuthCompanySignUpModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showCompanySignUpModal = action.payload
  },
  setAuthAccountEditModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showEditAccountModal = action.payload
  },
  setAuthGuestCustomerOnboardingModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showGuestCustomerOnboardingModal = action.payload
  },
  setReviewAccountModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showReviewAccountDetailsModal = action.payload
  },
  setSelectedAccountInformationStatus(
    state: AuthReducerState,
    action: PayloadAction<AccountInformationStatus>,
  ) {
    state.selectedAccountInfoStatus = action.payload
  },
  resetNewAccountDetails(state: AuthReducerState) {
    state.newAccountDetails = null
    state.createdCompanyId = null
  },
}

export default authReducers
