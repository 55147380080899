import {
  APIPostRequest,
  CheckoutAdyenPaymentResponse,
} from '@ancon/wildcat-types'
import { PaymentData } from '@adyen/adyen-web/dist/types/components/types'
import PaymentMethodsResponse from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse'

import Request from '../../client/Request'
import endpoints from '../endpoints'
import { CheckoutInvoicePaymentResponse } from '../../../features/checkout/types'

type PaymentsAPI = {
  post: {
    createAdyenPayment: APIPostRequest<
      CheckoutAdyenPaymentResponse,
      { paymentData: PaymentData; companyId?: string },
      { outletId: string; checkoutId: string }
    >
    createInvoicePayment: APIPostRequest<
      CheckoutInvoicePaymentResponse,
      { companyId: string; invoiceReferenceNote: string },
      { outletId: string; checkoutId: string }
    >
    paymentMethods: APIPostRequest<
      PaymentMethodsResponse,
      {
        channel: string
        shopperLocale: string
      },
      { outletId: string; checkoutId: string }
    >
    details: APIPostRequest<
      CheckoutAdyenPaymentResponse,
      PaymentData,
      { outletId: string; checkoutId: string }
    >
  }
}

const payments: PaymentsAPI = {
  post: {
    createAdyenPayment: new Request(
      endpoints.core.outlets.byId.checkouts.byId.adyen.payments.list,
      { version: 1.2 },
    ).post,
    createInvoicePayment: new Request(
      endpoints.core.outlets.byId.checkouts.byId.invoice.payments.list,
    ).post,
    paymentMethods: new Request(
      endpoints.core.outlets.byId.checkouts.byId.adyen.paymentMethods.list,
    ).post,
    details: new Request(
      endpoints.core.outlets.byId.checkouts.byId.adyen.paymentDetails.list,
      { version: 1.1 },
    ).post,
  },
}

export default payments
