import { CheckDetails, CheckDetailsMetadata } from '@ancon/wildcat-types'

import isPayAndGoSessionMetadata from './isPayAndGoSessionMetadata'

function findPayAndGoMetadataBySessionId(
  check: CheckDetails,
  sessionId: string,
): CheckDetailsMetadata | undefined {
  return check.metadata.find(m => {
    if (!isPayAndGoSessionMetadata(m)) {
      return false
    }

    return m.metadata.sessionId === sessionId
  })
}

export default findPayAndGoMetadataBySessionId
