import { APIGetRequest, APIPostRequest } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type OrderReceiptAPI = {
  post: {
    send: APIPostRequest<void, { email: string }, { orderId: string }>
  }
  get: {
    download: APIGetRequest<Blob>
  }
}

const orderReceipt: OrderReceiptAPI = {
  post: {
    send: new Request(endpoints.core.customers.orders.byId.receipt.list, {
      version: 1.1,
    }).post,
  },
  get: {
    download: new Request(endpoints.core.customers.orders.byId.receipt.pdf.list)
      .get,
  },
}

export default orderReceipt
