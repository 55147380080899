import useTranslation from 'next-translate/useTranslation'
import PalmOutlineIcon from '@ancon/wildcat-ui/shared/icons/palm-outline.svg'

import BodyText from '../../app/components/BodyText'
import HeadingText from '../../app/components/HeadingText'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { productsDetailsAllergyInfoSelector } from '../store/productSelectors'

import styles from './ProductAllergyInformation.module.scss'

export default function ProductAllergyInformation() {
  const { t } = useTranslation('common')

  const allergyInfo = useAppSelector(productsDetailsAllergyInfoSelector)

  return (
    !!allergyInfo && (
      <div className={styles.container}>
        <hr />
        <div className={styles.heading}>
          <PalmOutlineIcon />
          <HeadingText as="h3">
            {t('components.productDetailsModal.allergyInformation')}
          </HeadingText>
        </div>
        <BodyText color="body-2">{allergyInfo}</BodyText>
      </div>
    )
  )
}
