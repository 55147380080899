import { APIGetRequest, OutletDetails } from '@ancon/wildcat-types'

import endpoints from '../endpoints'
import Request from '../../client/Request'

type OutletAPI = {
  get: {
    details: APIGetRequest<OutletDetails, { outletId: string }>
  }
}

const outlet: OutletAPI = {
  get: {
    details: new Request(endpoints.core.outlets.byId.details, {
      version: 1.2,
    }).get,
  },
}

export default outlet
