export enum TipModalType {
  None,
  Info,
  AddTip,
}

export type AddTipState = {
  tipPercentage: number | null
  customTipAmount: number | null
  visibleModalType: TipModalType
  isSkipTipModal: boolean | null
}

export type AddTipFormValues = {
  customTip: string
  isCustomTipInputVisible: boolean
  activeTipIndex: number | null
}

export type AddTipFormConstraints = {
  pattern: {
    tipValue: RegExp
  }
  max: {
    tipValue: number
  }
}
