import { PayloadAction } from '@reduxjs/toolkit'

import { ProductReducerState } from '../types'

const productReducers = {
  setSelectedProductId: (
    state: ProductReducerState,
    action: PayloadAction<string>,
  ) => {
    state.selectedProductId = action.payload
  },
  setProductDetailsModalVisible: (
    state: ProductReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isProductDetailsModalOpen = action.payload
    if (!action.payload && !state.isUpsellGroupModalOpen) {
      state.productDetails = null
      state.selectedProductId = null
    }
  },
  setProductUpsellModalVisible: (
    state: ProductReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isUpsellGroupModalOpen = action.payload
    if (!action.payload) {
      state.productDetails = null
      state.selectedProductId = null
    }
  },
  setProductSearchModalVisible: (
    state: ProductReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.isProductSearchModalOpen = action.payload
  },
}

export default productReducers
