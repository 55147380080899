import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../../app/components/BodyText'
import { PurchaseTermsSectionType } from '../../types'

import styles from './TermsSection.module.scss'

interface TermsSectionProps {
  type: PurchaseTermsSectionType
}

export default function TermsSection({ type }: TermsSectionProps) {
  const { t } = useTranslation('common')

  return (
    <div className={styles.container}>
      <BodyText as="h3">
        {t(`termsOfPurchase.termsSections.${type}.title`)}
      </BodyText>
      <BodyText color="body-1">
        {t(`termsOfPurchase.termsSections.${type}.content`)}
      </BodyText>
    </div>
  )
}
