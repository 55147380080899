import { useEffect, useRef } from 'react'

export default function usePrevious<
  T,
  SetInitialValue extends boolean = boolean,
>(value: T, setInitialValue?: SetInitialValue) {
  const ref = useRef<T | undefined>(
    setInitialValue || false ? value : undefined,
  )

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current as SetInitialValue extends true ? T : T | undefined
}
