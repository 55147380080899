import { createSelector } from '@reduxjs/toolkit'
import { MenuTreeHierarchyProduct } from '@ancon/wildcat-types'
import { isDetailedProductUpSellsOutOfStock } from '@ancon/wildcat-utils/inventory'

import type { RootState } from '../../../store/rootReducer'
import { ProductReducerState } from '../types'

import productEntityAdapter from './productEntityAdapter'

function productSelector<K extends keyof ProductReducerState>(
  state: RootState,
  key: K,
) {
  return state.product[key]
}

const { selectById: productOutletMenuItemById } =
  productEntityAdapter.getSelectors()

// Simple state selector
export const productDetailsPendingSelector = (state: RootState) =>
  productSelector(state, 'fetchProductDetailsPending')

export const productHasDetailsSelector = (state: RootState) =>
  !!productSelector(state, 'productDetails')

export const productDetailsSelector = (state: RootState) =>
  productSelector(state, 'productDetails')

export const productSelectedIdSelector = (state: RootState) =>
  productSelector(state, 'selectedProductId')

export const productOutletMenuItemSelector = (state: RootState) => {
  const selectedProductId = productSelectedIdSelector(state)
  return selectedProductId
    ? productOutletMenuItemById(state.product, selectedProductId)
    : undefined
}

export const productsIsProductDetailsModalOpenSelector = (state: RootState) =>
  productSelector(state, 'isProductDetailsModalOpen')

export const productsIsProductUpsellModalOpenSelector = (state: RootState) =>
  productSelector(state, 'isUpsellGroupModalOpen')

export const isProductSearchModalOpenSelector = (state: RootState) =>
  productSelector(state, 'isProductSearchModalOpen')

export const productsDetailsProductNameSelector = (state: RootState) => {
  const productDetails = productSelector(state, 'productDetails')
  return productDetails?.customerFacingName || productDetails?.name
}

export const productsDetailsDescriptionSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.description

export const productsDetailsAllergyInfoSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.allergyInformation

export const productsDetailsHasVariantsSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.hasVariants

export const productsDetailsImagesSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.images

export const productsDetailsVariantsSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.variants

export const productsDetailsIngredientsSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.ingredients

export const productsDetailsAllowDynamicPricingSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.allowDynamicPricing

export const productsDetailsAddonGroupsSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.addOnGroups

export const productsDetailsMaximumIngredientsForDynamicAddonSelector = (
  state: RootState,
) =>
  productSelector(state, 'productDetails')?.maximumIngredientsForDynamicAddons

export const productDetailsUpsellGroupsSelector = (state: RootState) =>
  productSelector(state, 'productDetails')?.upsellGroups

export const productsDetailsUpsellGroupProductsSelector = (
  state: RootState,
) => {
  const upsellGroups = productDetailsUpsellGroupsSelector(state)

  if (upsellGroups && upsellGroups.length > 0) {
    return upsellGroups[0].products
  }

  return []
}

export const productsUpsellGroupHasProductsSelector = (state: RootState) =>
  !!productsDetailsUpsellGroupProductsSelector(state)?.length

export const productsUpSellGroupIsOutOfStockSelector = (state: RootState) => {
  const productUpSellGroups = productDetailsUpsellGroupsSelector(state)
  return isDetailedProductUpSellsOutOfStock(productUpSellGroups)
}

export const productDetailsVariantByIdSelector = createSelector(
  [productDetailsSelector, (_, variantId) => variantId],
  (productDetails, variantId) =>
    productDetails?.variants.find(v => v.id === variantId),
)

// Adapter selectors
const productEntityAdapterSelectors = productEntityAdapter.getSelectors(
  (state: RootState) => state.product,
)

export const {
  selectById: productByIdSelector,
  selectEntities: productEntitiesSelector,
} = productEntityAdapterSelectors

export const productSelectedMenuGroupProductsSelector = createSelector(
  [productEntitiesSelector, (_, productIds: string[]) => productIds],
  (productEntities, ids) =>
    ids.reduce((acc, id) => {
      const product = productEntities[id]
      if (product) {
        acc.push(product)
      }
      return acc
    }, [] as MenuTreeHierarchyProduct[]),
)
