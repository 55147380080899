import { configureStore } from '@reduxjs/toolkit'
import { Context } from 'next-redux-wrapper'

import rootReducer from './rootReducer'
import { setStore } from './store'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createStore(_context: Context) {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
  })

  setStore(store)

  return store
}

export default createStore
