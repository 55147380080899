import { useRouter } from 'next/router'
import Image from 'next/image'
import clsx from 'clsx'

import InteractiveDiv from '../../InteractiveDiv'
import getHeaderLogoPath from '../../../../app/utils/getHeaderLogoPath'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import getWhiteLabelClassName from '../../../../app/utils/getWhiteLabelClassName'
import { WhiteLabeledAppName } from '../../../../app/types'

import styles from './MainNavbarBrand.module.scss'

const DefaultBrandImageHeight = 40
const DefaultBrandImageWidth = 176

function MainNavbarBrand() {
  const router = useRouter()

  const isWhiteLabeled = isAppWhiteLabeled()

  function handleNavigateToHome() {
    router.push('/')
  }

  const headerLogoPath = getHeaderLogoPath()

  return (
    <InteractiveDiv
      className={clsx(
        styles.navBrand,
        {
          [styles.whiteLabeled]: isWhiteLabeled,
        },
        getWhiteLabelClassName({
          [WhiteLabeledAppName.Corner]: styles.cornerApp,
          [WhiteLabeledAppName.HeatRestauranger]: styles.heatApp,
        }),
      )}
      onClick={handleNavigateToHome}
    >
      <Image
        width={DefaultBrandImageWidth}
        height={DefaultBrandImageHeight}
        alt="logo"
        src={headerLogoPath}
        priority
      />
    </InteractiveDiv>
  )
}

export default MainNavbarBrand
