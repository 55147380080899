import {
  APIGetRequest,
  APIPostRequest,
  CreateMessagingProviderPayload,
  OrderSummary,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type OrderAPI = {
  get: {
    orderStatus: APIGetRequest<
      { orderSummary: OrderSummary },
      { orderId: string }
    >
  }
  post: {
    messagingProvider: APIPostRequest<
      {},
      CreateMessagingProviderPayload,
      {
        orderId: string
      }
    >
  }
}

const order: OrderAPI = {
  get: {
    orderStatus: new Request(endpoints.core.orders.orderStatus.byId.details)
      .get,
  },
  post: {
    messagingProvider: new Request(
      endpoints.core.orders.byId.messagingProviders.list,
    ).post,
  },
}

export default order
