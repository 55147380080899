import type { AppStore } from './types'

let store: AppStore

export function getStore(): AppStore {
  return store
}

export function setStore(nextStore: AppStore) {
  store = nextStore
}
