import { CustomerPreOrderCheckout } from '@ancon/wildcat-types'
import moment from 'moment'

export default function customerPreOrderSortComparer(
  preOrderA: CustomerPreOrderCheckout,
  preOrderB: CustomerPreOrderCheckout,
) {
  return moment(preOrderA.serviceTime.time).diff(
    moment(preOrderB.serviceTime.time),
  )
}
