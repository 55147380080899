import { SupportedLanguage } from '../constants'

/**
 * Supported Language codes of Firebase/Google Maps Places API
 *
 * @see https://developers.google.com/maps/faq#languagesupport
 * @see https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md
 */
const languageCodeMap: Record<string, string> = {
  [SupportedLanguage.English]: 'en',
  [SupportedLanguage.Swedish]: 'sv',
  [SupportedLanguage.Norwegian]: 'no',
}

export default function getLanguageCode(language: string) {
  return languageCodeMap[language] || 'en'
}
