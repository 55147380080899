import { useCallback, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useDebouncedCallback } from 'use-debounce'
import LocationPinIcon from '@ancon/wildcat-ui/shared/icons/location-pin-outline.svg'
import { useRouter } from 'next/router'
import { CustomerAddress } from '@ancon/wildcat-types'

import HeadingText from '../../../../app/components/HeadingText'
import Spinner from '../../../../app/components/Spinner'
import BodyText from '../../../../app/components/BodyText'
import usePlacesAutoComplete from '../../../../location/hooks/usePlacesAutoComplete'
import { AppRoutes } from '../../../../app/constants'

import OutletSearchResultItem from './OutletSearchResultItem'
import styles from './Suggestions.module.scss'
import AccountAddressSuggestions from './AccountAddressSuggestions'
import CurrentLocationSuggestion from './CurrentLocationSuggestion'

type PlacesSuggestionsProps = {
  query?: string
  hideTitle?: boolean
  onClickPlaceSearchResultItem?: (place_id: string, description: string) => void
  onClickAccountAddress?: (
    address: CustomerAddress,
    geocoderResult: google.maps.GeocoderResult,
  ) => void
  onClickCurrentLocation?: (geocoderResult: google.maps.GeocoderResult) => void
}

export default function PlacesSuggestions({
  query,
  hideTitle = false,
  onClickPlaceSearchResultItem,
  onClickCurrentLocation,
  onClickAccountAddress,
}: PlacesSuggestionsProps) {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [searchPending, setSearchPending] = useState(false)
  const { fetchPredictions } = usePlacesAutoComplete()
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const searchPlaces = useCallback(
    async (searchTerm: string) => {
      try {
        const placePredictions = await fetchPredictions(searchTerm)
        setSuggestions(placePredictions.slice(0, 3))
      } catch (err) {
        // TODO: Handle error
      } finally {
        setSearchPending(false)
      }
    },
    [fetchPredictions],
  )

  const debouncedSearch = useDebouncedCallback((term: string) => {
    searchPlaces(term)
  }, 500)

  useEffect(() => {
    if (query) {
      debouncedSearch(query)
    } else {
      debouncedSearch.cancel()
    }

    setSearchPending(!!query)
    setSuggestions([])
  }, [query, debouncedSearch])

  const handlePlaceSearchResultItem = useCallback(
    (place_id: string, description: string) => {
      if (onClickPlaceSearchResultItem) {
        onClickPlaceSearchResultItem(place_id, description)
      } else {
        router.push({
          pathname: AppRoutes.Outlets,
          query: { pl: place_id },
        })
      }
    },
    [onClickPlaceSearchResultItem, router],
  )

  return (
    <div className={styles.sectionContainer}>
      {!hideTitle && (
        <HeadingText as="h3">
          {t('components.outletSearchResultSection.locations')}
        </HeadingText>
      )}
      {searchPending && (
        <div className={styles.loaderContainer}>
          <Spinner size="small" />
          <BodyText>
            {t('components.outletSearchResultSection.locationsLoading')}
          </BodyText>
        </div>
      )}
      {query && !searchPending && suggestions.length === 0 && (
        <div className={styles.emptyContainer}>
          <BodyText color="body-1">
            {t('components.outletSearchResultSection.noLocationsFound')}
          </BodyText>
        </div>
      )}

      {onClickCurrentLocation && !query && (
        <CurrentLocationSuggestion
          label={t('components.deliveryLocationPickerModal.currentLocation')}
          onClick={onClickCurrentLocation}
        />
      )}
      {onClickAccountAddress && !query && (
        <AccountAddressSuggestions onAddressClick={onClickAccountAddress} />
      )}
      {suggestions.map(({ place_id, description }) => (
        <OutletSearchResultItem
          onClick={() => handlePlaceSearchResultItem(place_id, description)}
          key={place_id}
          leftAccessory={<LocationPinIcon />}
        >
          {description}
        </OutletSearchResultItem>
      ))}
    </div>
  )
}
