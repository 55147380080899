import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { NotificationState } from '../types'

import { initializeOneSignal } from './notificationThunks'

const notificationInitialState: NotificationState = {
  isInitialized: false,
  isPermissionGranted: false,
  isSubscribed: false,
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    setNotificationSubscribed(state, action: PayloadAction<boolean>) {
      state.isSubscribed = !!action.payload
    },
    setNotificationPermissionGranted(state, action: PayloadAction<boolean>) {
      state.isPermissionGranted = !!action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(initializeOneSignal.pending, state => {
      state.isInitialized = false
    })
    builder.addMatcher(
      isAnyOf(initializeOneSignal.fulfilled, initializeOneSignal.rejected),
      state => {
        state.isInitialized = true
      },
    )
  },
})

export const { setNotificationSubscribed, setNotificationPermissionGranted } =
  notificationSlice.actions

export default notificationSlice.reducer
