import useTranslation from 'next-translate/useTranslation'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import { useEffect } from 'react'
import { CheckoutStatus } from '@ancon/wildcat-types'
import wait from '@ancon/wildcat-utils/shared/wait'
import { useRouter } from 'next/router'

import Modal from '../../../app/components/modal/Modal'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  clientContextCustomerIdSelector,
  clientContextIsAnonymousCustomerSelector,
} from '../../../clientContext/store/clientContextSelectors'
import { checkoutSummaryCheckoutStatusSelector } from '../../store/checkoutSelectors'
import { authGuestCustomerOnboardingModalVisibleSelector } from '../../../auth/store/authSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { setAuthGuestCustomerOnboardingModalVisible } from '../../../auth/store/authSlice'

import styles from './GuestCustomerOnboardingModal.module.scss'
import OrderStatusCreateAccountForm from './GuestCustomerOnboardingForm'

const GUEST_LINK_MODAL_WAIT_TIME = 4000

export default function GuestCustomerOnboardingModal() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')
  const isAnonymousCustomer = useAppSelector(
    clientContextIsAnonymousCustomerSelector,
  )
  const customerId = useAppSelector(clientContextCustomerIdSelector)
  const checkoutStatus = useAppSelector(checkoutSummaryCheckoutStatusSelector)
  const isOpen = useAppSelector(authGuestCustomerOnboardingModalVisibleSelector)
  const { query } = useRouter()

  function handleCloseModal() {
    dispatch(setAuthGuestCustomerOnboardingModalVisible(false))
  }

  useEffect(() => {
    async function showGuestLinkModal() {
      await wait(GUEST_LINK_MODAL_WAIT_TIME)
      dispatch(setAuthGuestCustomerOnboardingModalVisible(true))
    }

    const isSameUser = query?.uId === customerId

    if (
      isAnonymousCustomer &&
      customerId &&
      isSameUser &&
      checkoutStatus === CheckoutStatus.InProgress
    ) {
      showGuestLinkModal()
    }
  }, [checkoutStatus, customerId, query?.uId, dispatch, isAnonymousCustomer])

  return (
    <Modal
      isOpen={isOpen}
      mobileModalMode="bottom-sheet"
      onClose={handleCloseModal}
      title={t('components.createAccountModal.title')}
      className={styles.modal}
    >
      <BodyText className={styles.infoText} color="body-1" fontSize="1.6rem">
        {t('components.createAccountModal.info')}
      </BodyText>
      <OrderStatusCreateAccountForm onClickClose={handleCloseModal} />
    </Modal>
  )
}
