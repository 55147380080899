import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Portal from '../../app/components/Portal'
import { PortalHostId } from '../../app/constants'
import ToastContext from '../ToasterContext'
import { ToastCallback, ToastOptions, ToastProps, ToastVariant } from '../types'

import Toast from './Toast'
import styles from './Toaster.module.scss'

function Toaster() {
  const [toasts, setToasts] = useState<ToastProps[]>([])

  useEffect(() => {
    function generateId(): string {
      return `toast-${Math.random().toString()}-${Date.now().toString()}`
    }

    function createToastCallback(variant: ToastVariant): ToastCallback {
      return (title: string, message?: string, options?: ToastOptions) => {
        const { id, onClick } = options ?? {}

        const toastId = id ?? generateId()

        const toastProps: ToastProps = {
          id: toastId,
          variant,
          title,
          message,
          onClick,
        }

        setToasts(prev => [...prev, toastProps])
      }
    }

    Toast.info = createToastCallback('info')
    Toast.success = createToastCallback('success')
    Toast.error = createToastCallback('error')
  }, [])

  const removeToast = useCallback((id: string) => {
    setToasts(prev => prev.filter(toastConfig => toastConfig.id !== id))
  }, [])

  const value = useMemo(
    () => ({
      removeToast,
    }),
    [removeToast],
  )

  return (
    <Portal hostId={PortalHostId.Toast}>
      <ToastContext.Provider value={value}>
        {toasts.length > 0 && (
          <div className={styles.toastsContainer}>
            {toasts.map(toastProps => (
              <Toast
                key={toastProps.id}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...toastProps}
              />
            ))}
          </div>
        )}
      </ToastContext.Provider>
    </Portal>
  )
}

export default Toaster
