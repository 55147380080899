import {
  CustomerAddress,
  CustomerAddressType,
  TranslateFn,
} from '@ancon/wildcat-types'

type AccountAddressStateType = {
  homeAddress?: CustomerAddress
  workAddress?: CustomerAddress
  otherAddresses: CustomerAddress[]
}

export default function getGroupedAddressTypes(
  t: TranslateFn,
  addresses?: CustomerAddress[],
): AccountAddressStateType {
  return (addresses || []).reduce(
    (acc, address) => {
      switch (address.addressType) {
        case CustomerAddressType.Home: {
          acc.homeAddress = {
            ...address,
            title: t('common:customerAddressTypes.home'),
          }
          return acc
        }
        case CustomerAddressType.Work:
          acc.workAddress = {
            ...address,
            title: t('common:customerAddressTypes.work'),
          }
          return acc
        case CustomerAddressType.Other:
        default:
          acc.otherAddresses ??= []
          acc.otherAddresses.push(address)
          return acc
      }
    },
    {
      otherAddresses: [],
    } as AccountAddressStateType,
  )
}
