import { useMemo } from 'react'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { Ingredient, IngredientReplacement } from '@ancon/wildcat-types'
import { useController, useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'

import useAppSelector from '../../../store/hooks/useAppSelector'
import { SelectableType } from '../../app/components/Selectable'
import ProductConfigurationAccordion from '../components/ProductConfigurationAccordion'
import ProductSelection from '../components/ProductSelection'
import {
  productsDetailsAllowDynamicPricingSelector,
  productsDetailsIngredientsSelector,
} from '../store/productSelectors'
import { ConfigureProductFormState } from '../types'
import { ProductConfigureAccordionId } from '../constants'

import styles from './ProductIngredientsForm.module.scss'

type ProductIngredientProp = {
  index: number
  ingredient: Ingredient
}

function ProductIngredient({ index, ingredient }: ProductIngredientProp) {
  const { setValue } = useFormContext<ConfigureProductFormState>()
  const allowDynamicPricing = useAppSelector(
    productsDetailsAllowDynamicPricingSelector,
  )
  const {
    ingredientId: mainIngredientId,
    ingredientName,
    hasReplacements,
    price: mainIngredientPrice,
    isMandatory,
    replacements,
    position,
  } = ingredient

  const {
    field: { value: selectedValue, onChange },
  } = useController({
    name: `selectedIngredients.${index}.selected`,
  })
  const {
    field: { value: replacementIdValue },
  } = useController({
    name: `selectedIngredients.${index}.replacementId`,
  })

  function handleCheckChange(checked: boolean) {
    setValue(`selectedIngredients.${index}.selected`, checked)
    onChange(checked)

    if (!checked) {
      setValue(`selectedIngredients.${index}.replacementId`, null)
    }
  }

  function handleReplacementChange(replacementId: string) {
    const isMainIngredient = replacementId === mainIngredientId

    setValue(`selectedIngredients.${index}`, {
      id: mainIngredientId,
      selected: true,
      replacementId: isMainIngredient ? null : replacementId,
      position,
    })
    onChange(true)
  }

  const replacementsList = useMemo(
    () =>
      [
        {
          ingredientId: mainIngredientId,
          ingredientName,
        } as IngredientReplacement,
      ].concat(replacements),
    [mainIngredientId, replacements, ingredientName],
  )

  return (
    <>
      <ProductSelection
        label={ingredientName}
        onChange={handleCheckChange}
        selected={isMandatory || selectedValue}
        selectionType={SelectableType.CheckBox}
        disabled={isMandatory}
        priceLabel={
          allowDynamicPricing && !hasReplacements
            ? // FIXME: Update Ingredient API to return price and currency
              getFormattedCurrency(mainIngredientPrice, 'SEK')
            : undefined
        }
      />
      {hasReplacements && (
        <div className={styles.replacementsContainer}>
          {replacementsList.map(
            ({
              ingredientId: replacementIngredientId,
              ingredientName: replacementName,
              price,
              currency,
            }) => (
              <ProductSelection
                key={replacementIngredientId}
                label={replacementName}
                onChange={() =>
                  handleReplacementChange(replacementIngredientId)
                }
                selected={
                  (selectedValue
                    ? replacementIdValue || mainIngredientId
                    : undefined) === replacementIngredientId
                }
                selectionType={SelectableType.RadioButton}
                disabled={isMandatory}
                priceLabel={
                  (allowDynamicPricing &&
                    replacementIngredientId === mainIngredientId) ||
                  replacementIngredientId !== mainIngredientId
                    ? getFormattedCurrency(
                        replacementIngredientId !== mainIngredientId
                          ? price
                          : mainIngredientPrice,
                        currency,
                      )
                    : undefined
                }
              />
            ),
          )}
        </div>
      )}
    </>
  )
}

export default function ProductIngredientsForm() {
  const { t } = useTranslation('common')

  const { watch, setValue } = useFormContext<ConfigureProductFormState>()

  const activeAccordionId = watch('activeAccordionId')

  const ingredients = useAppSelector(productsDetailsIngredientsSelector)!

  function handleToggleAccordion(isExpanded: boolean) {
    if (isExpanded) {
      const addonAccordionId = ProductConfigureAccordionId.Ingredients
      setValue('activeAccordionId', addonAccordionId)
    }
  }

  return (
    <ProductConfigurationAccordion
      title={t('components.productDetailsModal.choiceOfIngredients')}
      expanded={activeAccordionId === ProductConfigureAccordionId.Ingredients}
      onToggle={handleToggleAccordion}
    >
      {ingredients.map((ingredient, index) => (
        <ProductIngredient
          ingredient={ingredient}
          index={index}
          key={ingredient.id}
        />
      ))}
    </ProductConfigurationAccordion>
  )
}
