import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import BodyText from '../../../app/components/BodyText'

import styles from './AnconContactInformation.module.scss'

const AnconContactInfo = {
  organizationName: 'Ancon AB',
  organizationNo: '556257-3039',
  homeSite: 'www.ancon.io',
  homeSiteURL: 'https://www.ancon.io',
  telephone: '010-4050900',
  addressLine1: 'Kungsgatan 11',
  addressLine2: '451 30 Uddevalla',
}

export default function AnconContactInformation() {
  const { t } = useTranslation('common')

  return (
    <div className={styles.container}>
      <BodyText as="h3">{t('termsOfPurchase.contactInformation')}</BodyText>
      <BodyText color="body-1">{AnconContactInfo.organizationName}</BodyText>
      <BodyText color="body-1">
        {t('termsOfPurchase.organizationNo', {
          organizationNo: AnconContactInfo.organizationNo,
        })}
      </BodyText>
      <Link
        className={styles.webLink}
        href={AnconContactInfo.homeSiteURL}
        target="blank"
      >
        <BodyText color="body-1">{AnconContactInfo.homeSite}</BodyText>
      </Link>
      <BodyText color="body-1">{AnconContactInfo.telephone}</BodyText>
      <BodyText color="body-1">{AnconContactInfo.addressLine1}</BodyText>
      <BodyText color="body-1">{AnconContactInfo.addressLine2}</BodyText>
    </div>
  )
}
