import clsx from 'clsx'
import { ServiceTimeKindType } from '@ancon/wildcat-types'
import { PropsWithChildren } from 'react'

import Button from '../../../../app/components/Button'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { outletFiltersServiceTimeKindTypeSelector } from '../../../../outlet/store/outletSelector'

import styles from './NavBarOrderTimeSwitchButton.module.scss'

type NavBarOrderTimeSwitchButtonProps = PropsWithChildren<{
  type: ServiceTimeKindType
  isInNavBar: boolean
  onClick?: (type: ServiceTimeKindType) => void
  disabled?: boolean
}>

export default function NavBarOrderTimeSwitchButton({
  type,
  isInNavBar = false,
  children,
  onClick,
  disabled = false,
}: NavBarOrderTimeSwitchButtonProps) {
  const selectedServiceTimeKindType = useAppSelector(
    outletFiltersServiceTimeKindTypeSelector,
  )

  function handleOnClick() {
    onClick?.(type)
  }

  return (
    <Button
      variant="secondary"
      className={clsx(styles.button, {
        [styles.inNavBar]: isInNavBar,
        [styles.selected]: selectedServiceTimeKindType === type,
        [styles.disabled]: disabled,
      })}
      onClick={handleOnClick}
      disabled={disabled}
    >
      <span className={styles.serviceTimeData}>{children}</span>
    </Button>
  )
}
