import { WhiteLabeledAppName } from '../types'

import getWhiteLabeledAppName from './getWhiteLabeledAppName'
import isAppWhiteLabeled from './isAppWhiteLabeled'

type WhiteLabelAppsClassValues = {
  [P in WhiteLabeledAppName]?: string
}

/**
 * Get the class name related to the current white labeled app from the given class names object
 *
 * @param classValues The white label class names object from the invoking component
 * @returns The class name for the current white labeled app
 */
export default function getWhiteLabelClassName(
  classValues: WhiteLabelAppsClassValues,
) {
  const isWhiteLabeled = isAppWhiteLabeled()

  const whiteLabeledAppName = getWhiteLabeledAppName()

  if (isWhiteLabeled) {
    return classValues[whiteLabeledAppName as WhiteLabeledAppName]
  }

  return undefined
}
