import clsx from 'clsx'
import HamburgerIcon from '@ancon/wildcat-ui/shared/icons/menu.svg'

import Container from '../../container/Container'
import OutletSearchModal from '../../../../outlets/components/searchOutlets/OutletSearchModal'
import OrderFormatSelectionModal from '../../../../outlets/components/OrderFormatSelectionModal'
import ClientAuthContainer from '../../../../auth/components/ClientAuthContainer'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import {
  clientContextCustomerIdSelector,
  clientContextIsCompanyUserSelector,
} from '../../../../clientContext/store/clientContextSelectors'
import CustomerEvents from '../../../../../events/CustomerEvents'
import OrderProduct from '../../../../product/components/OrderProduct'
import DeleteOrderModal from '../../../../checkout/components/DeleteOrderModal'
import Button from '../../../../app/components/Button'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { appSetIsMobileDrawerVisible } from '../../../../app/store/appSlice'
import GuestCustomerOnboardingModal from '../../../../checkout/components/guestCustomerOnboardingModal/GuestCustomerOnboardingModal'
import { Toaster } from '../../../../notification'
import SwitchLanguageModal from '../../../../app/components/switchLanguageModal/SwitchLanguageModal'
import LocationPermissionModal from '../../../../location/components/LocationPermissionModal'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { NonOrderRoutes } from '../../../../app/constants'
import ErrorModal from '../../../../app/components/errorModal/ErrorModal'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import {
  isPreOrderCheckoutSelector,
  preOrderValidatePendingSelector,
} from '../../../../preOrder/store/preOrderSelectors'
import OneSignalInitializer from '../../../../notification/components/OneSignalInitializer'

import styles from './MainNavbar.module.scss'
import NavActions from './NavActions'
import NavbarOutletSearch from './NavbarOutletSearch'
import NavbarOrderFormatFilter from './NavbarOrderFormatFilter'
import NavBarOrderTimeSwitch from './NavBarOrderTimeSwitch'
import MainNavbarBrand from './MainNavbarBrand'
import NavbarBackToCompanyDashboard from './NavbarBackToCompanyDashboard'

function MainNavbar() {
  const dispatch = useAppDispatch()
  const customerId = useAppSelector(clientContextCustomerIdSelector)
  const isPreOrder = useAppSelector(isPreOrderCheckoutSelector)
  const isPreOrderValidatePending = useAppSelector(
    preOrderValidatePendingSelector,
  )
  const isCompanyUser = useAppSelector(clientContextIsCompanyUserSelector)
  const isWhiteLabeled = isAppWhiteLabeled()

  const isNonOrderRoute = useRouteMatch(NonOrderRoutes)

  const isCompanyDashboard = !!isCompanyUser && isWhiteLabeled

  function handleOpenMobileNavBar() {
    dispatch(appSetIsMobileDrawerVisible(true))
  }

  return (
    <nav
      className={clsx(styles.navContainer, {
        [styles.whiteLabeled]: isWhiteLabeled,
      })}
    >
      <Container fluid>
        <div className={styles.navLeftContainer}>
          {!isNonOrderRoute && (
            <Button
              className={styles.navToggle}
              onClick={handleOpenMobileNavBar}
            >
              <HamburgerIcon />
            </Button>
          )}
          {!isWhiteLabeled && <MainNavbarBrand />}
          {/* Popups */}
          <Toaster />
          {!isNonOrderRoute && (
            <ClientAuthContainer fallbackSpinning={false}>
              <div className={styles.actionButtonWrapper}>
                {isCompanyDashboard ? (
                  <NavbarBackToCompanyDashboard />
                ) : (
                  <NavbarOutletSearch />
                )}
                {!isPreOrder && !isPreOrderValidatePending && (
                  <>
                    <NavbarOrderFormatFilter />
                    <NavBarOrderTimeSwitch />
                  </>
                )}
              </div>
              {/* Modals */}
              <OutletSearchModal />
              <OrderFormatSelectionModal />
              <OrderProduct />
              <DeleteOrderModal />
              <GuestCustomerOnboardingModal />
              <SwitchLanguageModal />
              <LocationPermissionModal />
              <ErrorModal />
              {/* SignalR Listeners */}
              {customerId && <CustomerEvents />}
              {/* OneSignal */}
              {customerId && <OneSignalInitializer />}
            </ClientAuthContainer>
          )}
        </div>
        {isWhiteLabeled && (
          <div className={styles.navMiddleContainer}>
            <MainNavbarBrand />
          </div>
        )}
        <div className={styles.navRightContainer}>
          <div className={styles.navActions}>
            <NavActions />
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default MainNavbar
