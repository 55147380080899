import React from 'react'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import { appErrorModalConfigSelector } from '../../store/appSelectors'
import { ErrorModalType } from '../../types'
import BoxPickupUnavailableProductList from '../../../checkout/components/BoxPickupUnavailableProductList'

export default function ErrorModalAdditionalInfo() {
  const errorModalConfig = useAppSelector(appErrorModalConfigSelector)

  switch (errorModalConfig?.errorModalType) {
    case ErrorModalType.CheckoutBoxPickupUnAvailableProducts:
      return <BoxPickupUnavailableProductList />
    default:
      return null
  }
}
