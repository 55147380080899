import { useState } from 'react'

function useToggleState(
  initialState: boolean = false,
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [state, setState] = useState<boolean>(initialState)

  function toggle() {
    setState(prev => !prev)
  }

  return [state, toggle, setState]
}

export default useToggleState
