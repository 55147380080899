import useTranslation from 'next-translate/useTranslation'
import UserGroupIcon from '@ancon/wildcat-ui/shared/icons/user-group-outline.svg'

import BodyText from '../../app/components/BodyText'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { preOrderApproxNoOfAttendeesSelector } from '../store/preOrderSelectors'

import styles from './PreOrderAttendeesCountTag.module.scss'

export default function PreOrderAttendeesCountTag() {
  const { t } = useTranslation('common')

  const noOfAttendees = useAppSelector(preOrderApproxNoOfAttendeesSelector)

  return (
    <div className={styles.container}>
      <UserGroupIcon />
      <BodyText as="span">
        {t('preOrder.attendeesCount', {
          count: noOfAttendees,
        })}
      </BodyText>
    </div>
  )
}
