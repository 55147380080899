import { APIParams } from '@ancon/wildcat-types'

function createUrlParams(params?: APIParams) {
  return (
    params &&
    Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )
}

function getBaseURL(): string {
  if (typeof window === 'undefined') {
    // Running on the server
    if (!process.env.INTERNAL_MEDIA_API_URL) {
      throw new Error(
        'INTERNAL_MEDIA_API_URL is not defined in the server environment',
      )
    }

    return process.env.INTERNAL_MEDIA_API_URL
  }

  // Running on the client
  if (!process.env.NEXT_PUBLIC_MEDIA_API_URL) {
    throw new Error(
      'NEXT_PUBLIC_MEDIA_API_URL is not defined in the client environment',
    )
  }

  return process.env.NEXT_PUBLIC_MEDIA_API_URL
}

export default function getImageUrl(
  imageId: string,
  params?: APIParams,
  version: number = 1,
) {
  const queryParams = createUrlParams(params)

  return `${getBaseURL()}/v${version}/media/${imageId}${queryParams ? `?${queryParams}` : ''}`
}
