import {
  APIGetRequest,
  DayOfWeek,
  MenuTreeHierarchy,
  MenuTreeHierarchyProduct,
  OrderFormat,
  PaginatedResponse,
  SystemVisibility,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type MenuTreeAPIParams = {
  menuTreeId: string
  outletId: string
  dayOfWeek?: DayOfWeek
  orderFormats?: OrderFormat
  purchaseOrderFormat?: OrderFormat
  orderTime?: string
  systemVisibility?: SystemVisibility
}

type MenuTreesAPI = {
  get: {
    hierarchy: APIGetRequest<MenuTreeHierarchy, MenuTreeAPIParams>
    products: APIGetRequest<
      PaginatedResponse<MenuTreeHierarchyProduct>,
      MenuTreeAPIParams & {
        search?: string
        offset?: number
        limit?: number
      }
    >
  }
}

const menuTrees: MenuTreesAPI = {
  get: {
    hierarchy: new Request(endpoints.core.menuTrees.byId.hierarchy.list, {
      version: 1.1,
    }).get,
    products: new Request(endpoints.core.menuTrees.byId.products.list, {
      version: 1.1,
    }).get,
  },
}

export default menuTrees
