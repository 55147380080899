import useTranslation from 'next-translate/useTranslation'

import EmptyPlaceholder from '../emptyPlaceholder/EmptyPlaceholder'
import Button from '../Button'

import styles from './ErrorView.module.scss'

type ErrorViewProps = {
  error?: Error
  info?: React.ErrorInfo
  clearError?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ErrorView(_: ErrorViewProps) {
  const { t } = useTranslation('common')

  function handleRefreshPage() {
    window.location.reload()
  }

  function goToHomeClick() {
    window.location.replace('/')
  }

  return (
    <EmptyPlaceholder
      className={styles.container}
      title={t('components.errorView.title')}
      message={t('components.errorView.message')}
      footer={
        <div className={styles.footerActions}>
          <Button onClick={handleRefreshPage}>
            {t('components.errorView.refreshPage')}
          </Button>
          <Button variant="secondary" onClick={goToHomeClick}>
            {t('components.errorView.goToHome')}
          </Button>
        </div>
      }
    />
  )
}
