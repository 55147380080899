import { PayloadAction } from '@reduxjs/toolkit'

import { ClientContextState } from '../types'

const clientContextReducers = {
  clientContextSetCurrentCompanyUserId(
    state: ClientContextState,
    action: PayloadAction<string | null>,
  ) {
    state.currentCompanyUserId = action.payload
  },
}

export default clientContextReducers
