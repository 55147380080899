import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close-x.svg'
import ErrorIcon from '@ancon/wildcat-ui/shared/icons/i-close-x.svg'
import SuccessIcon from '@ancon/wildcat-ui/shared/icons/check.svg'
import InfoIcon from '@ancon/wildcat-ui/shared/icons/info-fill.svg'

import Button from '../../app/components/Button'
import BodyText from '../../app/components/BodyText'
import ToasterContext, { ToasterContextValue } from '../ToasterContext'
import {
  Draggable,
  DraggableDirection,
  ToastAnimation,
  ToastCallback,
  ToastEvent,
  ToastProps,
  ToastVariant,
} from '../types'
import {
  ToastAutoCloseTimeout,
  ToastCollapseDuration,
  ToastDraggablePercentage,
} from '../constants'
import getToastDragX from '../utils/getToastDragX'

import styles from './Toast.module.scss'

type ToastComponentType = React.FC<ToastProps> & {
  info: ToastCallback
  success: ToastCallback
  error: ToastCallback
}

function getToastVariantIcon(variant: ToastVariant) {
  switch (variant) {
    case 'success':
      return SuccessIcon

    case 'error':
      return ErrorIcon

    case 'info':
    default:
      return InfoIcon
  }
}

const Toast: ToastComponentType = ({
  id,
  variant,
  title,
  message,
  onClick,
}: ToastProps) => {
  const { removeToast } = useContext<ToasterContextValue>(ToasterContext)

  const [animation, setAnimation] = useState<ToastAnimation | null>(null)

  const preventExitAnimation = useRef<boolean>(false)

  const toastRef = useRef<HTMLDivElement | null>(null)

  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const drag = useRef<Draggable>({
    start: 0,
    x: 0,
    distance: 0,
    direction: DraggableDirection.Left,
    dragging: false,
  }).current

  const entering = animation === ToastAnimation.Entering

  const exiting = animation === ToastAnimation.Exiting

  useLayoutEffect(() => {
    function onEnter() {
      setAnimation(ToastAnimation.Entering)
    }

    onEnter()
  }, [])

  function handleExit() {
    if (!preventExitAnimation.current) {
      setAnimation(ToastAnimation.Exiting)
    }
  }

  useEffect(() => {
    const timer = timerRef.current

    if (id && !timer) {
      timerRef.current = setTimeout(handleExit, ToastAutoCloseTimeout)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [id])

  function getDragRemovalDistance() {
    const toast = toastRef.current!

    return toast.clientWidth * ToastDraggablePercentage
  }

  function deleteToast() {
    removeToast?.(id)
  }

  function collapseToast(finished: () => void) {
    const toast = toastRef.current

    if (toast) {
      const { clientHeight, style } = toast

      requestAnimationFrame(() => {
        style.height = `${clientHeight}px`
        style.transition = `all ${ToastCollapseDuration}ms`

        requestAnimationFrame(() => {
          style.height = '0'
          style.padding = '0'
          style.margin = '0'
          setTimeout(finished, ToastCollapseDuration)
        })
      })
    } else {
      finished()
    }
  }

  function handleCloseClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    handleExit()
  }

  function handleAnimationEnd() {
    if (entering) {
      setAnimation(null)
    } else if (exiting) {
      collapseToast(deleteToast)
    }
  }

  function handleDragStart(event: ToastEvent.Drag) {
    // Prevent default swipe behavior for iOS safari
    if (event.nativeEvent.type === 'touchstart') {
      event.nativeEvent.preventDefault()
    }

    const x = getToastDragX(event)
    drag.start = x
    drag.x = x
    drag.distance = 0
    drag.dragging = true

    const toast = toastRef.current

    if (toast) {
      toast.style.transition = ''
    }
  }

  function resetDragTransform() {
    const toast = toastRef.current

    if (toast) {
      toast.style.transition = 'transform 0.2s, opacity 0.2s'
      toast.style.transform = 'translateX(0)'
      toast.style.opacity = '1'
    }
  }

  function handleMouseLeave() {
    if (drag.dragging) {
      drag.dragging = false
      resetDragTransform()
    }
  }

  function handleDragEnd() {
    const toast = toastRef.current

    if (drag.dragging) {
      drag.dragging = false

      if (drag.distance > 0 && toast) {
        if (
          drag.distance > getDragRemovalDistance() &&
          drag.direction === DraggableDirection.Left
        ) {
          preventExitAnimation.current = true
          collapseToast(deleteToast)
        } else {
          resetDragTransform()
        }
      }
    }
  }

  function handleDragMove(event: ToastEvent.Drag) {
    const toast = toastRef.current!

    if (drag.dragging && toast) {
      // Update new drag x position when moving
      drag.x = getToastDragX(event)

      const delta = drag.x - drag.start
      drag.distance = Math.abs(drag.x - drag.start)
      drag.direction =
        delta > 0 ? DraggableDirection.Right : DraggableDirection.Left

      // Prevent moving toast to the right
      if (drag.direction === DraggableDirection.Left) {
        toast.style.transform = `translateX(${-drag.distance}px)`
        // Set opacity relative to drag distance
        toast.style.opacity = `${1 - drag.distance / getDragRemovalDistance()}`
      }
    }
  }

  const ToastVariantIcon = getToastVariantIcon(variant)

  const variantColorClass = clsx({
    [styles.success]: variant === 'success',
    [styles.error]: variant === 'error',
    [styles.info]: variant === 'info',
  })

  return (
    <div
      ref={toastRef}
      className={clsx(styles.toast, styles.animate, {
        [styles.slideEnter]: entering,
        [styles.slideExit]: exiting,
      })}
      onClick={onClick}
      role="presentation"
      onAnimationEnd={handleAnimationEnd}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
      onMouseMove={handleDragMove}
      onTouchMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onTouchEnd={handleDragEnd}
      onMouseLeave={handleMouseLeave}
    >
      <div className={clsx(styles.colorStripe, variantColorClass)} />
      <div className={styles.toastBody}>
        <div className={clsx(styles.iconCircle, variantColorClass)}>
          <ToastVariantIcon />
        </div>
        <div className={styles.toastContent}>
          <BodyText className={styles.title} color="body-0" fontSize="1.6rem">
            {title}
          </BodyText>
          {!!message && (
            <BodyText
              className={styles.message}
              color="body-1"
              data-testid={`toast-message-${message}`}
            >
              {message}
            </BodyText>
          )}
        </div>
      </div>
      <Button
        className={styles.close}
        type="button"
        onClick={handleCloseClick}
        plain
      >
        <CloseIcon />
      </Button>
    </div>
  )
}

Toast.info = () => {
  throw new Error('Toast.info() was called before Toaster had mounted.')
}

Toast.success = () => {
  throw new Error('Toast.success() was called before Toaster had mounted.')
}

Toast.error = () => {
  throw new Error('Toast.error() was called before Toaster had mounted.')
}

export default Toast
