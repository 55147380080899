import CrosshairIcon from '@ancon/wildcat-ui/shared/icons/crosshair-outline.svg'
import noop from 'lodash/noop'

import Spinner from '../../../../app/components/Spinner'
import {
  locationCurrentGeoPositionSelector,
  locationPendingSelector,
} from '../../../../location/store/locationSelectors'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import useGeocodeService from '../../../../location/hooks/useGeocodeService'
import useAppStore from '../../../../../store/hooks/useAppStore'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { requestLocationPermission } from '../../../../location/store/permissionThunks'

import OutletSearchResultItem from './OutletSearchResultItem'

type CurrentLocationSuggestionProps = {
  label: string
  onClick: (geocodeResult: google.maps.GeocoderResult) => void
}

export default function CurrentLocationSuggestion({
  label,
  onClick,
}: CurrentLocationSuggestionProps) {
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const locationPending = useAppSelector(locationPendingSelector)

  const { fetchGeocodeResults } = useGeocodeService()

  async function handleLocationResults() {
    const currentGeoPosition = locationCurrentGeoPositionSelector(
      store.getState(),
    )

    if (currentGeoPosition) {
      const geocodeResult = await fetchGeocodeResults(currentGeoPosition)

      if (geocodeResult?.formatted_address) {
        onClick(geocodeResult)
      }
    }
  }

  async function handleCheckCurrentLocation() {
    if (locationPending) return

    try {
      const { permissionGranted, currentGeoPosition } = await dispatch(
        requestLocationPermission(),
      ).unwrap()

      if (permissionGranted && currentGeoPosition) {
        handleLocationResults()
      }
    } catch (err) {
      noop()
    }
  }

  return (
    <OutletSearchResultItem
      leftAccessory={
        locationPending ? <Spinner size="xSmall" /> : <CrosshairIcon />
      }
      disabled={locationPending}
      onClick={handleCheckCurrentLocation}
    >
      {label}
    </OutletSearchResultItem>
  )
}
