import { isAxiosError } from 'axios'

// https://github.com/axios/axios/issues/815#issuecomment-453963910
// Fix for when using responseType: 'blob' and the response is a JSON error response.
function handleBlobResponseRejection(error: any): Promise<unknown> {
  if (
    isAxiosError(error) &&
    error.request?.responseType === 'blob' &&
    error.response &&
    error.response.data instanceof Blob &&
    error.response.data.type?.toLowerCase().indexOf('json') >= 0
  ) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        try {
          const data = JSON.parse(reader.result as string)
          Object.assign(error.response!, { data })
          reject(error)
        } catch (err) {
          reject(error)
        }
      }
      reader.onerror = () => reject(error)
      reader.readAsText(error.response?.data)
    })
  }

  return Promise.reject(error)
}

export default handleBlobResponseRejection
