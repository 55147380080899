import { RootState } from '../../../store/types'
import { LocationReducerState } from '../types'

function locationSelector<K extends keyof LocationReducerState>(
  state: RootState,
  key: K,
) {
  return state.location[key]
}

// Simple state selector
export const locationHasPermissionSelector = (state: RootState) =>
  locationSelector(state, 'locationPermissionGranted')

export const locationPermissionModalVisibleSelector = (state: RootState) =>
  locationSelector(state, 'locationPermissionModalVisible')

export const locationPendingSelector = (state: RootState) =>
  locationSelector(state, 'locationPending')

export const locationCurrentGeoPositionSelector = (state: RootState) =>
  locationSelector(state, 'currentGeoPosition')
