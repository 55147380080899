import {
  APIGetRequest,
  CheckDetails,
  IDNameItem,
  SaleGroupItem,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type GroupsAPI = {
  get: {
    details: APIGetRequest<
      {
        originalCheck: CheckDetails
        unpaidItems: SaleGroupItem[]
        ongoingItems: SaleGroupItem[]
        paidItems: SaleGroupItem[]
        outlet: IDNameItem
        virtualPos: { id: string } | null
      },
      { outletId: string; groupId: string; 'extent.virtualPos': true }
    >
  }
}

const groups: GroupsAPI = {
  get: {
    details: new Request(endpoints.core.outlets.byId.sales.groups.byId.details)
      .get,
  },
}

export default groups
