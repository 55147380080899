import {
  OutletListItem,
  OutletDetails,
  OrderFormat,
  CheckoutSalesLocation,
  ServiceTime,
  CheckoutDelivery,
  BoxPickUpStation,
} from '@ancon/wildcat-types'

import { InformativeError } from '../app/types'

export type OutletFilters = {
  orderFormat: OrderFormat | null
  salesLocation: CheckoutSalesLocation | null
  serviceTime: ServiceTime | null
  delivery: CheckoutDelivery | null
  section: BoxPickUpStation | null
}

export type OutletReducerState = {
  outletListItem: OutletListItem | null
  outletDetails: OutletDetails | null
  isOutletDetailsPending: boolean
  outletFilters: OutletFilters

  // Modal
  orderConfigureFilterModal: OrderConfigureFilterMode
  isVisibleOutletChangeWarningModal: boolean
  isVisibleDeliveryLocationModal: boolean
  isVisibleTableOrderConfigureModal: boolean
  isVisibleOutletDetailsModal: boolean
  isVisibleServiceTimeKindSelectionModal: boolean
  isVisibleBoxPickupOrderConfigureModal: boolean

  // Validate table number
  outletTableNumberValidatePending: boolean
  outletTableNumberValidateError: InformativeError | null

  // Refresh outlet menu
  outletMenuForceRefresh: boolean
  outletMenuLastRefreshedAt: number | null
}

export type MenuSubGroup = {
  id: string
  name: string
  products?: string[]
}

export type MenuSection = {
  id: string
  name: string
  subGroups?: MenuSubGroup[]
  products?: string[]
}

export enum OrderConfigureFilterMode {
  None,
  OrderType,
  OrderTime,
  OrderTypeAndTime,
}
