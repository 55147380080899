import axios, { AxiosError } from 'axios'
import { generateBackoffWithEqualJitter } from '@ancon/wildcat-utils/api'
import wait from '@ancon/wildcat-utils/shared/wait'
import isLockedError from '@ancon/wildcat-utils/error/isLockedError'
import isNetworkError from '@ancon/wildcat-utils/error/isNetworkError'

type APIRetryEntry = { backoff: number[]; count: number }

const retries: Map<string, APIRetryEntry> = new Map()

function getErrorKey(error: AxiosError) {
  return [error.config!.method!, error.config!.url!].join('-')
}

function getOrCreateRetryEntry(
  error: AxiosError,
  backoff: number[],
): APIRetryEntry | undefined {
  if (error.config?.url && error.config.method) {
    const key = getErrorKey(error)

    if (!retries.has(key)) {
      retries.set(key, {
        backoff,
        count: -1,
      })
    }

    return retries.get(key)
  }

  return undefined
}

async function retryRequest(
  error: AxiosError,
  backoff: number[],
  rejectionHandler: (error: unknown) => Promise<unknown>,
) {
  const retry = getOrCreateRetryEntry(error, backoff)

  if (retry) {
    retry.count += 1
    const ms = retry.backoff[retry.count]

    if (ms != null) {
      if (retry.count < retry.backoff.length) {
        // eslint-disable-next-line no-console
        console.warn(
          `Retrying ${error.config?.url} request (${retry.count + 1}/${
            retry.backoff.length
          })`,
        )

        try {
          await wait(ms)
          const res = await axios(error.config!)
          retries.delete(getErrorKey(error))
          return res
        } catch (err) {
          return rejectionHandler(err)
        }
      }

      // eslint-disable-next-line no-console
      console.warn(
        `Retries exceeded for request ${error.config?.url} (${
          retry.count + 1
        }/${retry.backoff.length})`,
      )
    }
  }

  return Promise.reject(error)
}

let networkErrorBackoff: number[] | null = null
let lockedErrorBackoff: number[] | null = null

export default function handleResponseRejection(error: any): Promise<unknown> {
  // Retry until success
  if (isNetworkError(error)) {
    if (!networkErrorBackoff) {
      networkErrorBackoff = generateBackoffWithEqualJitter(10, 1000, 5000)
    }

    return retryRequest(error, networkErrorBackoff, handleResponseRejection)
  }

  if (isLockedError(error)) {
    if (!lockedErrorBackoff) {
      lockedErrorBackoff = generateBackoffWithEqualJitter(15, 100, 1000)
    }

    return retryRequest(error, lockedErrorBackoff, handleResponseRejection)
  }

  return Promise.reject(error)
}
