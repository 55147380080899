import { ErrorCode } from '@ancon/wildcat-types'
import invert from 'lodash/invert'
import getTranslation from 'next-translate/getT'
import { Translate } from 'next-translate'

import { AppGenericErrorType, ErrorInfo, InformativeError } from '../types'

const ErrorsMap = invert(ErrorCode)

function getResponseErrorId(err: AppGenericErrorType) {
  // @ts-ignore
  const { id } = err?.response?.data?.error ?? {} // Core API Error
  // @ts-ignore
  const { errorCode } = err?.response?.data ?? {} // Core API Error
  // @ts-ignore
  const { code } = err ?? {} // Custom client side CodedError or Firebase auth error

  return id ?? errorCode ?? code
}

function internalGetTranslatedError(
  error: AppGenericErrorType,
  t: Translate,
  fallback?: InformativeError,
): ErrorInfo {
  const id = getResponseErrorId(error)

  const defaultError: InformativeError = {
    title: t('errors.unknown.title'),
    message: t('errors.unknown.message'),
  }

  const errorName = ErrorsMap[id]
  let errorTranslateKey = ''

  // If no error code exists fallback to default title & message with response error message
  if (errorName) {
    errorTranslateKey =
      errorName.charAt(0).toLowerCase() + errorName.substring(1)
  }

  const title = t(`common:errors.${errorTranslateKey}.title`, undefined, {
    default: fallback?.title || defaultError.title,
  })

  const message = t(`common:errors.${errorTranslateKey}.message`, undefined, {
    default: fallback?.message || defaultError.message,
  })

  return { title, message }
}

function getTranslatedError(
  error: AppGenericErrorType,
  translate: Translate,
  fallback?: InformativeError,
): ErrorInfo

function getTranslatedError(
  error: AppGenericErrorType,
  fallback?: InformativeError,
  locale?: string,
): Promise<ErrorInfo>

function getTranslatedError(
  error: AppGenericErrorType,
  translateOrFallback?: Translate | InformativeError,
  localeOrFallback?: InformativeError | string,
): ErrorInfo | Promise<ErrorInfo> {
  if (
    typeof translateOrFallback === 'function' &&
    typeof localeOrFallback !== 'string'
  ) {
    return internalGetTranslatedError(
      error,
      translateOrFallback,
      localeOrFallback,
    )
  }

  return new Promise(resolve => {
    getTranslation(localeOrFallback as string, 'common').then(t => {
      resolve(
        internalGetTranslatedError(
          error,
          t,
          translateOrFallback as InformativeError,
        ),
      )
    })
  })
}

export default getTranslatedError
