import { OrderFormat } from '@ancon/wildcat-types'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import Button from '../../app/components/Button'
import OrderTypeIcon from '../../app/components/OrderTypeIcon'
import HeadingText from '../../app/components/HeadingText'
import { AppTestIds } from '../../app/constants'
import getWhiteLabelClassName from '../../app/utils/getWhiteLabelClassName'
import { WhiteLabeledAppName } from '../../app/types'

import styles from './OutletOrderFormats.module.scss'

const orderFormatTextMap = {
  [OrderFormat.EatIn]: 'orderFormat.eatIn',
  [OrderFormat.TakeAway]: 'orderFormat.takeAway',
  [OrderFormat.Delivery]: 'orderFormat.delivery',
  [OrderFormat.TableOrder]: 'orderFormat.tableOrder',
  [OrderFormat.BoxPickup]: 'orderFormat.boxPickup',
}

type OutletOrderFormatsProps = {
  className?: string
  selectedOrderFormatClassName?: string
  orderFormats: OrderFormat[]
  selectedOrderFormat?: OrderFormat
  onChange: (orderFormat: OrderFormat) => void
}

export default function OutletOrderFormats({
  className,
  selectedOrderFormatClassName,
  orderFormats,
  selectedOrderFormat,
  onChange,
}: OutletOrderFormatsProps) {
  const { t } = useTranslation('common')

  function handleOnClickOrderFormat(orderFormat: OrderFormat) {
    if (orderFormat !== selectedOrderFormat) {
      onChange(orderFormat)
    }
  }

  return (
    <div className={clsx(styles.gridContainer, className)}>
      {orderFormats.map(orderFormat => (
        <Button
          key={orderFormat}
          variant="secondary"
          onClick={() => handleOnClickOrderFormat(orderFormat)}
          data-testid={AppTestIds.Order.Modal.OrderFormatButton}
          className={clsx(
            {
              [clsx(styles.selected, selectedOrderFormatClassName)]:
                orderFormat === selectedOrderFormat,
            },
            getWhiteLabelClassName({
              [WhiteLabeledAppName.HeatRestauranger]: styles.heatApp,
            }),
          )}
        >
          <div>
            <OrderTypeIcon orderFormat={orderFormat} />
            <HeadingText as="p" size="h3" color="heading-1">
              {t(orderFormatTextMap[orderFormat as never])}
            </HeadingText>
          </div>
        </Button>
      ))}
    </div>
  )
}
