import { OrderFormat } from '@ancon/wildcat-types'

import { CheckoutFilters } from '../types'

export default function getCheckoutFilters(
  currentFilters: CheckoutFilters,
  newFilters: Partial<CheckoutFilters>,
) {
  if (newFilters?.orderFormat) {
    switch (newFilters.orderFormat) {
      case OrderFormat.Delivery: {
        return {
          ...currentFilters,
          ...newFilters,
          salesLocation: null,
          section: null,
        }
      }
      case OrderFormat.TableOrder: {
        return {
          ...currentFilters,
          ...newFilters,
          delivery: null,
          section: null,
        }
      }

      case OrderFormat.BoxPickup: {
        return {
          ...currentFilters,
          ...newFilters,
          delivery: null,
          salesLocation: null,
        }
      }

      default: {
        return {
          ...currentFilters,
          ...newFilters,
          delivery: null,
          salesLocation: null,
          section: null,
        }
      }
    }
  } else {
    return { ...currentFilters, ...newFilters }
  }
}
