import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../../app/components/BodyText'
import Modal from '../../../app/components/modal/Modal'
import Button from '../../../app/components/Button'
import { PurchaseTermsSectionType } from '../../types'

import TermsSection from './TermsSection'
import AnconContactInformation from './AnconContactInformation'
import styles from './TermsOfPurchaseModal.module.scss'

interface TermsOfPurchaseModalProps {
  isOpen: boolean
  onClose?: () => void
}

export default function TermsOfPurchaseModal({
  isOpen,
  onClose,
}: TermsOfPurchaseModalProps) {
  const { t } = useTranslation('common')

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileModalMode="bottom-sheet"
      contentClassName={styles.modalContent}
      modalHeaderClassName={styles.modalHeader}
      hideClose
      title={t('termsOfPurchase.title')}
    >
      <div className={styles.modalBody}>
        <BodyText color="body-1">{t('termsOfPurchase.description')}</BodyText>
        <TermsSection type={PurchaseTermsSectionType.PersonalInformation} />
        <TermsSection type={PurchaseTermsSectionType.Ordering} />
        <TermsSection type={PurchaseTermsSectionType.Products} />
        <TermsSection type={PurchaseTermsSectionType.Payment} />
        <TermsSection type={PurchaseTermsSectionType.Delivery} />
        <TermsSection type={PurchaseTermsSectionType.Returns} />
        <TermsSection type={PurchaseTermsSectionType.Abuse} />
        <TermsSection type={PurchaseTermsSectionType.Amendments} />
        <AnconContactInformation />
        <BodyText color="body-1">
          {t('termsOfPurchase.weFollowRecommendations')}
        </BodyText>
      </div>
      <div className={styles.modalFooter}>
        <Button size="large" onClick={onClose}>
          {t('close')}
        </Button>
      </div>
    </Modal>
  )
}
