import { Amount, CheckoutItem } from '@ancon/wildcat-types'

export default function calculateAttendeeItemsTotalAmount(
  attendeeItems: CheckoutItem[],
): Amount | undefined {
  if (attendeeItems.length) {
    const amount = attendeeItems.reduce(
      (acc, item) => acc + item.totalPriceInclTax.amount,
      0,
    )
    const { currency } = attendeeItems[0].totalPriceInclTax

    return { amount, currency }
  }

  return undefined
}
