import { AttendeePreOrderStatus, PreOrderLink } from '@ancon/wildcat-types'

export default function getPreOrderLinkAttendeeStatus(
  preOrderLink: PreOrderLink,
  id: string,
) {
  const foundAttendee = preOrderLink?.preOrderAttendees.find(
    attendee => attendee.id === id,
  )

  return foundAttendee?.status ?? AttendeePreOrderStatus.Pending
}
