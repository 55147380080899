import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneNumberUtil = PhoneNumberUtil.getInstance()

export default function isValidPhoneNumber(phoneNumber: string): boolean {
  try {
    // Get PhoneNumber object
    const number = phoneNumberUtil.parseAndKeepRawInput(phoneNumber)
    // Get region code (US, SE, etc)
    const regionCode = phoneNumberUtil.getRegionCodeForNumber(number)

    const isPossibleNumber = phoneNumberUtil.isPossibleNumber(number)
    const isValidNumber = phoneNumberUtil.isValidNumber(number)
    const isValidForRegion = phoneNumberUtil.isValidNumberForRegion(
      number,
      regionCode,
    )

    return (
      isPossibleNumber && isValidNumber && (!regionCode || isValidForRegion)
    )
  } catch (err) {
    return false
  }
}
