import {
  APIGetRequest,
  APIPostRequest,
  APIPutRequest,
  CompanyMemberInvitation,
  CreateCompanyMemberAccountPayload,
  CreateCompanyMemberAccountResponse,
  PatchCompanyMemberPayload,
  SendCompanyMemberEmailsPayload,
  UpdateCompanyMemberResponse,
} from '@ancon/wildcat-types'

import endpoints from '../endpoints'
import Request from '../../client/Request'

type CompaniesAPI = {
  get: {
    verifyMemberInvitation: APIGetRequest<
      CompanyMemberInvitation,
      { invitationToken: string }
    >
  }
  put: {
    createMemberAccount: APIPutRequest<
      CreateCompanyMemberAccountResponse,
      CreateCompanyMemberAccountPayload,
      { companyId: string; memberId: string }
    >
  }
  patch: {
    updateCompanyMember: APIPutRequest<
      UpdateCompanyMemberResponse,
      PatchCompanyMemberPayload,
      { companyId: string; memberId: string }
    >
  }
  post: {
    sendMemberEmails: APIPostRequest<
      {},
      SendCompanyMemberEmailsPayload,
      { companyId: string; memberId: string }
    >
  }
}

const companies: CompaniesAPI = {
  get: {
    verifyMemberInvitation: new Request(
      endpoints.core.companies.members.verifyInvitation.list,
    ).get,
  },
  put: {
    createMemberAccount: new Request(
      endpoints.core.companies.byId.members.byId.details,
    ).put,
  },
  patch: {
    updateCompanyMember: new Request(
      endpoints.core.companies.byId.members.byId.details,
    ).patch,
  },
  post: {
    sendMemberEmails: new Request(
      endpoints.core.companies.byId.members.byId.sendEmails.list,
    ).post,
  },
}

export default companies
