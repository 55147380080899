import useTranslation from 'next-translate/useTranslation'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import LocationPinIcon from '@ancon/wildcat-ui/shared/icons/location-pin-outline.svg'
import CalendarIcon from '@ancon/wildcat-ui/shared/icons/calendar-event-outline.svg'
import ClockIcon from '@ancon/wildcat-ui/shared/icons/clock-circle-outline.svg'
import clsx from 'clsx'
import noop from 'lodash/noop'
import {
  ServiceTimeKindType,
  BoxPickUpStation,
  BoxPickUpStationTimeSlot,
} from '@ancon/wildcat-types'
import { batch } from 'react-redux'

import BodyText from '../../../app/components/BodyText'
import HeadingText from '../../../app/components/HeadingText'
import Button from '../../../app/components/Button'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  checkoutCurrentCheckoutPenguinLockersMetaDataSelector,
  checkoutFiltersSectionSelector,
  checkoutFiltersServiceTimeSelector,
} from '../../store/checkoutSelectors'
import Skeleton from '../../../app/components/Skeleton'
import useRouteMatch from '../../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../../app/constants'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { fetchOutletStationTimeSlots } from '../../../outlet/store/outletThunks'
import {
  checkoutSetCheckoutFilters,
  checkoutUpdatePenguinLockersMetaData,
} from '../../store/checkoutSlice'
import { updateCheckout } from '../../store/checkoutThunks'
import useAppStore from '../../../../store/hooks/useAppStore'

import styles from './BoxPickupScheduleOrderModalBody.module.scss'

function getFormattedTimeSlot(timeSlot: BoxPickUpStationTimeSlot): string {
  const { beginDateTime, expireDateTime, pickingDateTime } = timeSlot

  const startTime = moment(pickingDateTime ?? beginDateTime).format('LT')
  const endTime = moment(expireDateTime).format('LT')

  return `${startTime} - ${endTime}`
}

type BoxPickupScheduleOrderModalBodyProps = {
  section?: BoxPickUpStation
  onClickContinue: () => void
}

export default function BoxPickupScheduleOrderModalBody({
  section,
  onClickContinue,
}: BoxPickupScheduleOrderModalBodyProps) {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState<number>()
  const checkoutServiceTime = useAppSelector(checkoutFiltersServiceTimeSelector)
  const checkoutFiltersSection = useAppSelector(checkoutFiltersSectionSelector)
  const checkoutPenguinLockersData = useAppSelector(
    checkoutCurrentCheckoutPenguinLockersMetaDataSelector,
  )
  const isCheckoutPage = useRouteMatch([AppRoutes.Checkout])
  const [timeSlots, setTimeSlots] = useState<BoxPickUpStationTimeSlot[]>([])
  const [isFetchingTimeSlots, setIsFetchingTimeSlots] = useState(false)

  useEffect(() => {
    // Fetch station's time slots
    setIsFetchingTimeSlots(true)
    dispatch(fetchOutletStationTimeSlots(section?.id))
      .unwrap()
      .then(({ items }) => {
        const penguinLockersMetaData =
          checkoutCurrentCheckoutPenguinLockersMetaDataSelector(
            store.getState(),
          )
        if (penguinLockersMetaData) {
          const initialSelectedIndex = items.findIndex(
            item =>
              item.beginDateTime === penguinLockersMetaData.timeBegin &&
              item.expireDateTime === penguinLockersMetaData.expireTime,
          )
          if (initialSelectedIndex >= 0) {
            setSelectedTimeSlotIndex(initialSelectedIndex)
          }
        }

        setTimeSlots(items)
      })
      .catch(noop)
      .finally(() => {
        setIsFetchingTimeSlots(false)
      })
  }, [dispatch, section?.id, store])

  function handleOnClickContinue() {
    const selectedTimeSlot = timeSlots[selectedTimeSlotIndex!]
    const preferredServiceTime =
      checkoutCurrentCheckoutPenguinLockersMetaDataSelector(
        store.getState(),
      )?.preferredServiceTime

    batch(() => {
      dispatch(
        checkoutUpdatePenguinLockersMetaData({
          timeBegin: selectedTimeSlot.beginDateTime,
          expireTime: selectedTimeSlot.expireDateTime,
          pickingTime: selectedTimeSlot.pickingDateTime,
          preferredServiceTime:
            preferredServiceTime || checkoutServiceTime?.time,
        }),
      )
      dispatch(
        checkoutSetCheckoutFilters({
          serviceTime: {
            kind: ServiceTimeKindType.AtSpecifiedTime,
            time: selectedTimeSlot.beginDateTime,
          },
        }),
      )
    })

    if (isCheckoutPage) {
      if (section?.id) {
        dispatch(
          checkoutSetCheckoutFilters({
            section,
          }),
        )
      }

      dispatch(updateCheckout({}))
    }
    onClickContinue?.()
  }

  const isAlreadySelectedTimeSlot = useMemo(() => {
    if (checkoutPenguinLockersData && selectedTimeSlotIndex !== undefined) {
      const selectedTimeSlot = timeSlots[selectedTimeSlotIndex!]
      return (
        selectedTimeSlot.beginDateTime ===
          checkoutPenguinLockersData.timeBegin &&
        selectedTimeSlot.expireDateTime ===
          checkoutPenguinLockersData.expireTime
      )
    }
    return false
  }, [checkoutPenguinLockersData, selectedTimeSlotIndex, timeSlots])

  function getFormattedServiceDate(): string {
    let serviceTime = checkoutServiceTime?.time

    if (selectedTimeSlotIndex && selectedTimeSlotIndex >= 0) {
      const { beginDateTime, pickingDateTime } =
        timeSlots[selectedTimeSlotIndex]

      serviceTime = pickingDateTime ?? beginDateTime
    }

    return moment(serviceTime).format('ll')
  }

  return (
    <>
      <BodyText className={styles.infoText}>
        {t('boxPickupScheduleOrderModal.info')}
      </BodyText>
      <section className={styles.timeSlotContainer}>
        {isFetchingTimeSlots
          ? new Array(4).fill(0).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton className={styles.skeleton} key={index} />
            ))
          : timeSlots.map((timeSlot, index) => (
              <Button
                variant="secondary"
                onClick={() => setSelectedTimeSlotIndex(index)}
                className={clsx({
                  [styles.selected]: selectedTimeSlotIndex === index,
                })}
                key={timeSlot.id}
              >
                <HeadingText as="p" size="h3" color="heading-1">
                  {getFormattedTimeSlot(timeSlot)}
                </HeadingText>
              </Button>
            ))}
        {!isFetchingTimeSlots && !timeSlots.length && (
          <BodyText>
            {t('boxPickupScheduleOrderModal.noTimeSlotsAvailable')}
          </BodyText>
        )}
      </section>
      <section className={styles.infoContainer}>
        <div className={styles.row}>
          <LocationPinIcon />
          <BodyText fontSize="1.6rem">
            {section?.name || checkoutFiltersSection?.name}
          </BodyText>
        </div>
        <div className={styles.row}>
          <CalendarIcon />
          <BodyText fontSize="1.6rem">{getFormattedServiceDate()}</BodyText>
          {selectedTimeSlotIndex !== undefined && (
            <div className={styles.selectedTimeSlot}>
              <ClockIcon />
              <BodyText fontSize="1.6rem">
                {getFormattedTimeSlot(timeSlots[selectedTimeSlotIndex])}
              </BodyText>
            </div>
          )}
        </div>
      </section>
      <Button
        variant="primary"
        disabled={
          selectedTimeSlotIndex === undefined ||
          isFetchingTimeSlots ||
          isAlreadySelectedTimeSlot
        }
        className={styles.continueButton}
        onClick={handleOnClickContinue}
      >
        {t(
          isCheckoutPage
            ? 'boxPickupSectionSelectionModal.update'
            : 'boxPickupScheduleOrderModal.continueToCheckout',
        )}
      </Button>
    </>
  )
}
