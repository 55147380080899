import { CheckoutOrderType } from '@ancon/wildcat-types'

export type NotificationState = {
  isInitialized: boolean
  isPermissionGranted: boolean
  isSubscribed: boolean
}

export enum OneSignalEventName {
  SubscriptionChange = 'subscriptionChange',
  NotificationPermissionChange = 'notificationPermissionChange',
}

export type OrderStatusNotificationData = {
  outletId?: string
  checkoutId?: string
  orderId?: string
  /** For checkout orders */
  orderType?: CheckoutOrderType
}

export type ToastVariant = 'info' | 'success' | 'error'

export interface ToastOptions {
  id?: string
  onClick?: () => void
}

export type ToastCallback = (
  title: string,
  message?: string,
  options?: ToastOptions,
) => void

export interface ToastProps {
  id: string
  variant: ToastVariant
  title: string
  message?: string
  onClick?: () => void
}

export enum DraggableDirection {
  Left,
  Right,
}

export enum ToastAnimation {
  Entering,
  Exiting,
}

export interface Draggable {
  start: number
  x: number
  distance: number
  direction: DraggableDirection
  dragging: boolean
}

export namespace ToastEvent {
  export type Mouse = React.MouseEvent<HTMLDivElement>

  export type Touch = React.TouchEvent<HTMLDivElement>

  export type Drag = Mouse | Touch
}

export enum MessagingProviderEntityType {
  Customer,
  Order,
}
