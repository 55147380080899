import { CheckoutDetails, OrderFormat } from '@ancon/wildcat-types'
import getCheckoutPenguinLockersMetaData from '@ancon/wildcat-utils/checkout/getCheckoutPenguinLockersMetaData'

export default function getCheckoutFiltersFromMetaData(
  checkout: CheckoutDetails | null,
) {
  if (!checkout) return null

  const { orderFormat, metaData } = checkout

  if (orderFormat === OrderFormat.BoxPickup) {
    const penguinLockersMetaData = getCheckoutPenguinLockersMetaData(metaData)

    if (penguinLockersMetaData?.station) {
      const { station } = penguinLockersMetaData

      return {
        section: station,
      }
    }
  }

  return null
}
