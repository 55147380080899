import { ErrorCode } from '@ancon/wildcat-types'
import isCodedError from '@ancon/wildcat-utils/error/isCodedError'

export default function getIsAuthCredentialsError(error: unknown): boolean {
  if (isCodedError(error)) {
    const { code } = error
    return (
      code === ErrorCode.AuthInvalidEmail ||
      code === ErrorCode.AuthWrongPassword ||
      code === ErrorCode.AuthEmailAlreadyInUse
    )
  }

  return false
}
