import { useEffect, useRef, useState } from 'react'

export enum ScriptStatus {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

export function useScript(src: string, removeOnUnmount = false) {
  const [status, setStatus] = useState<ScriptStatus>(ScriptStatus.Loading)
  const scriptRef = useRef<HTMLScriptElement | null>(null)

  useEffect(() => {
    scriptRef.current = document.querySelector(`script[src="${src}"]`)

    if (scriptRef.current) {
      setStatus(
        (scriptRef.current.getAttribute('data-status') as ScriptStatus) ??
          ScriptStatus.Loading,
      )
    } else {
      scriptRef.current = document.createElement('script')
      scriptRef.current.src = src
      scriptRef.current.async = true
      scriptRef.current.setAttribute('data-status', ScriptStatus.Loading)
      document.body.appendChild(scriptRef.current)

      const setAttributeFromEvent = (event: Event) => {
        const scriptStatus =
          event.type === 'load' ? ScriptStatus.Ready : ScriptStatus.Error

        if (scriptRef.current) {
          scriptRef.current.setAttribute('data-status', scriptStatus)
        }
      }

      scriptRef.current.addEventListener('load', setAttributeFromEvent)
      scriptRef.current.addEventListener('error', setAttributeFromEvent)
    }

    const setStateFromEvent = (event: Event) => {
      const newStatus =
        event.type === 'load' ? ScriptStatus.Ready : ScriptStatus.Error
      setStatus(newStatus)
    }

    scriptRef.current.addEventListener('load', setStateFromEvent)
    scriptRef.current.addEventListener('error', setStateFromEvent)

    return () => {
      if (scriptRef.current) {
        scriptRef.current.removeEventListener('load', setStateFromEvent)
        scriptRef.current.removeEventListener('error', setStateFromEvent)

        if (removeOnUnmount && scriptRef.current.parentNode) {
          scriptRef.current.remove()
        }
      }
    }
  }, [src, removeOnUnmount])

  return status
}
