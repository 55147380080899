import { CheckoutItem } from '@ancon/wildcat-types'

import { CheckoutOutOfStockAdditionalData } from '../../checkout/types'
import {
  OutOfStockCheckoutItem,
  StockErrorAdditionalDataMap,
  VariantStockData,
} from '../types'

function getCurrentQuantity(stocks: VariantStockData, quantity: number) {
  const { count, addedCount } = stocks
  const remainingCount = count - addedCount

  return Math.min(remainingCount, quantity)
}

function getIsAllItemsOutOfStock(
  checkoutStockErrorAdditionalData: CheckoutOutOfStockAdditionalData[],
) {
  return checkoutStockErrorAdditionalData.every(item => item.count === 0)
}

export default function getOutOfStockFormInitialValues(
  checkoutStockErrorAdditionalData: CheckoutOutOfStockAdditionalData[],
  checkoutItems: CheckoutItem[],
) {
  const additionalDataMap = checkoutStockErrorAdditionalData.reduce(
    (acc, item) => {
      acc[item.variantId] = {
        count: Math.max(item.count, 0),
        addedCount: 0,
      }
      return acc
    },
    {} as StockErrorAdditionalDataMap,
  )

  const items = checkoutItems.reduce((acc, item) => {
    const {
      product,
      quantity,
      unitPriceInclTax: { unitPrice, additionalPrice },
      id,
    } = item
    const { variantId, hasVariants, variantName, name } = product

    if (additionalDataMap[variantId]) {
      const currentQuantity = getCurrentQuantity(
        additionalDataMap[variantId],
        quantity,
      )

      acc.push({
        id,
        variantId,
        name: hasVariants ? variantName : name,
        quantity,
        price: {
          amount: unitPrice.amount + additionalPrice.amount,
          currency: unitPrice.currency,
        },
        currentQuantity,
      })

      additionalDataMap[variantId].addedCount += currentQuantity
    }
    return acc
  }, [] as OutOfStockCheckoutItem[])

  return {
    items,
    stocks: additionalDataMap,
    isAllItemsOutOfStock: getIsAllItemsOutOfStock(
      checkoutStockErrorAdditionalData,
    ),
  }
}
