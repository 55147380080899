import { PayloadAction } from '@reduxjs/toolkit'

import { LocationReducerState } from '../types'

const locationReducers = {
  locationSetPermissionModalVisible(
    state: LocationReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.locationPermissionModalVisible = action.payload
  },
  locationSetCurrentGeoPositionName(
    state: LocationReducerState,
    action: PayloadAction<string>,
  ) {
    state.currentGeoPositionName = action.payload
  },
}

export default locationReducers
