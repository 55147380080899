import React, {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'

import usePrevious from '../../hooks/usePrevious'
import Portal from '../Portal'
import { PortalHostId } from '../../constants'

import ModalCard from './ModalCard'
import { ModalHeaderProps } from './ModalHeader'
import styles from './Modal.module.scss'

type ModalDisplayMode = 'modal' | 'full-screen' | 'bottom-sheet'

type ModalProps = PropsWithChildren<{
  isOpen?: boolean
  hideClose?: boolean
  backdropFade?: boolean
  mobileModalMode?: ModalDisplayMode
  onClose?: () => void
  title?: string
  className?: string
  contentClassName?: string
  CustomModalHeader?: FC<ModalHeaderProps>
  preventOnClose?: boolean
  modalHeaderClassName?: string
}>

function ModalComponent({
  children,
  isOpen = false,
  hideClose = false,
  backdropFade = true,
  mobileModalMode = 'modal',
  onClose,
  title,
  className,
  contentClassName,
  CustomModalHeader,
  preventOnClose = false,
  modalHeaderClassName,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null)

  const prevOpened = usePrevious<boolean>(isOpen, true)

  const [closing, setClosing] = useState<boolean>(false)

  const bottomSheet = mobileModalMode === 'bottom-sheet'
  const fullScreen = mobileModalMode === 'full-screen'

  function restoreScrollbarIfNeeded() {
    if (document.body.classList.contains(styles.modalOpen)) {
      document.body.classList.remove(styles.modalOpen)
    }
  }

  function hideScrollbarIfNeeded() {
    if (!document.body.classList.contains(styles.modalOpen)) {
      document.body.classList.add(styles.modalOpen)
    }
  }

  const validateScrollbar = useCallback(() => {
    const hasChildNodes = document
      .getElementById(PortalHostId.Modal)
      ?.hasChildNodes()

    if (hasChildNodes) {
      hideScrollbarIfNeeded()
    } else {
      restoreScrollbarIfNeeded()
    }
  }, [])

  useEffect(() => {
    validateScrollbar()

    return () => validateScrollbar()
  }, [validateScrollbar])

  useEffect(() => {
    const didOpenChange = prevOpened !== isOpen

    if (didOpenChange) {
      validateScrollbar()
    }
  }, [isOpen, prevOpened, validateScrollbar])

  function handleClose() {
    setClosing(true)
  }

  useEffect(() => {
    function handlePopState() {
      restoreScrollbarIfNeeded()
      if (isOpen) {
        onClose?.()
      }
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [onClose, isOpen])

  function handleModalTransitionEnd(e: React.TransitionEvent<HTMLDivElement>) {
    if (e.target === modalRef.current) {
      e.stopPropagation()
      setClosing(false)

      restoreScrollbarIfNeeded()
      onClose?.()
    }
  }

  function handleModalClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    handleClose()
  }

  return (
    <Portal hostId={PortalHostId.Modal}>
      {isOpen && (
        <div
          ref={modalRef}
          className={clsx(
            styles.modal,
            {
              [styles.bottomSheetModal]: bottomSheet,
              [styles.modalFadeOut]: closing,
              [styles.backdropFade]: backdropFade,
            },
            className,
          )}
          onTransitionEnd={handleModalTransitionEnd}
        >
          <div
            className={styles.backdrop}
            onClick={preventOnClose ? undefined : handleModalClick}
            role="presentation"
          />
          <ModalCard
            title={title}
            onClose={hideClose || preventOnClose ? undefined : handleClose}
            className={clsx(
              closing && styles.cardSlideInUp,
              closing && {
                [styles.fullScreenFadeOut]: fullScreen,
                [styles.bottomSheetSlideInDown]: bottomSheet,
              },
              contentClassName,
            )}
            mobileModalMode={mobileModalMode}
            CustomModalHeader={CustomModalHeader}
            modalHeaderClassName={modalHeaderClassName}
          >
            {children}
          </ModalCard>
        </div>
      )}
    </Portal>
  )
}

const Modal = memo(ModalComponent)

export type { ModalProps, ModalDisplayMode }

export default Modal
