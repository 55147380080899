import { CheckDetails, CheckDetailsMetadata } from '@ancon/wildcat-types'
import moment from 'moment'

import { PayAndGoSession } from '../types'

import isPayAndGoSessionMetadata from './isPayAndGoSessionMetadata'

function findRecentPayAndGoMetadataBySessionId(
  check: Pick<CheckDetails, 'metadata'>,
  sessionId: string,
): CheckDetailsMetadata<PayAndGoSession> | null {
  return (
    check.metadata.find(m => {
      if (!isPayAndGoSessionMetadata(m)) {
        return false
      }

      // Limit to recent sessions only
      if (moment(m.metadata.modified).isBefore(moment().subtract(1, 'hour'))) {
        return false
      }

      return m.metadata.sessionId === sessionId
    }) || null
  )
}

export default findRecentPayAndGoMetadataBySessionId
