import { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { ProductDetailsAddonIngredient } from '@ancon/wildcat-types'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import useTranslation from 'next-translate/useTranslation'
import {
  ConfigureAddonGroupFormState,
  ConfigureAddonIngredientFormState,
} from '@ancon/wildcat-utils/product/types'

import ProductSelection from '../../components/ProductSelection'
import { SelectableType } from '../../../app/components/Selectable'

type CheckBoxTypeAddonGroupProps = {
  ingredients: ProductDetailsAddonIngredient[]
  addOnIndex: number
}

type CheckBoxTypeAddonIngredientProps = {
  ingredient: ProductDetailsAddonIngredient
  ingredientIndex: number
  addOnIndex: number
}

function CheckBoxAddonIngredient({
  ingredient,
  addOnIndex,
  ingredientIndex,
}: CheckBoxTypeAddonIngredientProps) {
  const freeIngredientAmountFieldName = `selectedAddons.${addOnIndex}.ingredients.${ingredientIndex}.freeAmount`
  const addOnSelectedCountFieldName = `selectedAddons.${addOnIndex}.selectedCount`
  const addOnSettingsFieldName = `selectedAddons.${addOnIndex}.addOnSettings`
  const addOnIngredientsFieldName = `selectedAddons.${addOnIndex}.ingredients`

  const { ingredientName, price, currency, id } = ingredient
  const { t } = useTranslation('common')
  const { trigger, setValue, getValues, watch } = useFormContext()
  const [freeAmount, selectedAddOnCount, addOnSettings] = watch([
    freeIngredientAmountFieldName,
    addOnSelectedCountFieldName,
    addOnSettingsFieldName,
  ])
  const addedAsFreeItem = freeAmount === 1
  const hasFreeAddons = !!addOnSettings.freeAmount
  const isWithinFreeLimit =
    hasFreeAddons && addOnSettings.freeAmount! > selectedAddOnCount
  const showFreeLabel = isWithinFreeLimit || addedAsFreeItem

  const {
    field: { value, onChange },
  } = useController({
    name: `selectedAddons.${addOnIndex}.ingredients.${ingredientIndex}.selectedCount`,
  })

  function handleCheckChange(checked: boolean) {
    onChange(checked ? 1 : 0)
    setValue(
      addOnSelectedCountFieldName,
      Math.max(checked ? selectedAddOnCount + 1 : selectedAddOnCount - 1, 0),
    )

    // Modify free addon state - Start
    // On increase add as free addOn
    if (checked && isWithinFreeLimit) {
      setValue(freeIngredientAmountFieldName, 1)
      trigger(`selectedAddons.${addOnIndex}`)
      return
    }

    // On deselect free addon assign free item to next candidate addOn item
    if (!checked && addedAsFreeItem) {
      setValue(freeIngredientAmountFieldName, 0)

      const ingredientSelectionState: ConfigureAddonIngredientFormState[] =
        getValues(addOnIngredientsFieldName)
      const nextFreeCandidateIngredientIndex =
        ingredientSelectionState.findIndex(
          ing =>
            ing.id !== id && ing.freeAmount === 0 && ing.selectedCount === 1,
        )

      if (nextFreeCandidateIngredientIndex !== -1) {
        setValue(
          `selectedAddons.${addOnIndex}.ingredients.${nextFreeCandidateIngredientIndex}.freeAmount`,
          1,
        )
      }
    }
    // Modify free addon state - End

    // Trigger Validation Cycle
    trigger(`selectedAddons.${addOnIndex}`)
  }

  return (
    <ProductSelection
      selected={value === 1}
      label={ingredientName}
      onChange={handleCheckChange}
      selectionType={SelectableType.CheckBox}
      priceLabel={
        showFreeLabel
          ? t('components.productDetailsModal.free')
          : getFormattedCurrency(
              hasFreeAddons ? addOnSettings.afterFreePrice.amount : price,
              currency,
            )
      }
      key={id}
    />
  )
}

export default function CheckBoxTypeAddonGroup({
  ingredients,
  addOnIndex,
}: CheckBoxTypeAddonGroupProps) {
  const { trigger, watch } = useFormContext()
  const { t } = useTranslation('common')
  const [addOnSettings] = watch([`selectedAddons.${addOnIndex}.addOnSettings`])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const field = useController({
    name: `selectedAddons.${addOnIndex}`,
    rules: {
      validate: (value: ConfigureAddonGroupFormState) => {
        const { maximumAmount, minimumAmount } = addOnSettings
        const { selectedCount: totalAddonCount } = value

        if (minimumAmount && totalAddonCount === 0) {
          return t('components.productDetailsModal.mandatoryChoice').toString()
        }
        if (maximumAmount && totalAddonCount > maximumAmount) {
          return t('components.productDetailsModal.maxAddons', {
            maximum: maximumAmount,
          }).toString()
        }
        if (minimumAmount && totalAddonCount < minimumAmount) {
          return t('components.productDetailsModal.atLeastAddons', {
            minimum: minimumAmount,
          }).toString()
        }
        return true
      },
    },
  })

  useEffect(() => {
    // Trigger Initial Validation Cycle
    trigger(`selectedAddons.${addOnIndex}`)
  }, [trigger, addOnIndex])

  return ingredients.map((ingredient, i) => (
    <CheckBoxAddonIngredient
      ingredient={ingredient}
      ingredientIndex={i}
      addOnIndex={addOnIndex}
      key={ingredient.id}
    />
  ))
}
