import { initializeApp, FirebaseOptions, getApps } from 'firebase/app'

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAwVPuipbzssJbYeGHnXIyeHjlBVmx4CF8',
  authDomain: 'leen-61727.firebaseapp.com',
  projectId: 'leen-61727',
  appId: '1:544918921186:web:2afe14329d6954034563bb',
  messagingSenderId: '544918921186',
  measurementId: 'G-SC4ENJMFCH',
}

const firebaseApps = getApps()

const firebaseApp = firebaseApps.length
  ? firebaseApps[0]
  : initializeApp(firebaseConfig)

export default firebaseApp
