import useTranslation from 'next-translate/useTranslation'
import { useFormContext } from 'react-hook-form'

import FormInput from '../../app/components/FormInput'
import FormPhoneNumberInput from '../../app/components/FormPhoneNumberInput'
import userConstraints from '../../app/constraints/userConstraints'
import { AppTestIds } from '../../app/constants'

type UserInfoFieldsProps = {
  enableEmailField?: boolean
}

export default function UserInfoFields({
  enableEmailField,
}: UserInfoFieldsProps) {
  const { t } = useTranslation('common')
  const {
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <>
      {enableEmailField && (
        <FormInput
          fieldName="email"
          autoComplete="off"
          autoCapitalize="none"
          placeholder={t('placeholders.email')}
          label={t('user.email')}
          type="email"
          rules={{
            required: t('validations.required'),
            pattern: {
              message: t('validations.invalidEmail'),
              value: userConstraints.pattern.email,
            },
            maxLength: {
              value: userConstraints.max.email,
              message: t('validations.maxLength', {
                limit: userConstraints.max.email,
              }),
            },
          }}
          readOnly={isSubmitting}
          required
        />
      )}
      <FormInput
        fieldName="firstName"
        autoComplete="off"
        autoCapitalize="none"
        placeholder={t('placeholders.firstName')}
        label={t('user.firstName')}
        type="text"
        readOnly={isSubmitting}
        rules={{
          required: t('validations.required'),
          maxLength: {
            value: userConstraints.max.firstName,
            message: t('validations.maxLength', {
              limit: userConstraints.max.firstName,
            }),
          },
        }}
        required
        data-testid={AppTestIds.UserInfoFields.FirstName}
      />
      <FormInput
        fieldName="lastName"
        autoComplete="off"
        autoCapitalize="none"
        placeholder={t('placeholders.lastName')}
        label={t('user.lastName')}
        type="text"
        readOnly={isSubmitting}
        rules={{
          required: t('validations.required'),
          maxLength: {
            value: userConstraints.max.lastName,
            message: t('validations.maxLength', {
              limit: userConstraints.max.lastName,
            }),
          },
        }}
        required
        data-testid={AppTestIds.UserInfoFields.LastName}
      />
      <FormPhoneNumberInput
        autoComplete="off"
        fieldName="phoneNumber"
        placeholder={t('placeholders.phoneNumber')}
        label={t('user.phoneNumber')}
        readOnly={isSubmitting}
        rules={{
          required: t('validations.required'),
        }}
        required
        data-testid={AppTestIds.UserInfoFields.PhoneNumber}
      />
    </>
  )
}
