import {
  APIGetRequest,
  BoxPickUpStation,
  BoxPickUpStationTimeSlot,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type StationsAPI = {
  get: {
    stations: APIGetRequest<
      { items: BoxPickUpStation[] },
      {
        outletId: string
      }
    >
    stationTimeSlots: APIGetRequest<
      { items: BoxPickUpStationTimeSlot[] },
      {
        outletId: string
        stationId: number
        timeSlotDate: string
      }
    >
  }
}

const stations: StationsAPI = {
  get: {
    stations: new Request(endpoints.core.penguin.outlets.byId.stations.list)
      .get,
    stationTimeSlots: new Request(
      endpoints.core.penguin.outlets.byId.stations.timeSlots.list,
    ).get,
  },
}

export default stations
