'use client'

import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { showAuthModal } from '../store/authSlice'
import { AuthModalType } from '../types'
import { authCustomerIsLoggedInSelector } from '../store/authSelectors'
import { clientContextFetchPendingSelector } from '../../clientContext/store/clientContextSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'

const AuthModalFilterKeys = {
  SignIn: 'signin',
  ForgotPassword: 'forgotpassword',
}

const AuthModalMapByFilterKey = {
  [AuthModalFilterKeys.SignIn]: AuthModalType.SignIn,
  [AuthModalFilterKeys.ForgotPassword]: AuthModalType.ForgotPassword,
}

export default function AuthSignInURLFilter() {
  const dispatch = useAppDispatch()

  const { query } = useRouter()

  const isTriggeredRef = useRef<boolean>(false)

  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)

  const isClientContextLoading = useAppSelector(
    clientContextFetchPendingSelector,
  )

  useEffect(() => {
    const authFilterURLFlag = Object.keys(query).find(key =>
      Object.values(AuthModalFilterKeys).includes(key.toLowerCase()),
    )

    if (
      !!authFilterURLFlag &&
      isLoggedIn === false &&
      !isTriggeredRef.current &&
      !isClientContextLoading
    ) {
      isTriggeredRef.current = true

      dispatch(
        showAuthModal(AuthModalMapByFilterKey[authFilterURLFlag.toLowerCase()]),
      )
    }
  }, [dispatch, isClientContextLoading, isLoggedIn, query])

  return null
}
