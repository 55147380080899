import { useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'

import Selectable, { SelectableType } from '../../../app/components/Selectable'
import QuantityModifier from '../../../app/components/QuantityModifier'
import { CheckoutOutOfStockFormState, VariantStockData } from '../../types'

import styles from './CheckoutOutOfStockFormItem.module.scss'

type CheckoutOutOfStockFormItemProps = {
  index: number
}

enum StockOption {
  None,
  SelectAll,
  SelectSome,
  Remove,
}

function getSelectedOption(variantStock: VariantStockData, quantity: number) {
  const { count } = variantStock

  if (quantity === 0) return StockOption.Remove
  if (count && count === quantity) return StockOption.SelectAll
  if (count > 1) return StockOption.SelectSome

  return StockOption.None
}
export default function CheckoutOutOfStockFormItem({
  index,
}: CheckoutOutOfStockFormItemProps) {
  const { t } = useTranslation('common')
  const { watch, setValue } = useFormContext<CheckoutOutOfStockFormState>()
  const [item, stocks, isAllItemsOutOfStock] = watch([
    `items.${index}`,
    'stocks',
    'isAllItemsOutOfStock',
  ])
  const { name, price, quantity, variantId, currentQuantity } = item

  const variantStock = useMemo(
    () => stocks[variantId] as VariantStockData,
    [stocks, variantId],
  )

  const isOutOfStock = !variantStock.count
  const hasSomeQuantity = variantStock.count > 1
  const isVariantStockIsTwo = variantStock.count === 2
  const selectedOption = getSelectedOption(variantStock, currentQuantity)

  function updateQuantity(newQuantity: number) {
    setValue(`items.${index}.currentQuantity`, newQuantity)
    setValue(
      `stocks.${variantId}.addedCount`,
      variantStock.addedCount + newQuantity - currentQuantity,
      { shouldValidate: true },
    )
  }

  function handleOnSelectAllClick() {
    updateQuantity(variantStock.count)
  }

  function handleOnSelectSomeClick() {
    updateQuantity(variantStock.count - 1)
  }

  function handleOnRemoveClick() {
    updateQuantity(0)
  }

  function handleOnChangeQuantity(newQuantity: number) {
    updateQuantity(newQuantity)
  }

  return (
    <div>
      <div className={clsx(styles.row, styles.spaceBetween, styles.titleRow)}>
        <div className={clsx(styles.row, styles.spaceBetween)}>
          <div>
            <BodyText className={styles.productName}>{name}</BodyText>
            <BodyText color="body-2">
              {getFormattedCurrency(price.amount, price.currency)}
            </BodyText>
          </div>
          <div>
            <BodyText
              color="body-2"
              className={styles.singleLineText}
            >{`x ${quantity}`}</BodyText>
          </div>
        </div>
        <div>
          <div className={styles.outOfStockTag}>
            <BodyText fontSize="1.2rem" className={styles.singleLineText}>
              {variantStock.count
                ? t('cartOutOfStockModal.availableCount', {
                    count: variantStock.count,
                  })
                : t('cartOutOfStockModal.outOfStock')}
            </BodyText>
          </div>
        </div>
      </div>
      {!isAllItemsOutOfStock && (
        <div>
          {isOutOfStock ? (
            <BodyText className={styles.warningText}>
              {t('cartOutOfStockModal.thisWillBeRemoved')}
            </BodyText>
          ) : (
            <div>
              <div className={clsx(styles.row, styles.optionRow)}>
                <Selectable
                  type={SelectableType.RadioButton}
                  checked={selectedOption === StockOption.SelectAll}
                  onClick={handleOnSelectAllClick}
                  containerClassName={styles.radioButton}
                />
                <BodyText>
                  {t('cartOutOfStockModal.addAvailableQuantity', {
                    count: variantStock.count,
                  })}
                </BodyText>
              </div>
              {hasSomeQuantity && (
                <div
                  className={clsx(
                    styles.row,
                    styles.optionRow,
                    styles.spaceBetween,
                  )}
                >
                  <div className={styles.row}>
                    <Selectable
                      type={SelectableType.RadioButton}
                      checked={selectedOption === StockOption.SelectSome}
                      onClick={handleOnSelectSomeClick}
                      containerClassName={styles.radioButton}
                    />
                    <BodyText>
                      {t(
                        isVariantStockIsTwo
                          ? 'cartOutOfStockModal.addOneItem'
                          : 'cartOutOfStockModal.reduceQuantity',
                      )}
                    </BodyText>
                  </div>
                  {!isVariantStockIsTwo && (
                    <QuantityModifier
                      value={
                        selectedOption === StockOption.SelectSome
                          ? currentQuantity
                          : variantStock.count - 1
                      }
                      max={variantStock.count - 1}
                      min={1}
                      onChange={handleOnChangeQuantity}
                    />
                  )}
                </div>
              )}
              <div className={clsx(styles.row, styles.optionRow)}>
                <Selectable
                  type={SelectableType.RadioButton}
                  checked={selectedOption === StockOption.Remove}
                  onClick={handleOnRemoveClick}
                  containerClassName={styles.radioButton}
                />
                <BodyText>{t('cartOutOfStockModal.removeItem')}</BodyText>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
