import {
  CustomerPreOrderCheckout,
  PreOrderCheckoutDetails,
} from '@ancon/wildcat-types'
import pick from 'lodash/pick'

export default function pickCustomerPreOrderFromCheckout(
  preOrderCheckout: PreOrderCheckoutDetails,
): CustomerPreOrderCheckout {
  const { referenceNumber, noOfAttendees, attendees } = preOrderCheckout

  const pickedData = pick(preOrderCheckout, [
    'id',
    'preOrderName',
    'preOrderStatus',
    'orderType',
    'orderFormat',
    'serviceTime',
    'outlet',
    'ticketNumber',
  ])

  const hostAttendee = attendees?.find(attendee => attendee.isHost)

  return {
    ...pickedData,
    checkoutNumber: referenceNumber,
    attendeeCount: noOfAttendees,
    hostAttendeeId: hostAttendee?.id!,
  }
}
