import useAppSelector from '../../../store/hooks/useAppSelector'
import Modal from '../../app/components/modal/Modal'
import { productsIsProductUpsellModalOpenSelector } from '../store/productSelectors'
import ConfigureUpsellGroupForm from '../forms/upsellGroups/ConfigureUpsellGroupForm'
import { ConfigureProductUpsellGroupFormState } from '../types'

import styles from './ProductDetailsModal.module.scss'

type ProductUpsellModalProps = {
  onSubmit: (data: ConfigureProductUpsellGroupFormState) => void
  onDismiss: () => void
}

export default function ProductUpsellModal({
  onSubmit,
  onDismiss,
}: ProductUpsellModalProps) {
  const isModalVisible = useAppSelector(
    productsIsProductUpsellModalOpenSelector,
  )

  return (
    <Modal
      isOpen={isModalVisible}
      onClose={onDismiss}
      className={styles.modal}
      mobileModalMode="bottom-sheet"
      hideClose
    >
      <div className={styles.modalBody}>
        <ConfigureUpsellGroupForm onSubmit={onSubmit} onDismiss={onDismiss} />
      </div>
    </Modal>
  )
}
