import { APIPutRequest, CheckItem } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type ItemsAPI = {
  put: {
    split: APIPutRequest<
      {
        created: CheckItem[]
        updated: CheckItem[]
      },
      {
        parts: number
        checkItemIds: string[]
      },
      {
        outletId: string
        checkId: string
      }
    >
  }
}

const items: ItemsAPI = {
  put: {
    split: new Request(
      endpoints.core.outlets.byId.checks.byId.items.split.list,
      {
        version: 1.2,
      },
    ).put,
  },
}

export default items
