const userConstraints = Object.freeze({
  pattern: {
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^[\S]{8,}$/,
  },
  min: {
    password: 8,
  },
  max: {
    email: 128,
    firstName: 40,
    lastName: 40,
  },
})

export default userConstraints
