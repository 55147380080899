import React from 'react'
import EyeIcon from '@ancon/wildcat-ui/shared/icons/eye.svg'
import EyeClosedIcon from '@ancon/wildcat-ui/shared/icons/eye-closed.svg'

import styles from './PasswordVisibilityIcon.module.scss'

type PasswordVisibilityIconProps = {
  visible?: boolean
}

export default function PasswordVisibilityIcon({
  visible = false,
}: PasswordVisibilityIconProps) {
  return (
    <div className={styles.button}>
      {visible ? <EyeIcon /> : <EyeClosedIcon />}
    </div>
  )
}
