import {
  CheckoutMetaDataItem,
  CheckoutMetaDataItemPenguinLockersData,
  OrderFormat,
} from '@ancon/wildcat-types'
import getCheckoutPenguinLockersMetaData from '@ancon/wildcat-utils/checkout/getCheckoutPenguinLockersMetaData'

import { CheckoutFilters } from '../types'

export default function getCheckoutMetaData(
  checkoutFilters: CheckoutFilters,
  checkoutMetaData?: CheckoutMetaDataItem[],
): CheckoutMetaDataItemPenguinLockersData | null {
  if (
    checkoutFilters.orderFormat === OrderFormat.BoxPickup &&
    checkoutFilters?.section?.id
  ) {
    const { section } = checkoutFilters
    const penguinLockersMetaData =
      getCheckoutPenguinLockersMetaData(checkoutMetaData)

    return {
      station: section,
      timeBegin: penguinLockersMetaData?.timeBegin ?? null,
      expireTime: penguinLockersMetaData?.expireTime ?? null,
      pickingTime: penguinLockersMetaData?.pickingTime ?? null,
      preferredServiceTime:
        penguinLockersMetaData?.preferredServiceTime ?? null,
    } as CheckoutMetaDataItemPenguinLockersData
  }

  return null
}
