import useAppSelector from '../../../store/hooks/useAppSelector'
import { AppRoutes } from '../../app/constants'
import useDeviceSize from '../../app/hooks/useDeviceSize'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { appIsCartDrawerVisibleSelector } from '../../app/store/appSelectors'
import { generalCheckoutTotalItemQtySelector } from '../store/checkoutSelectors'
import { isPreOrderCheckoutSelector } from '../../preOrder/store/preOrderSelectors'

export default function useCartDrawerOpen() {
  const isCartDisabledRoute = useRouteMatch([AppRoutes.Checkout])

  const { isLargeDesktop } = useDeviceSize()

  const cartItemCount = useAppSelector(generalCheckoutTotalItemQtySelector)
  const isCartDrawerVisible = useAppSelector(appIsCartDrawerVisibleSelector)
  const isPreOrderCheckout = useAppSelector(isPreOrderCheckoutSelector)

  const cartHasItems = !!cartItemCount && cartItemCount > 0

  return (
    // For pre-order checkouts, even if the cart is empty, we want to open the cart drawer
    (isPreOrderCheckout || cartHasItems) &&
    !isCartDisabledRoute &&
    (isLargeDesktop || isCartDrawerVisible)
  )
}
