import { useController, useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { getDetailedProductVariantStock } from '@ancon/wildcat-utils/inventory'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import isDetailedProductVariantOutOfStock from '@ancon/wildcat-utils/inventory/isDetailedProductVariantOutOfStocks'

import ProductConfigurationAccordion from '../components/ProductConfigurationAccordion'
import ProductSelection from '../components/ProductSelection'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { productsDetailsVariantsSelector } from '../store/productSelectors'
import { outletSelectedOutletIdSelector } from '../../outlet/store/outletSelector'
import { SelectableType } from '../../app/components/Selectable'
import { ConfigureProductFormState } from '../types'
import { ProductConfigureAccordionId } from '../constants'

export default function ProductVariantsForm() {
  const { t } = useTranslation('common')
  const { watch, setValue } = useFormContext<ConfigureProductFormState>()
  const [activeAccordionId, selectedVariantId, quantity] = watch([
    'activeAccordionId',
    'selectedVariantId',
    'quantity',
  ])
  const {
    field: { onChange },
  } = useController({
    name: 'selectedVariantId',
  })

  const outletId = useAppSelector(outletSelectedOutletIdSelector)
  const variants = useAppSelector(productsDetailsVariantsSelector)!

  function handleVariantChange(newVariantId: string) {
    const selectedVariant = variants.find(v => v.id === newVariantId)

    if (selectedVariant) {
      const variantStock = getDetailedProductVariantStock(
        selectedVariant,
        outletId!,
      )

      // Set quantity to max available stock if it's greater than the current quantity
      if (variantStock?.stockCount && variantStock.stockCount < quantity) {
        setValue('quantity', variantStock.stockCount)
      }

      setValue('selectedVariantId', selectedVariant.id)
      setValue(
        'selectedVariantShowingPrice',
        selectedVariant.variantPrice.unitPrice,
      )
      onChange(newVariantId)
    }
  }

  function handleToggleAccordion(isExpanded: boolean) {
    if (isExpanded) {
      const addonAccordionId = ProductConfigureAccordionId.Variants
      setValue('activeAccordionId', addonAccordionId)
    }
  }

  return (
    <ProductConfigurationAccordion
      title={t('components.productDetailsModal.choiceOfVariant')}
      expanded={activeAccordionId === ProductConfigureAccordionId.Variants}
      onToggle={handleToggleAccordion}
    >
      {variants.map(variant => {
        const { name, customerFacingName, id, variantPrice } = variant
        const isOutOfStock = isDetailedProductVariantOutOfStock(
          variant,
          outletId!,
        )

        return (
          <ProductSelection
            key={id}
            label={customerFacingName || name}
            priceLabel={
              isOutOfStock
                ? t('components.productDetailsModal.variantOutOfStock')
                : getFormattedCurrency(
                    variantPrice.unitPrice,
                    variantPrice.unitPriceCurrency,
                  )
            }
            disabled={isOutOfStock}
            selectionType={SelectableType.RadioButton}
            selected={selectedVariantId === id}
            onChange={() => handleVariantChange(id)}
          />
        )
      })}
    </ProductConfigurationAccordion>
  )
}
