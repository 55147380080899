import { useCallback } from 'react'
import { ServiceTime, ServiceTimeKindType } from '@ancon/wildcat-types'

import useAppSelector from '../../../store/hooks/useAppSelector'
import { checkoutCurrentCheckoutPenguinLockersMetaDataSelector } from '../store/checkoutSelectors'

export default function useGetBoxPickupServiceTime() {
  const boxPickupDetails = useAppSelector(
    checkoutCurrentCheckoutPenguinLockersMetaDataSelector,
  )

  const getBoxPickupServiceTime = useCallback(
    (checkoutServiceTime: ServiceTime) => {
      const { timeBegin, expireTime, pickingTime } = boxPickupDetails ?? {}

      const hasTimeSlot = !!timeBegin && !!expireTime

      if (hasTimeSlot) {
        return {
          time: pickingTime ?? timeBegin ?? checkoutServiceTime.time,
          kind: ServiceTimeKindType.AtSpecifiedTime,
        }
      }

      return checkoutServiceTime
    },
    [boxPickupDetails],
  )

  return getBoxPickupServiceTime
}
