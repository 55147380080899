import React, { useState } from 'react'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { CheckoutItem } from '@ancon/wildcat-types'
import { getCheckoutGroupedModifications } from '@ancon/wildcat-utils/checkout'
import DropDownIcon from '@ancon/wildcat-ui/shared/icons/arrow-drop-down.svg'
import clsx from 'clsx'
import { batch } from 'react-redux'
import {
  isTicketItemClosed,
  isTicketItemReady,
} from '@ancon/wildcat-utils/ticketItem'

import Button from '../../../app/components/Button'
import BodyText from '../../../app/components/BodyText'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  setProductDetailsModalVisible,
  setSelectedProductId,
} from '../../../product/store/productSlice'
import { setSelectedCheckoutItemId } from '../../store/checkoutSlice'
import { setSelectedPreOrderAttendeeId } from '../../../preOrder/store/preOrderSlice'

import CartItemQuantityWidget from './CartItemQuantityWidget'
import CartTicketItemStatus from './CartTicketItemStatus'
import styles from './CartItem.module.scss'

type CartItemProps = {
  cartItem: CheckoutItem
  preOrderAttendeeId?: string
  showSeparator?: boolean
  omitQuantityModifier?: boolean
  omitTicketItemStatus?: boolean
  disableClick?: boolean
  disabled?: boolean
  /** Whether the item should be highlighted */
  highlighted?: boolean
  /**
   * Enable/Disable item highlighting
   * @default false
   */
  highlightingEnabled?: boolean
  className?: string
  itemQuantityClassName?: string
  highlightClassName?: string
}

export default function CartItem({
  cartItem,
  preOrderAttendeeId,
  showSeparator = false,
  omitQuantityModifier = false,
  omitTicketItemStatus = false,
  disableClick = false,
  disabled = false,
  highlighted = false,
  highlightingEnabled = false,
  className,
  itemQuantityClassName,
  highlightClassName,
}: CartItemProps) {
  const dispatch = useAppDispatch()
  const {
    id,
    product,
    quantity,
    totalPriceInclTax,
    discountAmountInclTax,
    modifications,
    ticketItem,
  } = cartItem
  const { hasVariants, name, variantName } = product

  const [itemQuantity, setItemQuantity] = useState(quantity)
  const [isModifyingQuantity, setIsModifyingQuantity] = useState(false)

  const groupedModifications = getCheckoutGroupedModifications(modifications)

  const hasDiscount = discountAmountInclTax.amount > 0
  const isShowItemStatus =
    !omitTicketItemStatus &&
    !!ticketItem &&
    (isTicketItemReady(ticketItem) || isTicketItemClosed(ticketItem))

  function handleQuantityChange(changedQuantity: number) {
    setItemQuantity(changedQuantity)
  }

  function handleCartItemClick() {
    if (disableClick) return

    batch(() => {
      dispatch(setSelectedProductId(product.id))
      dispatch(setSelectedCheckoutItemId(id))
      if (preOrderAttendeeId) {
        dispatch(setSelectedPreOrderAttendeeId(preOrderAttendeeId))
      }
      dispatch(setProductDetailsModalVisible(true))
    })
  }

  function handleOpenQuantityWidget(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    event.stopPropagation()
    setIsModifyingQuantity(true)
  }

  function handleCloseQuantityWidget() {
    setIsModifyingQuantity(false)
  }

  return (
    <>
      <InteractiveDiv
        className={clsx(styles.container, className, {
          [styles.selectedItemContainer]: isModifyingQuantity,
          [styles.highlightingContainer]: highlightingEnabled,
        })}
        tabIndex={0}
        onClick={handleCartItemClick}
        disabled={disabled}
      >
        <div
          className={clsx(styles.itemQuantity, itemQuantityClassName, {
            [styles.quantityText]: omitQuantityModifier,
          })}
        >
          {omitQuantityModifier ? (
            <BodyText fontSize="1.6rem" className={styles.quantityText}>
              {itemQuantity}
            </BodyText>
          ) : (
            <Button
              variant="secondary"
              className={styles.quantityButton}
              outlined
              onClick={
                isModifyingQuantity ? undefined : handleOpenQuantityWidget
              }
              disabled={disabled}
            >
              {itemQuantity}
              <DropDownIcon />
            </Button>
          )}
          {!omitQuantityModifier && isModifyingQuantity && (
            <CartItemQuantityWidget
              onClose={handleCloseQuantityWidget}
              initialValue={itemQuantity}
              itemId={id}
              preOrderAttendeeId={preOrderAttendeeId}
              onChangeQuantity={handleQuantityChange}
              disabled={disabled}
            />
          )}
        </div>

        <div
          className={clsx(styles.itemDetailsContainer, {
            [styles.noModifications]: modifications.length === 0,
          })}
        >
          <div className={styles.productNameContainer}>
            <BodyText fontSize="1.6rem">
              {hasVariants ? variantName : name}
            </BodyText>
            {isShowItemStatus && (
              <CartTicketItemStatus status={ticketItem?.status} />
            )}
          </div>
          <div>
            {!!groupedModifications.replaced.length && (
              <>
                {groupedModifications.replaced.map((modification, index) => (
                  <BodyText
                    color="body-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >{`${modification}`}</BodyText>
                ))}
              </>
            )}
            {!!groupedModifications.added.length && (
              <>
                {groupedModifications.added.map((modification, index) => (
                  <BodyText
                    color="body-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >{`+ ${modification}`}</BodyText>
                ))}
              </>
            )}
            {!!groupedModifications.removed.length && (
              <>
                {groupedModifications.removed.map((modification, index) => (
                  <BodyText
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color="body-2"
                  >{`- ${modification}`}</BodyText>
                ))}
              </>
            )}
          </div>
        </div>
        <div className={styles.itemCost}>
          {hasDiscount && (
            <BodyText className={styles.discountPrice}>
              {getFormattedCurrency(
                totalPriceInclTax.amount + discountAmountInclTax.amount,
                totalPriceInclTax.currency,
              )}
            </BodyText>
          )}
          <BodyText fontSize="1.6rem">
            {getFormattedCurrency(
              totalPriceInclTax.amount,
              totalPriceInclTax.currency,
            )}
          </BodyText>
        </div>
        {highlightingEnabled && highlighted && (
          <div className={clsx(styles.highlight, highlightClassName)} />
        )}
      </InteractiveDiv>
      {showSeparator && <hr className={styles.separator} />}
    </>
  )
}
