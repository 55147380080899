import {
  APIPostRequest,
  APIPutRequest,
  CreateMessagingProviderPayload,
  MessagingProviderDetails,
  UpdateMessagingProviderPayload,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'
import { APIType } from '../../types'

type MessagingProviderAPIResponse = {
  customerMessagingProvider: MessagingProviderDetails
}

type MessagingProvidersAPI = {
  post: {
    create: APIPostRequest<
      MessagingProviderAPIResponse,
      CreateMessagingProviderPayload,
      {
        customerId: string
      }
    >
  }
  put: {
    update: APIPutRequest<
      MessagingProviderAPIResponse,
      UpdateMessagingProviderPayload,
      {
        customerId: string
        messagingProviderId: string
      }
    >
  }
}

const outlets: MessagingProvidersAPI = {
  post: {
    create: new Request(endpoints.user.customers.byId.messagingProviders.list, {
      apiType: APIType.User,
    }).post,
  },
  put: {
    update: new Request(
      endpoints.user.customers.byId.messagingProviders.byId.details,
      {
        apiType: APIType.User,
      },
    ).put,
  },
}

export default outlets
