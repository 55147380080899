import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import HeadingText from '../../app/components/HeadingText'
import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'

import styles from './ProductName.module.scss'

type ProductNameProps = PropsWithChildren<{
  className?: string
  as?: 'h1' | 'h2' | 'h3' | 'p' | 'span'
}>

export default function ProductName({
  className,
  children,
  as = 'p',
}: ProductNameProps) {
  const isWhiteLabeled = isAppWhiteLabeled()

  return (
    <HeadingText
      as={as}
      className={clsx(styles.productName, className, {
        [styles.whiteLabeled]: isWhiteLabeled,
      })}
    >
      {children}
    </HeadingText>
  )
}
