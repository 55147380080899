import { useMemo } from 'react'
import { CustomerAddress } from '@ancon/wildcat-types'
import LocationPinIcon from '@ancon/wildcat-ui/shared/icons/location-pin-outline.svg'
import StarIcon from '@ancon/wildcat-ui/shared/icons/star.svg'
import useTranslation from 'next-translate/useTranslation'

import getGroupedAddressTypes from '../../../../account/utils/getGroupedAddressTypes'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { clientContextCustomerAddressesSelector } from '../../../../clientContext/store/clientContextSelectors'
import useGeocodeService from '../../../../location/hooks/useGeocodeService'

import OutletSearchResultItem from './OutletSearchResultItem'

type AccountAddressSuggestionsProps = {
  onAddressClick: (
    address: CustomerAddress,
    geocodeResult: google.maps.GeocoderResult,
  ) => void
}

export default function AccountAddressSuggestions({
  onAddressClick,
}: AccountAddressSuggestionsProps) {
  const { t } = useTranslation()
  const { fetchGeocodeResults } = useGeocodeService()
  const addresses = useAppSelector(clientContextCustomerAddressesSelector)

  const { homeAddress, workAddress, otherAddresses } = useMemo(
    () => getGroupedAddressTypes(t, addresses),
    [addresses, t],
  )

  async function navigateToOutletsNearByAddress(address: CustomerAddress) {
    const { location } = address
    const geocodeResult = await fetchGeocodeResults(location)

    if (geocodeResult?.formatted_address) {
      onAddressClick(address, geocodeResult)
    }
  }

  return (
    <>
      {homeAddress && (
        <OutletSearchResultItem
          onClick={() => navigateToOutletsNearByAddress(homeAddress)}
          leftAccessory={<StarIcon />}
        >
          {homeAddress.title}
        </OutletSearchResultItem>
      )}
      {workAddress && (
        <OutletSearchResultItem
          onClick={() => navigateToOutletsNearByAddress(workAddress)}
          leftAccessory={<StarIcon />}
        >
          {workAddress.title}
        </OutletSearchResultItem>
      )}
      {otherAddresses.map(address => (
        <OutletSearchResultItem
          key={address.id}
          onClick={() => navigateToOutletsNearByAddress(address)}
          leftAccessory={<LocationPinIcon />}
        >
          {address.title}
        </OutletSearchResultItem>
      ))}
    </>
  )
}
