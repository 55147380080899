import React, { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import { ModalDisplayMode } from './Modal'
import ModalHeader, { ModalHeaderProps } from './ModalHeader'
import styles from './ModalCard.module.scss'

type ModalCardProps = PropsWithChildren<{
  mobileModalMode?: ModalDisplayMode
  onClose?: () => void
  title?: string
  className?: string
  CustomModalHeader?: FC<ModalHeaderProps>
  modalHeaderClassName?: string
}>

export default function ModalCard({
  children,
  className,
  mobileModalMode = 'modal',
  onClose,
  title,
  CustomModalHeader = ModalHeader,
  modalHeaderClassName,
}: ModalCardProps) {
  const shouldShowHeader = title || onClose

  const bottomSheet = mobileModalMode === 'bottom-sheet'
  const fullScreen = mobileModalMode === 'full-screen'

  return (
    <div
      className={clsx(
        styles.modalContent,
        {
          [styles.bottomSheet]: bottomSheet,
          [styles.fullScreen]: fullScreen,
        },
        className,
      )}
    >
      {shouldShowHeader && (
        <div className={clsx(styles.modalHeader, modalHeaderClassName)}>
          <CustomModalHeader title={title} onClose={onClose} />
        </div>
      )}
      {children}
    </div>
  )
}
