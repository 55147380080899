import { UrlObject } from 'url'

import { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'

import InteractiveDiv, {
  InteractiveDivProps,
} from '../../../../layout/components/InteractiveDiv'
import { AppTestIds } from '../../../../app/constants'

import styles from './OutletSearchResultItem.module.scss'

type OutletSearchResultItemProps = PropsWithChildren<{
  leftAccessory?: React.ReactNode
  url?: string | UrlObject
  onClick?: () => void
}> &
  InteractiveDivProps

export default function OutletSearchResultItem({
  children,
  url,
  onClick,
  leftAccessory,
  ...interactiveDivProps
}: OutletSearchResultItemProps) {
  const router = useRouter()
  function handleClick() {
    onClick?.()
    if (url) {
      router.push(url)
    }
  }
  return (
    <InteractiveDiv
      className={styles.container}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...interactiveDivProps}
      onClick={handleClick}
      data-testid={AppTestIds.OutletSearchResultItem}
    >
      <div className={styles.leftAccessory}>{leftAccessory}</div>
      {children}
    </InteractiveDiv>
  )
}
