import WarningIcon from '@ancon/wildcat-ui/shared/icons/warning-fill.svg'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import useTranslation from 'next-translate/useTranslation'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'

import styles from './CheckoutOutOfStockBanner.module.scss'

type CheckoutOutOfStockBannerProps = {
  warningText?: string
  onReviewCartClick?: () => void
}

export default function CheckoutOutOfStockBanner({
  warningText,
  onReviewCartClick,
}: CheckoutOutOfStockBannerProps) {
  const { t } = useTranslation('common')

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <WarningIcon />
      </div>

      <BodyText className={styles.warningText}>
        {warningText || t('cartOutOfStockModal.someItemsOutOfStock')}
      </BodyText>
      {!!onReviewCartClick && (
        <Button
          onClick={onReviewCartClick}
          variant="secondary"
          className={styles.actionButton}
        >
          {t('cartOutOfStockModal.review')}
        </Button>
      )}
    </div>
  )
}
