import { ToastEvent } from '../types'

export default function getToastDragX(dragEvent: ToastEvent.Drag) {
  const { targetTouches } = dragEvent as ToastEvent.Touch

  if (targetTouches?.length) {
    return targetTouches[0].clientX
  }

  return (dragEvent as ToastEvent.Mouse).clientX
}
