import useAppSelector from '../../../store/hooks/useAppSelector'
import Skeleton from '../../app/components/Skeleton'
import { productOutletMenuItemSelector } from '../store/productSelectors'

import styles from './ProductDetailsModalSkeleton.module.scss'

function ProductModificationSkeleton() {
  return (
    <div className={styles.variantContainer}>
      <Skeleton className={styles.variantTitle} />
      <div className={styles.variantItem}>
        <div className={styles.variantNameContainer}>
          <Skeleton className={styles.variantSelection} />
          <Skeleton className={styles.variantName} />
        </div>
        <Skeleton className={styles.variantPrice} />
      </div>
      <div className={styles.variantItem}>
        <div className={styles.variantNameContainer}>
          <Skeleton className={styles.variantSelection} />
          <Skeleton className={styles.variantName} />
        </div>
        <Skeleton className={styles.variantPrice} />
      </div>
      <div className={styles.variantItem}>
        <div className={styles.variantNameContainer}>
          <Skeleton className={styles.variantSelection} />
          <Skeleton className={styles.variantName} />
        </div>
        <Skeleton className={styles.variantPrice} />
      </div>
    </div>
  )
}

export default function ProductDetailsModalSkeleton() {
  const productMenuItem = useAppSelector(productOutletMenuItemSelector)
  const hasProductMenuItem = !!productMenuItem
  const { hasVariants, hasAddOnGroups, hasIngredients, productDescription } =
    productMenuItem || {}

  return (
    <div className={styles.container}>
      <Skeleton className={styles.productImage} />

      <div className={styles.metaContainer}>
        <Skeleton className={styles.productName} />
        <Skeleton className={styles.priceLabel} />
        {(!!productDescription || !hasProductMenuItem) && (
          <Skeleton className={styles.description} />
        )}
      </div>
      <div className={styles.modificationsContainer}>
        {(hasVariants || !hasProductMenuItem) && (
          <ProductModificationSkeleton />
        )}
        {(hasIngredients || !hasProductMenuItem) && (
          <ProductModificationSkeleton />
        )}
        {(hasAddOnGroups || !hasProductMenuItem) && (
          <ProductModificationSkeleton />
        )}
      </div>
      <div className={styles.footerContainer}>
        <Skeleton className={styles.quantityModifier} />
        <Skeleton className={styles.addToCart} />
      </div>
    </div>
  )
}
