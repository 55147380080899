import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

import useAppSelector from '../../../store/hooks/useAppSelector'
import Modal from '../../app/components/modal/Modal'
import { isUserWithIncompleteCustomerDetailsSelector } from '../../clientContext/store/clientContextSelectors'
import SignUpForm from '../forms/SignUpForm'

import styles from './FillYourInfoModal.module.scss'

export default function FillYourInfoModal() {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const isUserWithIncompleteDetails = useAppSelector(
    isUserWithIncompleteCustomerDetailsSelector,
  )

  useEffect(() => {
    if (isUserWithIncompleteDetails) {
      setIsOpen(true)
    }
  }, [isUserWithIncompleteDetails])

  function handleOnSuccess() {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('fillYourInfoModal.title')}
      hideClose
      preventOnClose
      mobileModalMode="bottom-sheet"
      className={styles.modal}
    >
      <SignUpForm
        title={t('fillYourInfoModal.message')}
        isIncompleteCustomerSignUp
        onSuccess={handleOnSuccess}
      />
    </Modal>
  )
}
