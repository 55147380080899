import { CheckDetails } from '@ancon/wildcat-types'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'
import getFormattedQuantity from '@ancon/wildcat-utils/quantity/getFormattedQuantity'

import { PayAndGoCartSummary } from '../types'

import getPayAndGoCheckDiscount from './getPayAndGoCheckDiscount'

function getTipAmount(tip: number | null, currency: string): string | null {
  if (tip) {
    return getFormattedCurrency(tip, currency, {
      trimTrailingZeros: false,
      showAsManyDecimalsAsNeeded: true,
    })
  }

  return null
}

function getSubtotalExclTaxAmount(
  check: CheckDetails,
  certainAmount: number | null,
): string {
  if (certainAmount != null) {
    const taxPercentage = check.taxAmount.amount / check.subtotalExclTax.amount

    const subtotalExclTaxAmount = certainAmount / (1 + taxPercentage)

    return getFormattedCurrency(
      subtotalExclTaxAmount,
      check.subtotalExclTax.currency,
      { trimTrailingZeros: false, showAsManyDecimalsAsNeeded: true },
    )
  }

  return getFormattedCurrency(
    check.subtotalExclTax.amount,
    check.subtotalExclTax.currency,
    { trimTrailingZeros: false, showAsManyDecimalsAsNeeded: true },
  )
}

function getSubtotalInclTaxAmount(
  check: CheckDetails,
  certainAmount: number | null,
): string {
  if (certainAmount != null) {
    return getFormattedCurrency(certainAmount, check.totalAmount.currency, {
      trimTrailingZeros: false,
      showAsManyDecimalsAsNeeded: true,
    })
  }

  return getFormattedCurrency(
    check.subtotalInclTax.amount,
    check.subtotalInclTax.currency,
    { trimTrailingZeros: false, showAsManyDecimalsAsNeeded: true },
  )
}

function getTaxAmount(
  check: CheckDetails,
  certainAmount: number | null,
): string {
  if (certainAmount != null) {
    const taxPercentage = check.taxAmount.amount / check.subtotalExclTax.amount

    const subtotalExclTaxAmount = certainAmount / (1 + taxPercentage)

    const taxAmount = Math.max(0, certainAmount - subtotalExclTaxAmount)

    return getFormattedCurrency(taxAmount, check.taxAmount.currency, {
      trimTrailingZeros: false,
      showAsManyDecimalsAsNeeded: true,
    })
  }

  return getFormattedCurrency(
    check.taxAmount.amount,
    check.taxAmount.currency,
    {
      trimTrailingZeros: false,
      showAsManyDecimalsAsNeeded: true,
    },
  )
}

function getTotalAmount(
  check: CheckDetails,
  tipAmount: number | null,
  certainAmount: number | null,
): string {
  const { currency } = check.totalAmount

  const base = certainAmount ?? check.remainingAmountToPay.amount

  const total = base + (tipAmount ?? 0)

  return getFormattedCurrency(total, currency, {
    trimTrailingZeros: false,
    showAsManyDecimalsAsNeeded: true,
  })
}

function getPayAndGoCartSummaryFromCheckDetails(
  check: CheckDetails,
  tipInAmount: number | null,
  tipInPercentage: number | null,
  certainAmount: number | null,
): PayAndGoCartSummary {
  const discount = getPayAndGoCheckDiscount(check)

  const subtotalExclTaxAmount = getSubtotalExclTaxAmount(check, certainAmount)

  const subtotalInclTaxAmount = getSubtotalInclTaxAmount(check, certainAmount)

  const taxAmount = getTaxAmount(check, certainAmount)

  const tipAmount = getTipAmount(tipInAmount, check.totalAmount.currency)

  const totalAmount = getTotalAmount(check, tipInAmount, certainAmount)

  const totalItemsCount = getFormattedQuantity(check.items.length)

  const certainAmountText =
    certainAmount != null
      ? getFormattedCurrency(certainAmount, check.totalAmount.currency, {
          trimTrailingZeros: false,
          showAsManyDecimalsAsNeeded: true,
        })
      : null

  return {
    discount,
    subtotalExclTaxAmount,
    subtotalInclTaxAmount,
    taxAmount,
    tipAmount,
    tipPercentage: tipInPercentage,
    totalAmount,
    totalItemsCount,
    certainAmount: certainAmountText,
  }
}

export default getPayAndGoCartSummaryFromCheckDetails
