import {
  APIGetRequest,
  APIParams,
  NearbyOutletsListQueryParams,
  NearbyOutletsPaginatedResponse,
  OutletSearchListItem,
  PaginatedResponse,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type OutletsAPI = {
  get: {
    nearby: APIGetRequest<
      NearbyOutletsPaginatedResponse,
      NearbyOutletsListQueryParams
    >
    search: APIGetRequest<PaginatedResponse<OutletSearchListItem>, APIParams>
  }
}

const outlets: OutletsAPI = {
  get: {
    nearby: new Request(endpoints.core.outlets.nearby.list, {
      version: 1.1,
    }).get,
    search: new Request(endpoints.core.outlets.search.list).get,
  },
}

export default outlets
