import { SerializedError, miniSerializeError } from '@reduxjs/toolkit'

function serializeError(x: any): SerializedError {
  if (x?.isAxiosError) {
    return {
      name: x.name,
      message: x.message,
      stack: x.stack,
      code: x.code,
      isAxiosError: true,
      response: {
        data: x.response?.data,
      },
    }
  }

  return miniSerializeError(x)
}

export default serializeError
