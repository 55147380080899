import { User } from 'firebase/auth'

import { FirebaseUser } from '../types'

export default function serializeFirebaseUser(user: User): FirebaseUser {
  return {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    isAnonymous: user.isAnonymous,
  }
}
