import { RootState } from '../../../store/types'

export const isOneSignalInitializedSelector = (state: RootState) =>
  state.notification.isInitialized

export const isNotificationPermissionGrantedSelector = (state: RootState) =>
  state.notification.isPermissionGranted

export const isNotificationSubscribedSelector = (state: RootState) =>
  state.notification.isSubscribed
