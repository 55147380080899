import Logger from '@ancon/wildcat-utils/logger/Logger'

import AppLogStrategy from './appLogStrategy'

const logStrategy = new AppLogStrategy()

const logger = new Logger(logStrategy)

export { logStrategy }

export default logger
