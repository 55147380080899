import {
  APIGetRequest,
  APIPostRequest,
  APIPutRequest,
  CustomerAddress,
  APIDeleteRequest,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'
import { APIType } from '../../types'
import {
  CreateCustomerAddressRequestBody,
  CreateCustomerAddressResponse,
} from '../../../features/account/types'

type AddressAPI = {
  post: {
    create: APIPostRequest<
      CreateCustomerAddressResponse,
      CreateCustomerAddressRequestBody,
      { customerId: string }
    >
  }
  get: {
    list: APIGetRequest<{ items: CustomerAddress[] }, { customerId: string }>
  }
  put: {
    update: APIPutRequest<
      { customerAddress: CustomerAddress },
      CreateCustomerAddressRequestBody,
      { customerId: string; addressId: string }
    >
  }
  delete: {
    deleteAddress: APIDeleteRequest<
      void,
      {},
      { customerId: string; addressId: string }
    >
  }
}

const addresses: AddressAPI = {
  get: {
    list: new Request(endpoints.user.customers.byId.addresses.list, {
      apiType: APIType.User,
    }).get,
  },
  post: {
    create: new Request(endpoints.user.customers.byId.addresses.list, {
      apiType: APIType.User,
    }).post,
  },
  put: {
    update: new Request(endpoints.user.customers.byId.addresses.byId.details, {
      apiType: APIType.User,
    }).put,
  },
  delete: {
    deleteAddress: new Request(
      endpoints.user.customers.byId.addresses.byId.details,
      {
        apiType: APIType.User,
      },
    ).delete,
  },
}

export default addresses
