import React from 'react'
import clsx from 'clsx'
import { Amount } from '@ancon/wildcat-types'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'
import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../../app/components/BodyText'

import styles from './OrderGrandTotal.module.scss'

type OrderGrandTotalProps = {
  total: Amount
  totalItemsQty?: number
  showSeparator?: boolean
}

export default function OrderGrandTotal({
  total,
  totalItemsQty = 0,
  showSeparator = false,
}: OrderGrandTotalProps) {
  const { t } = useTranslation('common')

  return (
    <div
      className={clsx(styles.container, {
        [styles.separator]: showSeparator,
      })}
    >
      <div className={styles.rightContent}>
        {totalItemsQty > 0 && (
          <div className={styles.totalItemsCount}>
            <BodyText fontSize="1.2rem">{totalItemsQty}</BodyText>
          </div>
        )}
        <div>
          <BodyText fontSize="1.6rem" className={styles.bold}>
            {t('components.order.total')}
          </BodyText>
          <BodyText color="body-1" className={styles.bold}>
            {t('components.order.vatIncluded')}
          </BodyText>
        </div>
      </div>
      <BodyText
        fontSize={showSeparator ? '2.4rem' : '1.6rem'}
        className={styles.bold}
      >
        {getFormattedCurrency(total.amount, total.currency)}
      </BodyText>
    </div>
  )
}
