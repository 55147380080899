import {
  APIDeleteRequest,
  APIGetRequest,
  APIPostRequest,
  APIPutRequest,
  CustomerPreOrdersPaginatedResponse,
  GroupPreOrderCreatePayload,
  PreOrderAttendeeSignInPayload,
  PreOrderAttendeeSignInResponse,
  PreOrderCheckoutDetailsResponse,
  PreOrderCheckoutUpdatePayload,
  PreOrderLinkResponse,
  PreOrderSettingsResponse,
} from '@ancon/wildcat-types'

import endpoints from '../endpoints'
import Request from '../../client/Request'

import { ProductPriceChangeAdditionalData } from './checkouts'

type PreOrdersAPI = {
  get: {
    preOrderLink: APIGetRequest<PreOrderLinkResponse, { checkoutId: string }>
    details: APIGetRequest<
      PreOrderCheckoutDetailsResponse,
      { outletId: string; preOrderId: string; attendeeId: string }
    >
    customerPreOrders: APIGetRequest<CustomerPreOrdersPaginatedResponse>
    preOrderSettings: APIGetRequest<
      PreOrderSettingsResponse,
      { tenantId: string }
    >
  }
  post: {
    createGroupOrder: APIPostRequest<
      PreOrderCheckoutDetailsResponse,
      GroupPreOrderCreatePayload,
      { outletId: string }
    >
  }
  put: {
    activate: APIPutRequest<{}, {}, { outletId: string; checkoutId: string }>
    attendeeSignIn: APIPutRequest<
      PreOrderAttendeeSignInResponse,
      PreOrderAttendeeSignInPayload,
      { outletId: string; checkoutId: string }
    >
    confirm: APIPutRequest<
      void,
      {},
      { outletId: string; checkoutId: string; attendeeId: string }
    >
    update: APIPutRequest<
      { items?: ProductPriceChangeAdditionalData[] },
      PreOrderCheckoutUpdatePayload,
      { outletId: string; checkoutId: string }
    >
  }
  delete: {
    deleteAttendee: APIDeleteRequest<
      void,
      {},
      { outletId: string; checkoutId: string; attendeeId: string }
    >
  }
}

const preOrders: PreOrdersAPI = {
  get: {
    preOrderLink: new Request(
      endpoints.core.preOrderCheckouts.byId.summary.list,
    ).get,
    details: new Request(
      endpoints.core.outlets.byId.preOrders.byId.attendees.byId.details,
    ).get,
    customerPreOrders: new Request(
      endpoints.core.customers.preOrderCheckouts.list,
    ).get,
    preOrderSettings: new Request(
      endpoints.core.tenants.byId.preOrders.settings.list,
    ).get,
  },
  post: {
    createGroupOrder: new Request(
      endpoints.core.outlets.byId.preOrderCheckouts.createGroupOrder.list,
    ).post,
  },
  put: {
    activate: new Request(
      endpoints.core.outlets.byId.preOrderCheckouts.byId.activate.list,
    ).put,
    attendeeSignIn: new Request(
      endpoints.core.outlets.byId.preOrderCheckouts.byId.attendeeSignIn.list,
    ).put,
    confirm: new Request(
      endpoints.core.outlets.byId.preOrderCheckouts.byId.attendees.byId.confirm.list,
    ).put,
    update: new Request(endpoints.core.outlets.byId.checkouts.byId.details, {
      version: 1.1,
    }).put,
  },
  delete: {
    deleteAttendee: new Request(
      endpoints.core.outlets.byId.preOrderCheckouts.byId.attendees.byId.details,
    ).delete,
  },
}

export default preOrders
