import { ServiceTimeKindType } from '@ancon/wildcat-types'

import { CheckoutReducerState } from '../types'
import { AddTipModalInitialState } from '../../app/components/addTipModal/constants'

export const checkoutInitialState: CheckoutReducerState = {
  checkoutFilters: {
    orderFormat: null,
    serviceTime: { kind: ServiceTimeKindType.AsSoonAsPossible, time: '' },
    salesLocation: null,
    delivery: null,
    section: null,
  },

  currentCheckoutOutletId: null,
  currentCheckoutOutletListDetails: null,

  currentCheckoutId: null,
  currentCheckoutDetails: null,
  currentCheckoutSelectedCheckoutItemId: null,
  currentCheckoutDetailsFetchPending: false,
  currentCheckoutPaymentPending: false,
  checkoutOutletListItemFetchPending: false,

  checkoutWayOfPaymentsFetchPending: false,
  adyenPaymentMethodsFetchPending: false,
  hasAdyenPaymentMethods: false,

  isFetchingCheckoutAdyenPaymentMethods: false,
  checkoutAdyenPaymentMethods: null,
  checkoutAdyenPaymentMethodsError: null,
  checkoutAdyenPaymentAttemptCount: 1,

  checkoutAddItemPending: false,
  checkoutUpdatePending: false,
  checkoutApplyDiscountPending: false,
  checkoutUpdateItemQuantityPending: false,
  checkoutDeletePending: false,
  checkoutUpdateItemPending: false,
  checkoutValidatePending: false,
  checkoutCancelPending: false,

  checkoutSummaryFetchPending: false,
  checkoutSummary: null,
  checkoutSummaryOutletListItem: null,

  isMinimumOrderModalVisible: false,
  isDiscountModalVisible: false,
  isCustomerInfoModalVisible: false,
  isVisibleDeleteOrderModal: false,
  isVisibleBoxPickupScheduleOrderModal: false,

  checkoutStockErrorAdditionalData: null,
  checkoutInvalidDataErrorAdditionalData: null,

  checkoutStatusLastUpdatedAt: 0,

  checkoutTip: AddTipModalInitialState,
}
