import { PropsWithChildren, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { PortalHostId } from '../constants'
import useMountedState from '../hooks/useMountedState'

type PortalProps = PropsWithChildren<{
  hostId: PortalHostId
}>

function Portal({ hostId, children }: PortalProps) {
  const containerRef = useRef<HTMLElement | null>(null)

  const mounted = useMountedState()

  useEffect(() => {
    const container = document.getElementById(hostId)

    if (!container) {
      document.body.insertAdjacentHTML(
        'beforeend',
        `<div id="${hostId}"></div>`,
      )
    }

    containerRef.current = document.getElementById(hostId)
  }, [hostId])

  if (mounted && containerRef.current && children) {
    return ReactDOM.createPortal(children, containerRef.current)
  }

  return null
}

export default Portal
