import useTranslation from 'next-translate/useTranslation'
import formatPhoneNumber from '@ancon/wildcat-utils/phoneNumber/formatPhoneNumber'

import Modal from '../../app/components/modal/Modal'
import useAppSelector from '../../../store/hooks/useAppSelector'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  preOrderCheckoutHostSelector,
  preOrderHostInfoModalVisibleSelector,
} from '../store/preOrderSelectors'
import { setPreOrderHostInfoModalVisible } from '../store/preOrderSlice'

import PreOrderInfoField from './PreOrderInfoField'
import styles from './PreOrderHostInfoModal.module.scss'

export default function PreOrderHostInfoModal() {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(preOrderHostInfoModalVisibleSelector)

  const preOrderHost = useAppSelector(preOrderCheckoutHostSelector)!

  const { name, telephoneNumber } = preOrderHost

  function handleOnClose() {
    dispatch(setPreOrderHostInfoModalVisible(false))
  }

  return (
    <Modal
      title={t('preOrder.preOrderHostInfo')}
      isOpen={isOpen}
      onClose={handleOnClose}
      mobileModalMode="bottom-sheet"
      contentClassName={styles.modalContent}
    >
      <div className={styles.modalBody}>
        <div className={styles.hostInformation}>
          <PreOrderInfoField name={t('user.name')}>{name}</PreOrderInfoField>
          {telephoneNumber && (
            <PreOrderInfoField name={t('user.phoneNumber')}>
              {formatPhoneNumber(telephoneNumber)}
            </PreOrderInfoField>
          )}
        </div>
      </div>
    </Modal>
  )
}
