import {
  CompanyMemberInvitation,
  CreateCompanyMemberAccountResponse,
} from '@ancon/wildcat-types'

import { AppError } from '../app/types'

export type CompanyReducerState = {
  /** Company member data in invitation link */
  memberInvitation: CompanyMemberInvitation | null

  /** Company member account created after accepting invitation */
  createdMemberAccount: CompanyMemberAccount | null

  /** Step in invitation sign up flow */
  memberInvitationSignUpStep: CompanyInvitationSignUpStep

  /** Error when accepting company member invitation */
  memberInvitationSignUpError: AppError | null

  /** Company member ID whose email is being verified */
  verificationPendingMemberId: string | null
}

export enum CompanyInvitationSignUpStep {
  Verification,
  AcceptInvitation,
  Finished,
}

export type CompanyInvitationSignUpFormState = {
  email: string
  firstName: string
  lastName: string
  password: string
  phoneNumber: string
  department: string
}

export type CompanyMemberAccount =
  CreateCompanyMemberAccountResponse['companyMember']

export type MemberInvitationWithCredentials = CompanyMemberInvitation & {
  password: string
}
