import { PayloadAction } from '@reduxjs/toolkit'

import { AccountReducerState, ConfigureAddressModalState } from '../types'

const accountReducers = {
  setAccountConfigureAddressModalState(
    state: AccountReducerState,
    action: PayloadAction<ConfigureAddressModalState>,
  ) {
    state.configureAddressModalState = action.payload
  },
  setAccountDeleteModalVisible(
    state: AccountReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isAccountDeleteModalVisible = action.payload
  },
}

export default accountReducers
