import { createEntityAdapter } from '@reduxjs/toolkit'
import { SaleGroupItem } from '@ancon/wildcat-types'

import { PayAndGoSelectedItem } from '../types'

export const payAndGoSelectedItemEntityAdapter =
  createEntityAdapter<PayAndGoSelectedItem>({
    selectId: item => item.ids.join('_'),
  })

export const payAndGoUnpaidItemEntityAdapter =
  createEntityAdapter<SaleGroupItem>({
    selectId: item => item.ids.join('_'),
  })

export const payAndGoPaidItemEntityAdapter = createEntityAdapter<SaleGroupItem>(
  {
    selectId: item => item.ids.join('_'),
  },
)

export const payAndGoOngoingItemEntityAdapter =
  createEntityAdapter<SaleGroupItem>({
    selectId: item => item.ids.join('_'),
  })
