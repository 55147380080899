import useTranslation from 'next-translate/useTranslation'

import Modal from '../../../app/components/modal/Modal'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { checkoutIsVisibleBoxPickupScheduleOrderModalSelector } from '../../store/checkoutSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { checkoutSetIsVisibleBoxPickupScheduleOrderModal } from '../../store/checkoutSlice'
import { AppRoutes } from '../../../app/constants'
import useRouteMatch from '../../../app/hooks/useRouteMatch'

import styles from './BoxPickupScheduleOrderModal.module.scss'
import BoxPickupScheduleOrderModalBody from './BoxPickupScheduleOrderModalBody'

type BoxPickupScheduleOrderModalProps = {
  onClickContinue: () => void
}

export default function BoxPickupScheduleOrderModal({
  onClickContinue,
}: BoxPickupScheduleOrderModalProps) {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const isCheckoutPage = useRouteMatch([AppRoutes.Checkout])

  const isOpen = useAppSelector(
    checkoutIsVisibleBoxPickupScheduleOrderModalSelector,
  )

  function handleClose() {
    dispatch(checkoutSetIsVisibleBoxPickupScheduleOrderModal(false))
  }

  function handleOnClickContinue() {
    handleClose()
    if (!isCheckoutPage) {
      onClickContinue()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('boxPickupScheduleOrderModal.title')}
      mobileModalMode="bottom-sheet"
      className={styles.modal}
      onClose={handleClose}
    >
      <BoxPickupScheduleOrderModalBody
        onClickContinue={handleOnClickContinue}
      />
    </Modal>
  )
}
