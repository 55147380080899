import { useRouter } from 'next/router'
import { batch } from 'react-redux'
import { CustomerAddress } from '@ancon/wildcat-types'
import useTranslation from 'next-translate/useTranslation'

import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { AppRoutes } from '../../../../app/constants'
import {
  outletsSetOutletsFilters,
  outletsSetVisibleOutletSearchModal,
} from '../../../store/outletsSlice'
import { OutletSearchModalType } from '../../../types'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { outletsVisibleOutletSearchModalSelector } from '../../../store/outletsSelectors'
import { locationSetCurrentGeoPositionName } from '../../../../location/store/locationSlice'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'

import CurrentLocationSuggestion from './CurrentLocationSuggestion'
import PlacesSuggestions from './PlacesSuggestions'
import OutletSuggestions from './OutletSuggestions'
import AccountAddressSuggestions from './AccountAddressSuggestions'

export enum OutletSearchContext {
  Places,
  Outlets,
  All,
}

type OutletSearchResultsSectionProps = {
  context?: OutletSearchContext
  searchTerm?: string
  hideTitle?: boolean
}

export default function OutletSearchSuggestions({
  searchTerm,
  hideTitle = false,
  context = OutletSearchContext.All,
}: OutletSearchResultsSectionProps) {
  const { t } = useTranslation('common')
  const isWhiteLabeled = isAppWhiteLabeled()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const outletSearchModalVisible = useAppSelector(
    outletsVisibleOutletSearchModalSelector,
  )

  const showNearByRestaurantsSuggestion =
    (context === OutletSearchContext.All ||
      context === OutletSearchContext.Places) &&
    !searchTerm
  const showPlaceSuggestions =
    !showNearByRestaurantsSuggestion &&
    (context === OutletSearchContext.Places ||
      context === OutletSearchContext.All)
  const showOutletSuggestions =
    !showNearByRestaurantsSuggestion &&
    (context === OutletSearchContext.Outlets ||
      context === OutletSearchContext.All)

  function handlePlaceSearchResultItemClick(placeId: string) {
    dispatch(
      outletsSetOutletsFilters({
        searchTerm: undefined,
      }),
    )
    router.push({
      pathname: AppRoutes.Outlets,
      query: { pl: placeId },
    })

    if (outletSearchModalVisible) {
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    }
  }

  function handleNavigateToNearByRestaurants(
    geocodeResult: google.maps.GeocoderResult,
  ) {
    batch(() => {
      dispatch(
        locationSetCurrentGeoPositionName(geocodeResult.formatted_address),
      )
      dispatch(
        outletsSetOutletsFilters({
          searchTerm: undefined,
        }),
      )
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    })
    router.push({
      pathname: AppRoutes.Outlets,
      query: {
        pl: geocodeResult.place_id,
      },
    })
  }

  function handleNavigateToNearByRestaurantsOfAddress(
    address: CustomerAddress,
    geocodeResult: google.maps.GeocoderResult,
  ) {
    batch(() => {
      dispatch(
        locationSetCurrentGeoPositionName(geocodeResult.formatted_address),
      )
      dispatch(
        outletsSetOutletsFilters({
          searchTerm: undefined,
        }),
      )
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    })
    router.push({
      pathname: AppRoutes.Outlets,
      query: {
        pl: geocodeResult.place_id,
      },
    })
  }

  return (
    <>
      {showNearByRestaurantsSuggestion && (
        <CurrentLocationSuggestion
          label={
            isWhiteLabeled
              ? t('components.deliveryLocationPickerModal.currentLocation')
              : t('components.outletSearchModal.nearbyRestaurants')
          }
          onClick={handleNavigateToNearByRestaurants}
        />
      )}
      {showNearByRestaurantsSuggestion && (
        <AccountAddressSuggestions
          onAddressClick={handleNavigateToNearByRestaurantsOfAddress}
        />
      )}
      {showOutletSuggestions && (
        <OutletSuggestions query={searchTerm} hideTitle={hideTitle} />
      )}
      {showPlaceSuggestions && (
        <PlacesSuggestions
          query={searchTerm}
          hideTitle={hideTitle}
          onClickPlaceSearchResultItem={handlePlaceSearchResultItemClick}
        />
      )}
    </>
  )
}
