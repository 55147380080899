import { PayloadAction } from '@reduxjs/toolkit'
import { ErrorCode } from '@ancon/wildcat-types'

import { AppReducerState, InformativeError } from '../types'

const appReducers = {
  appSetIsErrorModalVisible(
    state: AppReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isErrorModalVisible = action.payload
    if (!action.payload) {
      state.errorModalConfig = null
    }
  },
  appShowError<T extends ErrorCode = never>(
    state: AppReducerState,
    action: PayloadAction<InformativeError<T> | undefined>,
  ) {
    if (action.payload) {
      state.isErrorModalVisible = true
      state.errorModalConfig = action.payload as InformativeError
    }
  },
  appSetIsCartDrawerVisible(
    state: AppReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isCartDrawerVisible = action.payload
  },
  appSetIsMobileDrawerVisible(
    state: AppReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isMobileDrawerVisible = action.payload
  },
  appSetIsSwitchLanguageModalVisible(
    state: AppReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isSwitchLanguageModalVisible = action.payload
  },
  appSetOrderDrawerOrderId(
    state: AppReducerState,
    action: PayloadAction<string | null>,
  ) {
    state.orderDrawerOrderId = action.payload
  },
  appSetLanguage(state: AppReducerState, action: PayloadAction<string>) {
    state.language = action.payload
  },
  appSetIsSignalROnline(
    state: AppReducerState,
    action: PayloadAction<{ isSignalROnline: boolean }>,
  ) {
    state.isSignalROnline = action.payload.isSignalROnline
  },
}

export default appReducers
