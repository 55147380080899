import { v4 } from 'uuid'
import {
  LogLevel,
  LogStrategy,
  sanitizeAsStringDict,
  pascalCaseFormatter,
} from '@ancon/wildcat-utils/logger'
import noop from 'lodash/noop'

import api from '../api'

class ArchiveLogStrategy implements LogStrategy {
  private tenantId?: string

  private outletId?: string

  private checkoutId?: string

  sanitize(data: unknown) {
    return sanitizeAsStringDict(data)
  }

  format(data: unknown) {
    return pascalCaseFormatter(data)
  }

  async execute(
    logLevel: LogLevel,
    message: string,
    properties: Record<string, string>,
  ) {
    // Values are sanitized and converted to string at this point
    const correlationId =
      properties.CorrelationId === 'undefined' ? v4() : properties.CorrelationId

    api.archive.logging.post
      .create(
        {
          application: 'Wildcat.Client.OrderWeb',
          logLevel,
          message,
          properties: {
            ...properties,
            Time: new Date().toISOString(),
          },
          correlationId,
          tenantId: this.tenantId,
          outletId: this.outletId,
          checkoutId: this.checkoutId,
        },
        undefined,
        {
          'x-correlation-id': correlationId,
        },
      )
      .catch(noop)
  }

  setContext({
    tenantId,
    outletId,
    checkoutId,
  }: {
    tenantId?: string
    outletId?: string
    checkoutId?: string
  }) {
    this.tenantId = tenantId
    this.outletId = outletId
    this.checkoutId = checkoutId
  }

  setTenantId(tenantId: string): void {
    this.tenantId = tenantId
  }

  setOutletId(outletId: string): void {
    this.outletId = outletId
  }

  setCheckoutId(checkoutId: string): void {
    this.checkoutId = checkoutId
  }
}

const archiveLogStrategy = new ArchiveLogStrategy()

export default archiveLogStrategy
