import clsx from 'clsx'

import BodyText from '../../app/components/BodyText'
import Selectable, { SelectableType } from '../../app/components/Selectable'
import { AppTestIds } from '../../app/constants'

import styles from './ProductSelection.module.scss'

type ProductSelectionProps = {
  label: string
  onChange: (checked: boolean) => void
  selected: boolean
  selectionType?: SelectableType
  priceLabel?: string
  disabled?: boolean
}

export default function ProductSelection({
  label,
  selected,
  onChange,
  priceLabel,
  selectionType = SelectableType.CheckBox,
  disabled = false,
}: ProductSelectionProps) {
  return (
    <div
      className={clsx(styles.container, {
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? undefined : () => onChange(!selected)}
      role="presentation"
      data-testid={AppTestIds.Order.Modal.ProductSelection.Container + label}
    >
      <Selectable
        type={selectionType}
        checked={selected}
        disabled={disabled}
        onClick={() => onChange(!selected)}
        data-testid={AppTestIds.Order.Modal.ProductSelection.Selectable + label}
      />
      <div className={styles.contentContainer}>
        <BodyText>{label}</BodyText>
        {/* TODO: Replace with SpotlightText */}
        {priceLabel && <BodyText>{priceLabel}</BodyText>}
      </div>
    </div>
  )
}
