import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { batch } from 'react-redux'

import Modal from '../../app/components/modal/Modal'
import Button from '../../app/components/Button'
import BodyText from '../../app/components/BodyText'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  preOrderClearCheckout,
  setPreOrderLeaveModalVisible,
  setPreOrderUser,
} from '../store/preOrderSlice'
import localStorageUtils from '../../app/utils/localStorageUtils'
import { preOrderLeaveModalVisibleSelector } from '../store/preOrderSelectors'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../app/constants'
import storedPreOrderUserToken from '../utils/storedPreOrderUserToken'

import styles from './LeavePreOrderModal.module.scss'

export default function LeavePreOrderModal() {
  const { t } = useTranslation('common')

  const router = useRouter()

  const dispatch = useAppDispatch()

  const isModalOpen = useAppSelector(preOrderLeaveModalVisibleSelector)

  const isManagePage = useRouteMatch([AppRoutes.PreOrderManage])

  function handleClose() {
    dispatch(setPreOrderLeaveModalVisible(false))
  }

  function handleLeavePreOrder() {
    batch(() => {
      dispatch(preOrderClearCheckout())
      dispatch(setPreOrderUser(null))
    })
    localStorageUtils.removeItem('cartMeta')
    storedPreOrderUserToken.remove()
    handleClose()

    if (isManagePage) {
      router.replace({ pathname: '/' })
    }
  }

  return (
    <Modal
      title={t('preOrder.leaveOrderModal.title')}
      isOpen={isModalOpen}
      onClose={handleClose}
      contentClassName={styles.modalContent}
      mobileModalMode="bottom-sheet"
    >
      <div className={styles.modalBody}>
        <BodyText color="body-1">
          {t('preOrder.leaveOrderModal.message')}
        </BodyText>
      </div>
      <div className={styles.modalFooter}>
        <Button variant="secondary" onClick={handleClose}>
          {t('preOrder.leaveOrderModal.no')}
        </Button>
        <Button onClick={handleLeavePreOrder}>
          {t('preOrder.leaveOrderModal.yes')}
        </Button>
      </div>
    </Modal>
  )
}
