import { AxiosResponse } from 'axios'
import createCodedError from '@ancon/wildcat-utils/error/createCodedError'
import { ErrorCode } from '@ancon/wildcat-types'

import logger from '../../utils/logger'

function handleResponse(response: AxiosResponse) {
  if (response.headers.deprecation) {
    const err = createCodedError(
      `Deprecated endpoint version ${response.config.endpoint ?? ''}`.trim(),
      ErrorCode.DeprecatedEndpointVersion,
      {
        baseURL: response.config.baseURL,
        url: response.config.url,
        expires: response.headers['deprecation-sunset'],
      },
    )

    logger.warn(err.message, err)
  }

  return response
}

export default handleResponse
