import { getOutletOrderFormats } from '@ancon/wildcat-utils/outlet'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { OrderFormat } from '@ancon/wildcat-types'
import { batch } from 'react-redux'

import useAppSelector from '../../../store/hooks/useAppSelector'
import Modal from '../../app/components/modal/Modal'
import {
  outletsSelectedOutletListItemSelector,
  outletsVisibleOrderFormatSelectionModalSelector,
} from '../store/outletsSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  outletsSetSelectedOutletListItem,
  outletsSetVisibleOrderFormatSelectionModal,
} from '../store/outletsSlice'
import { AppRoutes, OrderFormatSlugMap } from '../../app/constants'
import useRouteMatch from '../../app/hooks/useRouteMatch'

import OutletOrderFormats from './OutletOrderFormats'
import styles from './OrderFormatSelectionModal.module.scss'

export default function OrderFormatSelectionModal() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const outletListItem = useAppSelector(outletsSelectedOutletListItemSelector)
  const isVisible = useAppSelector(
    outletsVisibleOrderFormatSelectionModalSelector,
  )
  const isOutletPage = useRouteMatch([`${AppRoutes.Outlet}/`])
  const orderFormats = outletListItem
    ? getOutletOrderFormats(outletListItem)
    : []

  function handleOnClose() {
    batch(() => {
      dispatch(outletsSetSelectedOutletListItem(null))
      dispatch(outletsSetVisibleOrderFormatSelectionModal(false))
    })
  }

  function handleOnClickOrderFormat(orderFormat: OrderFormat) {
    if (outletListItem) {
      const pathName = outletListItem.urlSlug
        ? `/outlet/${outletListItem.urlSlug}`
        : `/outlets/${outletListItem.id}`

      router.push(
        {
          pathname: pathName,
          query: { orderFormat: OrderFormatSlugMap[orderFormat as never] },
        },
        undefined,
        { shallow: true },
      )
    }
    handleOnClose()
  }

  return (
    <Modal
      title={t('components.orderFormatModal.title')}
      isOpen={isVisible}
      onClose={handleOnClose}
      mobileModalMode="bottom-sheet"
      preventOnClose={isOutletPage}
    >
      <div className={styles.modalBody}>
        <OutletOrderFormats
          orderFormats={orderFormats}
          onChange={handleOnClickOrderFormat}
        />
      </div>
    </Modal>
  )
}
