'use client'

import BackIcon from '@ancon/wildcat-ui/shared/icons/arrow-back-long.svg'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import BodyText from '../../../../app/components/BodyText'
import Button from '../../../../app/components/Button'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { AppRoutes } from '../../../../app/constants'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { isPreOrderCheckoutSelector } from '../../../../preOrder/store/preOrderSelectors'

import styles from './NavbarBackToCompanyDashboard.module.scss'

const BackToDashboardEnabledRoutes = [
  AppRoutes.Outlet,
  AppRoutes.Checkout,
  AppRoutes.OrderStatus,
  AppRoutes.PreOrderStatus,
  AppRoutes.PreOrderManage,
]

export default function NavbarBackToCompanyDashboard() {
  const { t } = useTranslation('common')

  const router = useRouter()

  const isEnabledRoute = useRouteMatch(BackToDashboardEnabledRoutes)

  const isOutletDetailsPage = useRouteMatch([`${AppRoutes.Outlet}/`])

  const isPreOrderCheckout = useAppSelector(isPreOrderCheckoutSelector)

  function handleBackToDashboard() {
    router.replace({ pathname: '/' })
  }

  if (!isEnabledRoute) {
    return null
  }

  return (
    <Button
      variant="secondary"
      className={clsx(styles.button, {
        [styles.outletDetailsPage]: isOutletDetailsPage,
        [styles.hasPreOrderCheckout]: isPreOrderCheckout,
      })}
      onClick={handleBackToDashboard}
    >
      <BackIcon />
      <BodyText as="span">{t('components.navBar.backToDashboard')}</BodyText>
    </Button>
  )
}
