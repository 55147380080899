import { APIGetRequest, GiftCardWebViewResponse } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import { APIType } from '../../types'
import endpoints from '../endpoints'

type GiftCardsAPI = {
  get: {
    giftCardWebView: APIGetRequest<
      GiftCardWebViewResponse,
      { giftCardShareKey: string }
    >
  }
}

const giftCards: GiftCardsAPI = {
  get: {
    giftCardWebView: new Request(endpoints.card.giftCards.webView.list, {
      apiType: APIType.Card,
    }).get,
  },
}

export default giftCards
