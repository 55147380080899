import { useCallback } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppStore from '../../../store/hooks/useAppStore'
import { clientContextCompanyIdByCompanyMemberIdSelector } from '../../clientContext/store/clientContextSelectors'
import { checkoutCurrentCheckoutSelector } from '../store/checkoutSelectors'
import { updateCheckout } from '../store/checkoutThunks'
import {
  isPreOrderCheckoutSelector,
  preOrderUserIsHostSelector,
} from '../../preOrder/store/preOrderSelectors'
import { updatePreOrderCheckout } from '../../preOrder/store/preOrderThunks'

export default function useUpdateCheckoutCompanyId() {
  const store = useAppStore()

  const dispatch = useAppDispatch()

  const handleUpdateCheckoutCompany = useCallback(
    (companyMemberId: string | null) => {
      const currentCheckout = checkoutCurrentCheckoutSelector(store.getState())

      const companyId = companyMemberId
        ? clientContextCompanyIdByCompanyMemberIdSelector(
            store.getState(),
            companyMemberId,
          )
        : null

      if (currentCheckout) {
        const checkoutCompanyId = currentCheckout.company?.id ?? null
        const didCompanyChange = companyId !== checkoutCompanyId

        // Update checkout with new company ID, if it has changed
        if (didCompanyChange) {
          const isPreOrder = isPreOrderCheckoutSelector(store.getState())
          const isPreOrderHost = preOrderUserIsHostSelector(store.getState())

          if (isPreOrder) {
            // Set pre-order checkout company ID, if not already set
            if (!checkoutCompanyId && isPreOrderHost) {
              dispatch(updatePreOrderCheckout({ companyId }))
            }
          } else {
            dispatch(updateCheckout({ companyId }))
          }
        }
      }
    },
    [dispatch, store],
  )

  return handleUpdateCheckoutCompany
}
