import React from 'react'
import { BodyTextProps } from '@ancon/wildcat-ui/web/orderweb/BodyText'
import { OrderFormat } from '@ancon/wildcat-types'

import useGetOrderFormatName from '../hooks/useGetOrderFormatName'

import BodyText from './BodyText'

type OrderFormatTextProps = BodyTextProps & {
  orderFormat: OrderFormat
}

export default function OrderFormatText({
  orderFormat,
  ...bodyTextProps
}: OrderFormatTextProps) {
  const getOrderFormatName = useGetOrderFormatName()

  const orderFormatName = getOrderFormatName(orderFormat)

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BodyText {...bodyTextProps}>{orderFormatName}</BodyText>
  )
}
