import WildcatPhoneNumberInput, {
  PhoneNumberInputProps,
} from '@ancon/wildcat-ui/web/orderweb/PhoneNumberInput'
import '@ancon/wildcat-ui/web/orderweb/PhoneNumberInput/PhoneNumberInput.css'
import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { SupportedLanguage } from '../constants'

function PhoneNumberInput(props: PhoneNumberInputProps) {
  const { lang } = useTranslation()

  const defaultCountryCode = useMemo<'SE' | 'NO'>(() => {
    switch (lang) {
      case SupportedLanguage.Norwegian:
        return 'NO'

      /** Fallback to sweden */
      case SupportedLanguage.English:
      case SupportedLanguage.Swedish:
      default:
        return 'SE'
    }
  }, [lang])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <WildcatPhoneNumberInput {...props} defaultCountry={defaultCountryCode} />
  )
}

export type { PhoneNumberInputProps }

export default PhoneNumberInput
