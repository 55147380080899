import {
  CheckoutDetails,
  CustomerCheckoutListItem,
  CustomerOrder,
} from '@ancon/wildcat-types'
import { createEntityAdapter } from '@reduxjs/toolkit'

import { CheckOrderTicketEntity } from '../types'

const activeOrdersEntityAdapter = createEntityAdapter<CheckoutDetails>()

const upcomingOrdersEntityAdapter =
  createEntityAdapter<CustomerCheckoutListItem>()

const pastOrdersEntityAdapter = createEntityAdapter<CustomerOrder>()

const checkOrderTicketsEntityAdapter =
  createEntityAdapter<CheckOrderTicketEntity>()

export {
  activeOrdersEntityAdapter,
  pastOrdersEntityAdapter,
  upcomingOrdersEntityAdapter,
  checkOrderTicketsEntityAdapter,
}
