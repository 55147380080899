import {
  APIPostRequest,
  CreateLogEntryPayload,
  CreateLogEntryResponse,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'
import { APIType } from '../../types'

type LoggingAPI = {
  post: {
    create: APIPostRequest<CreateLogEntryResponse, CreateLogEntryPayload, {}>
  }
}

const logging: LoggingAPI = {
  post: {
    create: new Request(endpoints.archive.logging.list, {
      apiType: APIType.Archive,
    }).post,
  },
}

export default logging
