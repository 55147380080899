import { APIPutRequest, TicketItemStatus } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type TicketsAPI = {
  put: {
    byId: {
      markAsPickedUp: APIPutRequest<
        void,
        { outletId: string; ticketId: string }
      >
      ticketItemsStatus: APIPutRequest<
        void,
        { ticketItemIds: string[]; ticketItemStatus: TicketItemStatus },
        { outletId: string; ticketId: string }
      >
    }
  }
}

const tickets: TicketsAPI = {
  put: {
    byId: {
      markAsPickedUp: new Request(
        endpoints.core.outlets.byId.tickets.byId.markAsPickedUp.list,
      ).put,
      ticketItemsStatus: new Request(
        endpoints.core.outlets.byId.tickets.byId.ticketItemsStatus.list,
      ).put,
    },
  },
}

export default tickets
