import { APIGetRequest, APIParams, PaginatedResponse, TableDetails, TableSuggestion } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type TablesAPI = {
  get: {
    details: APIGetRequest<TableDetails, APIParams>,
    suggestions: APIGetRequest<
    PaginatedResponse<TableSuggestion>,
    {
      outletId: string
      filter?: {
        number?: string
      }
    }
  >
  }
}

const tables: TablesAPI = {
  get: {
    details: new Request(endpoints.core.outlets.byId.tables.byId.details).get,
    suggestions: new Request(
      endpoints.core.outlets.byId.tables.suggestions.list,
    ).get,
  },
}

export default tables
