import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import clsx from 'clsx'

import { SignUpFormState, SignUpStep } from '../types'
import BodyText from '../../app/components/BodyText'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { clientContextSelector } from '../../clientContext/store/clientContextSelectors'

import styles from './SignUpForm.module.scss'
import RegistrationForm from './RegistrationForm'

type SignUpFormProps = {
  title: string
  isIncompleteCustomerSignUp?: boolean
  onSuccess?: () => void
}

export default function SignUpForm({
  title,
  isIncompleteCustomerSignUp = false,
  onSuccess,
}: SignUpFormProps) {
  const clientContext = useAppSelector(clientContextSelector)
  const form = useForm<SignUpFormState>({
    mode: 'all',
    defaultValues: {
      password: '',
      email:
        clientContext?.customer?.email ||
        clientContext?.identityUser?.email ||
        '',
      firstName:
        clientContext?.customer?.firstName ||
        clientContext?.identityUser?.firstName ||
        '',
      lastName:
        clientContext?.customer?.lastName ||
        clientContext?.identityUser?.lastName ||
        '',
      phoneNumber: clientContext?.customer?.telephoneNumber || '',
      currentStep: isIncompleteCustomerSignUp
        ? SignUpStep.UserDetails
        : SignUpStep.Credentials,
      agreedToTerms: false,
    },
  })

  const { watch } = form
  const currentStep = watch('currentStep')

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <BodyText
        className={clsx(styles.title, {
          [styles.stepTwo]:
            currentStep === SignUpStep.UserDetails &&
            !isIncompleteCustomerSignUp,
        })}
        color="body-1"
        fontSize="1.6rem"
      >
        {title}
      </BodyText>
      <RegistrationForm
        isIncompleteCustomerSignUp={isIncompleteCustomerSignUp}
        onSuccess={onSuccess}
      />
    </FormProvider>
  )
}
