import { useCallback, useEffect, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { ScriptStatus, useScript } from '../../app/hooks/useScript'
import getLanguageCode from '../../app/utils/getLanguageCode'
import { GoogleMapsAPIUrl } from '../../app/constants'

export default function usePlacesAutoComplete() {
  const { lang } = useTranslation()
  const autoCompleteService = useRef<google.maps.places.AutocompleteService>()

  const scriptStatus = useScript(GoogleMapsAPIUrl)

  const fetchPredictions = useCallback(
    async (query: string) => {
      if (autoCompleteService.current) {
        const results = await autoCompleteService.current.getPlacePredictions({
          input: query,
          componentRestrictions: { country: ['se', 'no'] },
          language: getLanguageCode(lang),
        })

        return results.predictions
      }

      return []
    },
    [lang],
  )

  useEffect(() => {
    if (scriptStatus === ScriptStatus.Ready) {
      if (!window.google) {
        throw new Error('Google Maps JavaScript API library must be loaded.')
      }

      if (!window.google.maps.places) {
        throw new Error(
          'Google Maps Places library must be loaded. Please add `libraries=places` to the src URL',
        )
      }

      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService()
    }
  }, [scriptStatus])

  return {
    fetchPredictions,
  }
}
