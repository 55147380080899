import { useCallback } from 'react'
import OneSignal from 'react-onesignal'
import { useRouter } from 'next/router'
import isPreOrderSharable from '@ancon/wildcat-utils/preOrder/isPreOrderSharable'

import {
  createOrUpdateCustomerMessagingProvider,
  createOrderMessagingProvider,
  initializeOneSignal,
} from '../store/notificationThunks'
import {
  setNotificationPermissionGranted,
  setNotificationSubscribed,
} from '../store/notificationSlice'
import {
  isNotificationPermissionGrantedSelector,
  isNotificationSubscribedSelector,
  isOneSignalInitializedSelector,
} from '../store/notificationSelectors'
import useAppStore from '../../../store/hooks/useAppStore'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import getOneSignalAppId from '../../app/utils/getOneSignalAppId'
import {
  MessagingProviderEntityType,
  OneSignalEventName,
  OrderStatusNotificationData,
} from '../types'
import { AppRoutes } from '../../app/constants'

const oneSignalAppId = getOneSignalAppId()

function useOneSignal(
  messagingProviderEntity = MessagingProviderEntityType.Customer,
) {
  const store = useAppStore()

  const dispatch = useAppDispatch()

  const router = useRouter()

  const init = useCallback(
    async (entityId: string) => {
      function handleSubscription(nextSubscribed: boolean) {
        if (
          nextSubscribed !== isNotificationSubscribedSelector(store.getState())
        ) {
          // Keep for logging purposes
          // eslint-disable-next-line no-console
          console.log('OneSignal: Subscription:', nextSubscribed)

          dispatch(setNotificationSubscribed(nextSubscribed))

          OneSignal.getUserId(userId => {
            // Keep for logging purposes
            // eslint-disable-next-line no-console
            console.log('OneSignal: User ID', userId)

            if (userId) {
              switch (messagingProviderEntity) {
                case MessagingProviderEntityType.Order:
                  dispatch(
                    createOrderMessagingProvider({
                      orderId: entityId,
                      oneSignalUserId: userId,
                    }),
                  )
                  break
                default:
                  dispatch(createOrUpdateCustomerMessagingProvider(userId))
              }
            }
          })
        }
      }

      function handlePermission(permission: NotificationPermission) {
        const nextPermission = permission === 'granted'

        if (
          nextPermission !==
          isNotificationPermissionGrantedSelector(store.getState())
        ) {
          // Keep for logging purposes
          // eslint-disable-next-line no-console
          console.log('OneSignal: Push permission:', nextPermission)

          dispatch(setNotificationPermissionGranted(nextPermission))
        }
      }

      function handleNotificationOpened(notification: Notification) {
        // Keep for logging purposes
        // eslint-disable-next-line no-console
        console.log('OneSignal: notification opened:', notification)

        const { outletId, checkoutId, orderId, orderType } =
          (notification.data ?? {}) as OrderStatusNotificationData

        switch (messagingProviderEntity) {
          case MessagingProviderEntityType.Order:
            if (orderId) {
              router.replace({
                pathname: `${AppRoutes.OrderStatus}/qr/[orderId]`,
                query: { orderId },
              })
            }
            break
          default:
            if (outletId && checkoutId) {
              const isPreOrder = isPreOrderSharable({ orderType })
              const orderStatusPath = isPreOrder
                ? AppRoutes.PreOrderStatus
                : AppRoutes.OrderStatus

              router.replace({
                pathname: `${orderStatusPath}/[outletId]/[checkoutId]`,
                query: { outletId, checkoutId },
              })
            }
        }

        // https://documentation.onesignal.com/docs/web-push-sdk#addlistenerfornotificationopened-event
        OneSignal.addListenerForNotificationOpened(handleNotificationOpened)
      }

      function handlePermissionChange(changeEvent: {
        to: NotificationPermission
      }) {
        const permission = changeEvent.to

        // Keep for logging purposes
        // eslint-disable-next-line no-console
        console.log('OneSignal: Push permission changed:', permission)

        handlePermission(permission)
      }

      function handleSubscriptionChange(subscribed: boolean) {
        // Keep for logging purposes
        // eslint-disable-next-line no-console
        console.log('OneSignal: Subscription changed:', subscribed)

        handleSubscription(subscribed)
      }

      if (oneSignalAppId) {
        const isInitialized = isOneSignalInitializedSelector(store.getState())
        if (!isInitialized) {
          await dispatch(
            initializeOneSignal({
              entityId,
              entityType: messagingProviderEntity,
            }),
          )
        }

        OneSignal.getNotificationPermission(handlePermission)
        OneSignal.on(
          OneSignalEventName.NotificationPermissionChange,
          handlePermissionChange,
        )

        OneSignal.getSubscription(handleSubscription)
        OneSignal.on(
          OneSignalEventName.SubscriptionChange,
          handleSubscriptionChange,
        )

        OneSignal.addListenerForNotificationOpened(handleNotificationOpened)
      }

      return () => {
        OneSignal.off(OneSignalEventName.SubscriptionChange, handleSubscription)

        OneSignal.off(
          OneSignalEventName.NotificationPermissionChange,
          handlePermissionChange,
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, router, store],
  )

  const promptIfNeeded = useCallback(async () => {
    if (oneSignalAppId) {
      await OneSignal.showSlidedownPrompt()
    }
  }, [])

  return { init, promptIfNeeded }
}

export default useOneSignal
