import BodyText from '../../app/components/BodyText'

import styles from './AdvancedPricingTag.module.scss'

type AdvancedPricingTagProps = {
  presetName: string
}

export default function AdvancedPricingTag({
  presetName,
}: AdvancedPricingTagProps) {
  return (
    <div className={styles.container}>
      <BodyText as="h3">{presetName}</BodyText>
    </div>
  )
}
