import { useCallback } from 'react'
import { OrderFormat } from '@ancon/wildcat-types'
import useTranslation from 'next-translate/useTranslation'

function useGetOrderFormatName() {
  const { t } = useTranslation('common')

  const getOrderFormatName = useCallback(
    (orderFormat: OrderFormat | undefined | null) => {
      switch (orderFormat) {
        case OrderFormat.EatIn:
          return t('orderFormat.eatIn')
        case OrderFormat.TakeAway:
          return t('orderFormat.takeAway')
        case OrderFormat.TableOrder:
          return t('orderFormat.tableOrder')
        case OrderFormat.Delivery:
          return t('orderFormat.delivery')
        case OrderFormat.BoxPickup:
          return t('orderFormat.boxPickup')
        default:
          return ''
      }
    },
    [t],
  )

  return getOrderFormatName
}

export default useGetOrderFormatName
