import { createContext } from 'react'

export type ToasterContextValue = {
  removeToast: ((id: string) => void) | null
}

const ToasterContext = createContext<ToasterContextValue>({
  removeToast: null,
})

export default ToasterContext
