import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import { getStore } from '../../../store/store'
import { GeoPosition } from '../types'

import {
  locationCurrentGeoPositionSelector,
  locationHasPermissionSelector,
  locationPermissionModalVisibleSelector,
} from './locationSelectors'
import { locationSetPermissionModalVisible } from './locationSlice'
import {
  checkLocationPermission,
  getCurrentGeoPosition,
} from './locationThunks'

export const requestLocationPermission = createAppAsyncThunk<
  {
    permissionGranted: boolean
    currentGeoPosition?: GeoPosition
  },
  void
>('permission/requestLocationPermission', async (_, { dispatch, getState }) => {
  const permissionGranted = await dispatch(checkLocationPermission()).unwrap()

  if (!permissionGranted) {
    dispatch(locationSetPermissionModalVisible(true))

    return new Promise<{
      permissionGranted: boolean
      currentGeoPosition?: GeoPosition
    }>(resolve => {
      const unsubscribe = getStore().subscribe(() => {
        const modalVisible = locationPermissionModalVisibleSelector(getState())
        const hasPermission = locationHasPermissionSelector(getState())
        const currentGeoPosition = locationCurrentGeoPositionSelector(
          getState(),
        )

        if (!modalVisible) {
          resolve({
            permissionGranted: hasPermission && !!currentGeoPosition,
            currentGeoPosition,
          })

          unsubscribe()
        }
      })
    })
  }

  const { permissionGranted: granted, position } = await dispatch(
    getCurrentGeoPosition(),
  ).unwrap()

  return {
    permissionGranted: granted,
    currentGeoPosition: position,
  }
})
