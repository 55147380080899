import { CustomerPreOrderCheckout } from '@ancon/wildcat-types'
import { createEntityAdapter } from '@reduxjs/toolkit'

import customerPreOrderSortComparer from '../utils/customerPreOrderSortComparer'

const customerPreOrdersEntityAdapter =
  createEntityAdapter<CustomerPreOrderCheckout>({
    sortComparer: customerPreOrderSortComparer,
  })

export default customerPreOrdersEntityAdapter
