import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../app/components/BodyText'

import styles from './PreOrderTag.module.scss'

interface PreOrderTagProps {
  className?: string
}

export default function PreOrderTag({ className }: PreOrderTagProps) {
  const { t } = useTranslation('common')

  return (
    <div className={clsx(styles.container, className)}>
      <BodyText as="span" color="body-0">
        {t('preOrder.title')}
      </BodyText>
    </div>
  )
}
