import React from 'react'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import { CheckoutItem } from '@ancon/wildcat-types'

import EmptyPlaceholder from '../../../app/components/emptyPlaceholder/EmptyPlaceholder'

import CartItem from './CartItem'
import styles from './CartItemList.module.scss'

interface CartItemListProps {
  items: CheckoutItem[]
  itemDisabled?: boolean
  preOrderAttendeeId?: string
  canEditItems?: boolean
  className?: string
  emptyListPlaceholder?: React.ReactNode
  itemContainerClassName?: string
  itemQuantityClassName?: string
  showItemSeparator?: boolean
  omitTicketItemStatus?: boolean
}

export default function CartItemList({
  items,
  itemDisabled = false,
  preOrderAttendeeId,
  canEditItems = false,
  className,
  emptyListPlaceholder,
  itemContainerClassName,
  itemQuantityClassName,
  showItemSeparator = true,
  omitTicketItemStatus = false,
}: CartItemListProps) {
  const { t } = useTranslation('common')

  return (
    <div className={clsx(styles.container, className)}>
      {items.length > 0
        ? items.map((item, index) => (
            <CartItem
              className={itemContainerClassName}
              itemQuantityClassName={itemQuantityClassName}
              cartItem={item}
              key={item.id}
              showSeparator={showItemSeparator && index !== items.length - 1}
              disableClick={!canEditItems}
              disabled={itemDisabled}
              omitQuantityModifier={!canEditItems}
              omitTicketItemStatus={omitTicketItemStatus}
              preOrderAttendeeId={preOrderAttendeeId}
            />
          ))
        : emptyListPlaceholder ?? (
            <EmptyPlaceholder
              className={styles.emptyList}
              title={t('components.order.emptyCart.title')}
              message={t('components.order.emptyCart.message')}
            />
          )}
    </div>
  )
}
