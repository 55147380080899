import { createSlice } from '@reduxjs/toolkit'

import { LocationReducerState } from '../types'

import {
  checkLocationPermission,
  getCurrentGeoPosition,
} from './locationThunks'
import locationReducers from './locationReducers'

const locationInitialState: LocationReducerState = {
  locationPermissionModalVisible: false,
  locationPermissionGranted: false,

  locationPending: false,
  checkLocationPending: false,
}

const locationSlice = createSlice({
  name: 'location',
  initialState: locationInitialState,
  reducers: locationReducers,
  extraReducers: builder => {
    builder.addCase(checkLocationPermission.pending, state => {
      state.locationPending = true
      state.checkLocationPending = true
    })
    builder.addCase(checkLocationPermission.fulfilled, (state, action) => {
      state.locationPending = false
      state.checkLocationPending = false
      state.locationPermissionGranted = action.payload
    })
    builder.addCase(checkLocationPermission.rejected, state => {
      state.locationPending = false
      state.checkLocationPending = false
    })

    builder.addCase(getCurrentGeoPosition.pending, state => {
      state.locationPending = true
    })
    builder.addCase(getCurrentGeoPosition.fulfilled, (state, action) => {
      const { position, permissionGranted } = action.payload
      state.locationPending = false
      state.currentGeoPosition = position
      state.locationPermissionGranted = permissionGranted
    })
    builder.addCase(getCurrentGeoPosition.rejected, state => {
      state.currentGeoPosition = undefined
      state.locationPending = false
    })
  },
})

export const {
  locationSetPermissionModalVisible,
  locationSetCurrentGeoPositionName,
} = locationSlice.actions

export default locationSlice.reducer
