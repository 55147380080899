import { PropsWithChildren } from 'react'
import clsx from 'clsx'

import BodyText from '../../app/components/BodyText'

import styles from './PreOrderInfoField.module.scss'

type PreOrderInfoFieldProps = PropsWithChildren<{
  name?: string
  inline?: boolean
}>

export default function PreOrderInfoField({
  name,
  inline = false,
  children,
}: PreOrderInfoFieldProps) {
  return (
    <div className={clsx(styles.field, { [styles.inline]: inline })}>
      {name && (
        <BodyText
          as="span"
          color="body-0"
          fontSize={inline ? '1.4rem' : '1.6rem'}
        >
          {name}
        </BodyText>
      )}
      {typeof children === 'string' ? (
        <BodyText color="body-4">{children}</BodyText>
      ) : (
        children
      )}
    </div>
  )
}
