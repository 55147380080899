import { RootState } from '../../../store/types'
import { AppReducerState } from '../types'

function appSelector<K extends keyof AppReducerState>(
  state: RootState,
  key: K,
) {
  return state.app[key]
}

// Simple state selector
export const appIsErrorModalVisibleSelector = (state: RootState) =>
  appSelector(state, 'isErrorModalVisible')

export const appErrorModalConfigSelector = (state: RootState) =>
  appSelector(state, 'errorModalConfig')

export const appIsCartDrawerVisibleSelector = (state: RootState) =>
  appSelector(state, 'isCartDrawerVisible')

export const appIsMobileDrawerVisibleSelector = (state: RootState) =>
  appSelector(state, 'isMobileDrawerVisible')

export const appIsSwitchLanguageModalVisibleSelector = (state: RootState) =>
  appSelector(state, 'isSwitchLanguageModalVisible')

export const appOrderDrawerOrderIdSelector = (state: RootState) =>
  appSelector(state, 'orderDrawerOrderId')

export const appLanguageSelector = (state: RootState) =>
  appSelector(state, 'language')

export const appIsSignalROnlineSelector = (state: RootState) =>
  appSelector(state, 'isSignalROnline')
