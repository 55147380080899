import { useCallback } from 'react'
import { useRouter } from 'next/router'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { signOut } from '../store/authThunks'
import useAppStore from '../../../store/hooks/useAppStore'
import { authCustomerIsLoggedInSelector } from '../store/authSelectors'
import { preOrderUserSelector } from '../../preOrder/store/preOrderSelectors'
import { resetPreOrderState } from '../../preOrder/store/preOrderSlice'
import storedPreOrderUserToken from '../../preOrder/utils/storedPreOrderUserToken'
import localStorageUtils from '../../app/utils/localStorageUtils'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../app/constants'
import useUpdateCheckoutCompanyId from '../../checkout/hooks/useUpdateCheckoutCompanyId'
import { clientContextIsCompanyUserSelector } from '../../clientContext/store/clientContextSelectors'

type UseAppSignOutReturn = () => Promise<boolean>

export default function useAppSignOut(): UseAppSignOutReturn {
  const store = useAppStore()

  const router = useRouter()

  const dispatch = useAppDispatch()

  const updateCheckoutCompanyIfNeeded = useUpdateCheckoutCompanyId()

  const isPreOrderRoute = useRouteMatch([
    AppRoutes.Checkout,
    AppRoutes.PreOrder,
  ])

  const handleSignOut = useCallback(async () => {
    const isLoggedIn = authCustomerIsLoggedInSelector(store.getState())
    const preOrderUser = preOrderUserSelector(store.getState())
    const isCompanyUser = clientContextIsCompanyUserSelector(store.getState())

    try {
      if (isLoggedIn) {
        await dispatch(signOut()).unwrap()
      }

      if (isCompanyUser) {
        // Remove user company ID from current checkout
        updateCheckoutCompanyIfNeeded(null)
      }

      if (preOrderUser) {
        // Reset pre-order state
        dispatch(resetPreOrderState())

        // Remove stored pre-order token
        storedPreOrderUserToken.remove()

        // Remove cart meta from local storage
        localStorageUtils.removeItem('cartMeta')

        // Redirect to home if user is on Pre Order page
        if (isPreOrderRoute) {
          router.replace(AppRoutes.Home)
        }
      }
      return true
    } catch (error) {
      // Resolve with false if sign out fails
      return false
    }
  }, [dispatch, isPreOrderRoute, router, store, updateCheckoutCompanyIfNeeded])

  return handleSignOut
}
