import core from './endpoints/core'
import user from './endpoints/user'
import card from './endpoints/card'
import archive from './endpoints/archive'

const api = {
  core,
  user,
  card,
  archive,
}

export default api
