import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ClientContextResponseV2 } from '@ancon/wildcat-types'

import { signOut } from '../../auth/store/authThunks'
import { ClientContextState, ExtendedCustomerUserDetails } from '../types'
import extractCompanyUsers from '../utils/extractCompanyUsers'
import localStorageUtils from '../../app/utils/localStorageUtils'
import { fetchCustomerAddressesList } from '../../account/store/accountThunks'

import { fetchClientContext, fetchCustomerDetails } from './clientContextThunks'
import clientContextCompanyUsersEntityAdapter from './clientContextCompanyUsersEntityAdapter'
import clientContextReducers from './clientContextReducers'

const clientContextInitialState: ClientContextState = {
  clientContextFetchPending: false,
  clientContextFetchError: null,
  clientContext: null,

  customerFetchPending: false,
  customerFetchError: null,
  customer: null,

  companyUsers: clientContextCompanyUsersEntityAdapter.getInitialState(),
  currentCompanyUserId: null,
}

const clientContextSlice = createSlice({
  name: 'clientContext',
  initialState: clientContextInitialState,
  reducers: clientContextReducers,
  extraReducers: builder => {
    // Fetch clientContext
    builder.addCase(fetchClientContext.pending, state => {
      state.clientContextFetchPending = true
      state.clientContextFetchError = null
    })
    builder.addCase(
      fetchClientContext.fulfilled,
      (state, action: PayloadAction<ClientContextResponseV2 | null>) => {
        const clientContextResponse = action.payload
        const { customer, companyUsers } = clientContextResponse || {}

        state.clientContext = clientContextResponse
        state.clientContextFetchPending = false

        // Extract company users
        if (companyUsers?.length) {
          clientContextCompanyUsersEntityAdapter.setAll(
            state.companyUsers,
            extractCompanyUsers(action.payload),
          )
        }

        if (customer?.customerUserId) {
          const storedCompanyUserId =
            localStorageUtils.getItem<string>('companyProfileId')

          if (storedCompanyUserId) {
            const companyUserIds = (companyUsers || []).map(user => user.id)

            // Check if stored company user ID is still valid
            if (companyUserIds.includes(storedCompanyUserId)) {
              state.currentCompanyUserId = storedCompanyUserId
            } else {
              // Remove invalid company user ID from local storage
              localStorageUtils.removeItem('companyProfileId')
            }
          }
        }
      },
    )
    builder.addCase(fetchClientContext.rejected, (state, action) => {
      state.clientContextFetchPending = false
      state.clientContextFetchError = action.error.message || null
    })

    // Fetch userDetails
    builder.addCase(fetchCustomerDetails.pending, state => {
      state.customerFetchPending = true
      state.customerFetchError = null
    })
    builder.addCase(
      fetchCustomerDetails.fulfilled,
      (state, action: PayloadAction<ExtendedCustomerUserDetails>) => {
        state.customer = action.payload
        state.customerFetchPending = false
      },
    )
    builder.addCase(fetchCustomerDetails.rejected, (state, action) => {
      state.customerFetchPending = false
      state.customerFetchError = action.error.message || null
    })

    // Sign Out
    builder.addCase(signOut.fulfilled, () => clientContextInitialState)

    builder.addMatcher(
      isAnyOf(fetchCustomerAddressesList.fulfilled),
      (state, action) => {
        if (state.customer) {
          state.customer = {
            ...state.customer,
            addresses: action.payload.items,
          }
        }
      },
    )
  },
})

export const { clientContextSetCurrentCompanyUserId } =
  clientContextSlice.actions

export default clientContextSlice.reducer
