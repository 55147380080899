import { APIPutRequest } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type IdentityUsersAPI = {
  put: {
    verifyEmail: APIPutRequest<{}, { verificationToken: string }>
  }
}

const identityUsers: IdentityUsersAPI = {
  put: {
    verifyEmail: new Request(endpoints.core.identityUsers.verifyEmail.list).put,
  },
}

export default identityUsers
