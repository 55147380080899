import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import { useRouter } from 'next/router'

import QuantityModifier from '../../../app/components/QuantityModifier'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  changeCheckoutItemQuantity,
  deleteCheckoutItem,
} from '../../store/checkoutThunks'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'
import { AppRoutes } from '../../../app/constants'
import useRouteMatch from '../../../app/hooks/useRouteMatch'
import { isPreOrderCheckoutSelector } from '../../../preOrder/store/preOrderSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'
import {
  changePreOrderCheckoutItemQty,
  deletePreOrderCheckoutItem,
} from '../../../preOrder/store/preOrderThunks'

import styles from './CartItemQuantityWidget.module.scss'

type CartItemQuantityWidgetProps = {
  itemId: string
  preOrderAttendeeId?: string
  initialValue: number
  onChangeQuantity: (quantity: number) => void
  onClose: () => void
  disabled?: boolean
}

function CartItemQuantityWidget({
  itemId,
  preOrderAttendeeId,
  initialValue,
  onChangeQuantity,
  onClose,
  disabled = false,
}: CartItemQuantityWidgetProps) {
  const router = useRouter()

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const quantityWidgetRef = useRef<HTMLDivElement>(null)

  const isCheckoutPage = useRouteMatch([AppRoutes.Checkout])

  async function generalCheckoutChangeItemQty(newQuantity: number) {
    try {
      if (newQuantity === 0) {
        const response = await dispatch(
          deleteCheckoutItem({ checkoutItemId: itemId }),
        ).unwrap()

        if (response?.isCheckoutDeleted && isCheckoutPage) {
          router.replace('/')
        }
      } else {
        await dispatch(
          changeCheckoutItemQuantity({
            checkoutItemId: itemId,
            quantity: newQuantity,
          }),
        ).unwrap()
      }
    } catch (error) {
      onChangeQuantity(initialValue)
    }
  }

  async function preOrderChangeItemQty(newQuantity: number) {
    try {
      if (newQuantity === 0) {
        await dispatch(
          deletePreOrderCheckoutItem({
            checkoutItemId: itemId,
            attendeeId: preOrderAttendeeId,
          }),
        ).unwrap()
      } else {
        await dispatch(
          changePreOrderCheckoutItemQty({
            checkoutItemId: itemId,
            quantity: newQuantity,
            attendeeId: preOrderAttendeeId,
          }),
        ).unwrap()
      }
    } catch (error) {
      onChangeQuantity(initialValue)
    }
  }

  const debouncedOnChangeQuantity = useDebouncedCallback(
    (newQuantity: number) => {
      const isPreOrderCheckout = isPreOrderCheckoutSelector(store.getState())
      if (isPreOrderCheckout) {
        preOrderChangeItemQty(newQuantity)
      } else {
        generalCheckoutChangeItemQty(newQuantity)
      }
    },
    400,
  )

  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      const clickedOnWidget =
        quantityWidgetRef.current &&
        quantityWidgetRef.current.contains(e.target as Node)

      if (!clickedOnWidget) {
        onClose()
      }
    }

    document.addEventListener('mouseup', handleMouseDown)

    return () => {
      document.removeEventListener('mouseup', handleMouseDown)
    }
  }, [onClose])

  useEffect(
    () => () => debouncedOnChangeQuantity.flush(),
    [debouncedOnChangeQuantity],
  )

  return (
    <InteractiveDiv
      className={clsx(styles.quantityModifier)}
      ref={quantityWidgetRef}
    >
      <QuantityModifier
        onChange={qty => {
          onChangeQuantity(qty)
          debouncedOnChangeQuantity(qty)
        }}
        withRemoveOption
        value={initialValue}
        disabled={disabled}
        min={0}
      />
    </InteractiveDiv>
  )
}

export default CartItemQuantityWidget
