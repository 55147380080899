import getOutletIds from './getOutletIds'
import getTenantIds from './getTenantIds'

const DefaultTenantFilterProperty = 'tenantId'
const DefaultOutletFilterProperty = 'outletId'

type GetTenantOutletAPIFiltersArgs = {
  tenantFilterProp: string
  outletFilterProp: string
}

export default function getTenantOutletAPIFilters(
  args?: Partial<GetTenantOutletAPIFiltersArgs>,
) {
  const tenantFilterProp = args?.tenantFilterProp || DefaultTenantFilterProperty
  const outletFilterProp = args?.outletFilterProp || DefaultOutletFilterProperty

  const tenantIds = getTenantIds()
  const outletIds = getOutletIds()

  const hasTenantIds = !!tenantIds?.length
  const hasOutletIds = !!outletIds?.length

  const filters: Record<string, any> = {}

  if (hasTenantIds) {
    filters[tenantFilterProp] = tenantIds.map(id => `in:${id}`)
  }

  if (hasOutletIds) {
    filters[outletFilterProp] = outletIds.map(id => `in:${id}`)
  }

  return filters
}
