import { EntityState } from '@reduxjs/toolkit'
import { OutletListItem } from '@ancon/wildcat-types'

import { GeoPosition } from '../location/types'

type OutletsFiltersLocation = GeoPosition & {
  visibleSearchTerm?: string
}

export type OutletsFilters = {
  location: OutletsFiltersLocation | null
  searchTerm: string | null
}

export enum OutletSearchModalType {
  None,
  All,
  OutletSearch,
  PlaceSearch,
}

export enum RestaurantSelectionModalType {
  None,
  RestaurantSelection,
  PlaceNewOrder,
}

export type OutletsReducerState = {
  list: EntityState<OutletListItem>
  offset: number
  hasMore: boolean
  outletsFilters: OutletsFilters
  outletListFetchPending: boolean
  visibleOutletSearchModal: OutletSearchModalType
  visibleOrderFormatSelectionModal: boolean
  selectedOutletListItem: OutletListItem | null

  // WL company user restaurant selection
  selectedRestaurantId: string | null
  restaurantSelectionModalVisibleType: RestaurantSelectionModalType
}

export enum OutletSearchActionType {
  SetSearchTerm,
  FetchOutletSuggestionsFailure,
  FetchPlaceSuggestionsFailure,
  FetchOutletSuggestionsSuccess,
  FetchPlaceSuggestionsSuccess,
}

export type Coordinates = {
  latitude: number
  longitude: number
}

export type GoogleAddressComponentType =
  | 'street_number'
  | 'route'
  | 'point_of_interest'
  | 'administrative_area_level_1'
  | 'country'
  | 'postal_code'
  | 'postal_town'
  | 'locality'
  | 'administrative_area_level_2'

export type AddressComponentState = {
  city: string
  streetName: string
  streetNumber: string
  state: string
  zipCode: string
  country: string
}

export type OutletListingSection = {
  title: string
  data: OutletListItem[]
}

export interface OutletsListPageQuery {
  pl?: string // Google Place ID
  q?: string // Search query
}
