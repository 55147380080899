import {
  APIGetRequest,
  APIPostRequest,
  APIPutRequest,
  CustomerActivatePayload,
  CustomerRegistrationPayload,
  CustomerRegistrationResponse,
  CustomerUpdatePayload,
  CustomerUserDetails,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import { APIType } from '../../types'
import endpoints from '../endpoints'

type CustomerDetailsResponse = {
  customer: CustomerUserDetails
}

type CustomerId = {
  customerId: string
}

type CustomersAPI = {
  get: {
    details: APIGetRequest<CustomerDetailsResponse, CustomerId>
  }
  post: {
    create: APIPostRequest<
      CustomerRegistrationResponse,
      CustomerRegistrationPayload
    >
    anonymize: APIPostRequest<void, {}>
  }
  put: {
    byId: APIPutRequest<void, CustomerUpdatePayload, CustomerId>
    activate: APIPutRequest<void, CustomerActivatePayload, CustomerId>
  }
}

const customers: CustomersAPI = {
  get: {
    details: new Request(endpoints.user.customers.details, {
      apiType: APIType.User,
    }).get,
  },
  post: {
    create: new Request(endpoints.user.customers.register.list, {
      apiType: APIType.User,
    }).post,
    anonymize: new Request(endpoints.user.customers.byId.anonymize.list, {
      apiType: APIType.User,
    }).post,
  },
  put: {
    byId: new Request(endpoints.user.customers.details, {
      apiType: APIType.User,
    }).put,
    activate: new Request(endpoints.user.customers.byId.activate.list, {
      apiType: APIType.User,
    }).put,
  },
}

export default customers
