import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import Modal from '../../app/components/modal/Modal'
import Button from '../../app/components/Button'
import BodyText from '../../app/components/BodyText'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  checkoutDeletePendingSelector,
  checkoutIsVisibleDeleteOrderModalSelector,
} from '../store/checkoutSelectors'
import { deleteCheckout } from '../store/checkoutThunks'
import { checkoutSetIsVisibleDeleteOrderModal } from '../store/checkoutSlice'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { AppRoutes, AppTestIds } from '../../app/constants'

import styles from './DeleteOrderModal.module.scss'

export default function DeleteOrderModal() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isCheckoutRoute = useRouteMatch([AppRoutes.Checkout])

  const checkoutDeletePending = useAppSelector(checkoutDeletePendingSelector)
  const isVisibleDeleteOrderModal = useAppSelector(
    checkoutIsVisibleDeleteOrderModalSelector,
  )

  function handleOnClose() {
    dispatch(checkoutSetIsVisibleDeleteOrderModal(false))
  }

  function handleOnClickDeleteOrder() {
    try {
      dispatch(deleteCheckout())
        .unwrap()
        .then(() => {
          handleOnClose()

          if (isCheckoutRoute) {
            router.replace({
              pathname: '/',
            })
          }
        })
    } catch (error) {
      // TODO: handle error
    }
  }

  return (
    <Modal
      title={t('deleteOrderModal.title')}
      isOpen={isVisibleDeleteOrderModal}
      onClose={handleOnClose}
      className={styles.modal}
    >
      <div className={styles.modalBody}>
        <BodyText color="body-1">{t('deleteOrderModal.message')}</BodyText>
      </div>
      <div className={styles.modalFooter}>
        <Button variant="secondary" onClick={handleOnClose}>
          {t('deleteOrderModal.no')}
        </Button>
        <Button
          onClick={handleOnClickDeleteOrder}
          loading={checkoutDeletePending}
          data-testid={AppTestIds.deleteOrderModal.DeleteOrderButton}
        >
          {t('deleteOrderModal.yes')}
        </Button>
      </div>
    </Modal>
  )
}
