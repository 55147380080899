import React, { useEffect, useState } from 'react'

import { checkoutStockErrorAdditionalDataSelector } from '../../store/checkoutSelectors'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import ModalCard from '../../../app/components/modal/ModalCard'
import CheckoutOutOfStockForm from '../../../inventory/components/checkoutOutOfStock/CheckoutOutOfStockForm'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'

import styles from './CartOutOfStockPopup.module.scss'

interface CartOutOfStockPopupProps {
  onContinue?: () => void
}

export default function CartOutOfStockPopup({
  onContinue,
}: CartOutOfStockPopupProps) {
  const checkoutStockErrorAdditionalData = useAppSelector(
    checkoutStockErrorAdditionalDataSelector,
  )
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false)

  function handleClose() {
    setPopupOpen(false)
  }

  function handleContinue({
    isCheckoutDeleted,
  }: {
    isCheckoutDeleted?: boolean
  }) {
    setPopupOpen(false)
    if (!isCheckoutDeleted) {
      onContinue?.()
    }
  }

  useEffect(() => {
    if (checkoutStockErrorAdditionalData) {
      setPopupOpen(true)
    }
  }, [checkoutStockErrorAdditionalData])

  return isPopupOpen && checkoutStockErrorAdditionalData ? (
    <div>
      <InteractiveDiv className={styles.backdrop} onClick={handleClose} />
      <ModalCard className={styles.modalCard}>
        <CheckoutOutOfStockForm
          onCloseClick={handleClose}
          onContinue={handleContinue}
        />
      </ModalCard>
    </div>
  ) : null
}
