'use client'

import React from 'react'
import ErrorIcon from '@ancon/wildcat-ui/shared/icons/warning-outline.svg'
import { useRouter } from 'next/router'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  appErrorModalConfigSelector,
  appIsErrorModalVisibleSelector,
} from '../../store/appSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { appSetIsErrorModalVisible } from '../../store/appSlice'
import Modal from '../modal/Modal'
import BodyText from '../BodyText'
import { ErrorModalType } from '../../types'
import { setProductDetailsModalVisible } from '../../../product/store/productSlice'
import HeadingText from '../HeadingText'
import { checkoutRetryAdyenPayment } from '../../../checkout/store/checkoutSlice'
import storedPaymentCorrelationId from '../../utils/storedPaymentCorrelationId'
import { checkRetryAdyenPayment } from '../../../check/store/checkSlice'

import ErrorModalFooter from './ErrorModalFooter'
import ErrorModalAdditionalInfo from './ErrorModalAdditionalInfo'
import styles from './ErrorModal.module.scss'

type ErrorModalProps = {
  redirectOnClose?: boolean
}

export default function ErrorModal({
  redirectOnClose = true,
}: ErrorModalProps) {
  const dispatch = useAppDispatch()
  const router = useRouter()

  const errorConfig = useAppSelector(appErrorModalConfigSelector)
  const visible = useAppSelector(appIsErrorModalVisibleSelector)

  function hideCloseButton() {
    switch (errorConfig?.errorModalType) {
      default:
        return true
    }
  }

  function handleRequestClose() {
    switch (errorConfig?.errorModalType) {
      case ErrorModalType.GeneralCheckoutError:
      case ErrorModalType.CheckoutItemPriceChanged:
      case ErrorModalType.UpdateCheckoutItemQuantityOutOfStock:
      case ErrorModalType.CreateCheckoutProductOutOfStock:
      case ErrorModalType.UpdateCheckoutProductOutOfStock:
      case ErrorModalType.OutletNotAvailableForOrders:
      case ErrorModalType.CheckoutBoxPickupUnAvailableProducts:
      case ErrorModalType.CheckoutUnavailableOrderTime:
        // TODO: handle close request for checkout out of stock errors with out of stock modal
        break
      // add cases here to handle close requests for specific error modals
      case ErrorModalType.ProductOutOfStock:
        dispatch(setProductDetailsModalVisible(false))
        break
      case ErrorModalType.CheckoutPaymentError:
        storedPaymentCorrelationId.refresh()
        dispatch(checkoutRetryAdyenPayment())
        break
      case ErrorModalType.CheckPaymentError:
        storedPaymentCorrelationId.refresh()
        dispatch(checkRetryAdyenPayment())
        break
      default: {
        if (redirectOnClose) {
          router.push('/')
        }
      }
    }

    dispatch(appSetIsErrorModalVisible(false))
  }

  return (
    <Modal
      isOpen={visible}
      onClose={handleRequestClose}
      hideClose={hideCloseButton()}
    >
      <div className={styles.container}>
        <ErrorIcon />
        <HeadingText as="h3" className={styles.title}>
          {errorConfig?.title}
        </HeadingText>
        <BodyText color="body-1" className={styles.message}>
          {errorConfig?.message}
        </BodyText>
        <ErrorModalAdditionalInfo />
        <div className={styles.footer}>
          <ErrorModalFooter />
        </div>
      </div>
    </Modal>
  )
}
