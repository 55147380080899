'use client'

import { PropsWithChildren, useEffect } from 'react'
import { useRouter } from 'next/router'

import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  authCustomerIsLoggedInSelector,
  authInitializedSelector,
} from '../store/authSelectors'
import Spinner from '../../app/components/Spinner'
import {
  clientContextAuthenticationUserSignInProviderSelector,
  clientContextFetchPendingSelector,
} from '../../clientContext/store/clientContextSelectors'

import styles from './ClientAuthContainer.module.scss'

type ClientAuthContainerProps = PropsWithChildren<{
  skeleton?: React.ReactNode
  fallbackSpinning?: boolean
  authorizationRule?: AuthorizationRule
}>

export enum AuthorizationRule {
  /* General user access */
  General,
  /*
   * Only for account holders
   * Should have email address associated with the account
   */
  AccountHoldersOnly,
  /*
   * Only customers
   * Should have custom account attributes
   */
  CustomersOnly,
}

export default function ClientAuthContainer({
  skeleton,
  children,
  fallbackSpinning = true,
  authorizationRule = AuthorizationRule.General,
}: ClientAuthContainerProps) {
  const router = useRouter()
  const authInitialized = useAppSelector(authInitializedSelector)
  const clientContextPending = useAppSelector(clientContextFetchPendingSelector)
  const isLogged = useAppSelector(authCustomerIsLoggedInSelector)
  const signInProvider = useAppSelector(
    clientContextAuthenticationUserSignInProviderSelector,
  )

  const isAccountHoldersOnly =
    authorizationRule === AuthorizationRule.AccountHoldersOnly
  const isCustomersOnly = authorizationRule === AuthorizationRule.CustomersOnly
  const isGeneralAccess = authorizationRule === AuthorizationRule.General

  useEffect(() => {
    const isUnknownAuthorization =
      !signInProvider || (!!signInProvider && signInProvider === 'anonymous')

    // Redirecting unauthenticated users to home page
    if (
      authInitialized && // Client auth SDK is initialized
      isAccountHoldersOnly &&
      isLogged != null && // Logged in status is known
      !clientContextPending && // Client fetch context is completed
      isUnknownAuthorization // Not an anonymous or guest customer
    ) {
      router.replace('/')
    }

    // Redirecting unauthenticated users to home page
    if (isCustomersOnly && isLogged === false) {
      router.replace('/')
    }
  }, [
    authInitialized,
    isCustomersOnly,
    isAccountHoldersOnly,
    clientContextPending,
    signInProvider,
    isLogged,
    router,
  ])

  if (authInitialized) {
    const isAccountHolder =
      isAccountHoldersOnly &&
      isLogged &&
      !clientContextPending &&
      signInProvider !== 'anonymous'

    if (isAccountHoldersOnly && isAccountHolder) {
      return children
    }
    if (isCustomersOnly && isLogged) {
      return children
    }

    if (isGeneralAccess) {
      return children
    }
  }

  const spinner = (
    <div className={styles.container}>
      <Spinner size="large" />
    </div>
  )

  return skeleton || (fallbackSpinning && spinner)
}
