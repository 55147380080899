import clsx from 'clsx'

import { checkoutCurrentCheckoutDetailsSelector } from '../store/checkoutSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'
import useCartDrawerOpen from '../hooks/useCartDrawerOpen'
import PreOrderShoppingCart from '../../preOrder/components/PreOrderShoppingCart'
import { preOrderCheckoutSelector } from '../../preOrder/store/preOrderSelectors'

import ShoppingCart from './shoppingCart/ShoppingCart'
import styles from './CartDrawer.module.scss'

export default function CartDrawer() {
  const isCartDrawerVisible = useCartDrawerOpen()

  const checkout = useAppSelector(checkoutCurrentCheckoutDetailsSelector)
  const preOrderCheckout = useAppSelector(preOrderCheckoutSelector)

  // If there is no any checkout, then don't render anything
  if (!checkout && !preOrderCheckout) return null

  const isPreOrderCheckout = !!preOrderCheckout

  return (
    <div
      className={clsx(styles.container, {
        [styles.isOpen]: isCartDrawerVisible,
      })}
    >
      {isPreOrderCheckout ? <PreOrderShoppingCart /> : <ShoppingCart />}
    </div>
  )
}
