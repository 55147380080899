import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'
import useToggleState from '../../hooks/useToggleState'

import TermsOfPurchaseModal from './TermsOfPurchaseModal'
import styles from './TermsOfPurchase.module.scss'

interface TermsOfPurchaseProps {
  className?: string
}

export default function TermsOfPurchase({ className }: TermsOfPurchaseProps) {
  const { t } = useTranslation('common')

  const [isTermsModalOpen, toggleTermsModal] = useToggleState(false)

  function handleShowTerms() {
    toggleTermsModal()
  }

  return (
    <>
      <div
        className={clsx(styles.button, className)}
        role="presentation"
        aria-label="terms of purchase"
        onClick={handleShowTerms}
      >
        <BodyText as="p">{t('termsOfPurchase.title')}</BodyText>
      </div>
      <TermsOfPurchaseModal
        isOpen={isTermsModalOpen}
        onClose={toggleTermsModal}
      />
    </>
  )
}
