import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import type { ThunkApiConfig } from './types'

function createAppAsyncThunk<
  Returned,
  ThunkArg = void,
  T extends ThunkApiConfig = ThunkApiConfig,
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, T>,
  options?: AsyncThunkOptions<ThunkArg, T>,
): AsyncThunk<Returned, ThunkArg, T> {
  return createAsyncThunk<Returned, ThunkArg, T>(
    typePrefix,
    payloadCreator,
    options,
  )
}

export default createAppAsyncThunk
