import { ErrorCode, OrderFormat } from '@ancon/wildcat-types'
import getTranslation from 'next-translate/getT'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { AxiosError } from 'axios'

import {
  AppGenericErrorType,
  ErrorModalType,
  InformativeError,
  APIErrorResponse,
} from '../../app/types'
import { CheckoutErrorContextType } from '../types'
import { ProductPriceChangeAdditionalData } from '../../../api/endpoints/core/checkouts'
import getTranslatedError from '../../app/utils/getTranslatedError'
import { orderFormatTextMap } from '../../outlets/constants'

export default async function getCheckoutErrorModalConfig(
  err: AppGenericErrorType,
  fallback: InformativeError,
  errorContext?: CheckoutErrorContextType,
  excludedErrorCodes: ErrorCode[] = [],
  locale?: string,
  params?: Record<string, string | number>,
) {
  const t = await getTranslation(locale, 'common')
  const apiErrorResponse = err as AxiosError<APIErrorResponse>
  const errorCode = apiErrorResponse?.response?.data?.errorCode

  if (errorCode && excludedErrorCodes.includes(errorCode)) {
    return undefined
  }

  switch (errorCode) {
    case ErrorCode.RequestTimeInvalid:
      return {
        title: t('errors.checkoutRequestTimeInvalid.title'),
        message: t('errors.checkoutRequestTimeInvalid.message'),
      }
    case ErrorCode.RequestItemsNotDefined:
      return {
        title: t('errors.checkoutRequestItemsNotDefined.title'),
        message: t('errors.checkoutRequestItemsNotDefined.message'),
      }
    case ErrorCode.RequestItemsNotValid:
      return {
        title: t('errors.checkoutRequestItemsNotValid.title'),
        message: t('errors.checkoutRequestItemsNotValid.message'),
      }
    case ErrorCode.RequestedOrderFormatNotSupportedByOutlet:
      return {
        title: t(
          'errors.checkoutRequestedOrderFormatNotSupportedByOutlet.title',
        ),
        message: t(
          'errors.checkoutRequestedOrderFormatNotSupportedByOutlet.message',
        ),
      }
    case ErrorCode.RequestedOutletIsNotOpen: {
      if (params?.orderFormat) {
        const { orderFormat } = params

        return {
          title: t('errors.outletIsNotOpenForOrderFormat.title', {
            orderFormat: t(orderFormatTextMap[orderFormat as never]),
          }),
          message: t('errors.outletIsNotOpenForOrderFormat.message', {
            orderFormat: t(orderFormatTextMap[orderFormat as never]),
          }),
          errorModalType: ErrorModalType.OutletNotAvailableForOrders,
        }
      }

      return {
        title: t('errors.requestedOutletIsNotOpen.title'),
        message: t('errors.requestedOutletIsNotOpen.message'),
        errorModalType: ErrorModalType.OutletNotAvailableForOrders,
      }
    }

    case ErrorCode.ItemsNotValidBlocked:
      return {
        title: t('errors.checkoutItemsNotValidBlocked.title'),
        message: t('errors.checkoutItemsNotValidBlocked.message'),
      }
    case ErrorCode.ItemsNotValidNotAvailable:
      return {
        title: t('errors.checkoutItemsNotValidNotAvailable.title'),
        message: t('errors.checkoutItemsNotValidNotAvailable.message'),
      }
    case ErrorCode.ItemsNotValidNotExists:
      return {
        title: t('errors.checkoutItemsItemsNotValidNotExists.title'),
        message: t('errors.checkoutItemsItemsNotValidNotExists.message'),
      }
    case ErrorCode.ItemsNotValidNotAllowedQuantity:
      return {
        title: t('errors.checkoutItemsNotValidNotAllowedQuantity.title'),
        message: t('errors.checkoutItemsNotValidNotAllowedQuantity.message'),
      }
    case ErrorCode.RequestDataNotValid:
      if (params?.orderFormat === OrderFormat.BoxPickup) {
        return {
          title: t('errors.checkoutBoxPickupProductsUnavailable.title'),
          message: t('errors.checkoutBoxPickupProductsUnavailable.message'),
        }
      }
      return {
        title: t('errors.checkoutRequestDataNotValid.title'),
        message: t('errors.checkoutRequestDataNotValid.message'),
      }
    case ErrorCode.DeliveryInfoNotValid:
      return {
        title: t('errors.checkoutDeliveryInfoNotValid.title'),
        message: t('errors.checkoutDeliveryInfoNotValid.message'),
      }
    case ErrorCode.DeliveryToAddressNotSupported:
      return {
        title: t('errors.checkoutDeliveryToAddressNotSupported.title'),
        message: t('errors.checkoutDeliveryToAddressNotSupported.message'),
      }
    case ErrorCode.ServiceTimeInThePast:
      return {
        title: t('errors.checkoutServiceTimeInThePast.title'),
        message: t('errors.checkoutServiceTimeInThePast.message'),
      }
    case ErrorCode.TableOrderRequiresServiceTimeAsap:
      return {
        title: t('errors.checkoutTableOrderRequiresServiceTimeAsap.title'),
        message: t('errors.checkoutTableOrderRequiresServiceTimeAsap.message'),
      }
    case ErrorCode.PaymentAcceptOutOfStock: {
      switch (errorContext) {
        case CheckoutErrorContextType.CreateCheckout:
        case CheckoutErrorContextType.AddCheckoutItem:
          return {
            title: t('errors.checkoutCreateItemsOutOfStock.title'),
            message: t('errors.checkoutCreateItemsOutOfStock.message'),
            errorModalType: ErrorModalType.CreateCheckoutProductOutOfStock,
          }
        case CheckoutErrorContextType.ChangeCheckoutItemQuantity:
          return {
            title: t('errors.checkoutUpdateQuantityOutOfStock.title'),
            message: t('errors.checkoutUpdateQuantityOutOfStock.message'),
            errorModalType: ErrorModalType.UpdateCheckoutItemQuantityOutOfStock,
          }
        default:
          return {
            title: t('errors.checkoutItemsOutOfStock.title'),
            message: t('errors.checkoutItemsOutOfStock.message'),
            errorModalType: ErrorModalType.UpdateCheckoutProductOutOfStock,
          }
      }
    }
    case ErrorCode.CheckoutPriceChanged: {
      const products =
        ((
          apiErrorResponse?.response
            ?.data as APIErrorResponse<ErrorCode.CheckoutPriceChanged>
        )?.additionalData as ProductPriceChangeAdditionalData[] | undefined) ??
        []
      let message = t('errors.checkoutItemPriceChanged.message.multiple')

      if (products.length === 1) {
        const [{ productName, oldPrice, newPrice, currency }] = products

        const oldPriceWithCurrency = getFormattedCurrency(oldPrice, currency)
        const newPriceWithCurrency = getFormattedCurrency(newPrice, currency)

        message = t('errors.checkoutItemPriceChanged.message.single', {
          productName,
          oldPrice: oldPriceWithCurrency,
          newPrice: newPriceWithCurrency,
        })
      }

      return {
        title: t('errors.checkoutItemPriceChanged.title'),
        message,
        errorModalType: ErrorModalType.CheckoutItemPriceChanged,
        additionalData: products,
      }
    }

    default: {
      const translatedError = await getTranslatedError(err, fallback, locale)

      return {
        title: translatedError?.title || fallback.title,
        message:
          translatedError?.message ||
          apiErrorResponse?.response?.data?.message ||
          fallback.message,
      }
    }
  }
}
