import {
  NewOrderStep,
  PreOrderMethod,
  PreOrderReducerState,
  PreOrderStartStep,
} from '../types'

import customerPreOrdersEntityAdapter from './preOrderEntityAdapters'

const preOrderInitialState: PreOrderReducerState = {
  preOrderLink: null,

  startStep: PreOrderStartStep.First,
  showPreOrderHostInfoModal: false,

  preOrderCheckout: null,
  preOrderSelectedAttendeeId: null,
  preOrderCheckoutFetchPending: false,
  preOrderAddItemPending: false,
  preOrderAttendeeDeletePending: false,
  preOrderConfirmPending: false,
  preOrderUpdatePending: false,
  preOrderValidatePending: false,
  isPreOrderLeaveModalVisible: false,
  isPreOrderExistWarningModalVisible: false,

  preOrderCheckoutSummary: null,
  preOrderCheckoutSummaryFetchPending: false,
  preOrderSummaryOutletListItem: null,

  preOrderUser: null,

  isMobilePreOrderMembersManageStep: false,
  isPreOrderAttendeeRemoveModalVisible: false,
  isAttendeeConfirmPendingModalVisible: false,
  isAttendanceConfirmationModalVisible: false,
  isAttendeeEmptyItemsWarningModalVisible: false,

  preOrderCheckoutStatusLastUpdatedAt: 0,

  orderCalendarSelectedDate: null,
  orderCalendarSelectedMonth: null,
  newOrderStep: NewOrderStep.None,
  newOrderEditModeStep: NewOrderStep.None,
  newOrder: {
    orderDate: null,
    orderTime: null,
    orderFormat: null,
    orderMethod: PreOrderMethod.Individual,
    groupOrderName: null,
    groupOrderSize: null,
  },
  isNewOrderModalVisible: false,
  newGroupOrderCreatePending: false,
  createdGroupOrderId: null,
  upcomingOrderList: customerPreOrdersEntityAdapter.getInitialState({
    isFetchPending: false,
    hasMore: true,
    meta: { offset: 0, count: 0 },
  }),
  calendarPreOrderList: customerPreOrdersEntityAdapter.getInitialState({
    isFetchPending: false,
  }),
  selectedPreOrderId: null,
  selectedPreOrderListItem: null,
  selectedPreOrderDetails: null,
  selectedPreOrderFetchPending: false,

  preOrderSettings: null,
  preOrderSettingsFetchPending: false,
}

export default preOrderInitialState
