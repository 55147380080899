import clsx from 'clsx'
import { useController, useFormContext } from 'react-hook-form'
import { ProductDetailsUpsellGroupProduct } from '@ancon/wildcat-types'
import { isUpSellProductOutOfStock } from '@ancon/wildcat-utils/inventory'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import { SVGAttributes } from 'react'

import Card from '../../../layout/components/card/Card'
import getImageUrl from '../../../app/utils/getImageUrl'
import QuantityModifier from '../../../app/components/QuantityModifier'
import { ConfigureProductUpsellGroupFormState } from '../../types'
import BodyText from '../../../app/components/BodyText'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { checkoutItemQuantityByVariantIdSelector } from '../../../checkout/store/checkoutSelectors'
import useDeviceSize from '../../../app/hooks/useDeviceSize'
import ProductName from '../../components/ProductName'

import styles from './UpsellProductCard.module.scss'

type UpsellProductCardProps = {
  upsellProduct: ProductDetailsUpsellGroupProduct
  index: number
}

// Large JS chunk
const ProductPlaceholder = dynamic<SVGAttributes<SVGElement>>(
  () => import('@ancon/wildcat-ui/shared/placeholders/product.svg'),
)

export default function UpsellProductCard({
  upsellProduct,
  index,
}: UpsellProductCardProps) {
  const { t } = useTranslation('common')
  const { isMobile } = useDeviceSize()
  const { hasVariants, productName, variantName, image, stockDetails } =
    upsellProduct
  const imageUrl = image ? getImageUrl(image) : undefined

  const { setValue } = useFormContext<ConfigureProductUpsellGroupFormState>()
  const {
    field: { value: selectedCount, onChange },
  } = useController({
    name: `selectedUpsellGroups.${index}.selectedCount`,
  })

  const selected = selectedCount > 0

  // Inventory
  const isOutOfStock = isUpSellProductOutOfStock(stockDetails)
  const alreadyInCartCount = useAppSelector(state =>
    checkoutItemQuantityByVariantIdSelector(state, upsellProduct.variantId),
  )
  const addedToCart = alreadyInCartCount > 0
  const eligibleMaxQuantity =
    stockDetails?.count && addedToCart
      ? stockDetails.count - alreadyInCartCount
      : stockDetails?.count ?? undefined
  const notEnoughStock =
    stockDetails?.count && alreadyInCartCount >= stockDetails?.count
  const hasStockError = isOutOfStock || !!notEnoughStock

  function handleQuantityChange(quantity: number) {
    setValue(`selectedUpsellGroups.${index}.selectedCount`, quantity)
    onChange(quantity)
  }

  function handleCardClick() {
    if (hasStockError) return
    if (selected) return

    setValue(`selectedUpsellGroups.${index}.selectedCount`, 1)
    onChange(1)
  }

  return (
    <Card
      className={clsx(styles.container, {
        [styles.selected]: selected,
        [styles.disabled]: hasStockError,
      })}
      tabIndex={index}
      onClick={handleCardClick}
    >
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt={variantName}
          height={200}
          width={200}
          className={clsx({
            [styles.disabled]: hasStockError,
          })}
        />
      ) : (
        <ProductPlaceholder viewBox={isMobile ? '0 0 160 80' : '0 0 180 124'} />
      )}

      {hasStockError && (
        <div className={styles.outOfStock}>
          <BodyText>
            {t('components.productDetailsModal.variantOutOfStock')}
          </BodyText>
        </div>
      )}

      <div className={styles.quantityPicker}>
        <QuantityModifier
          disabled={isOutOfStock}
          initialValue={selectedCount}
          value={selectedCount}
          onChange={handleQuantityChange}
          max={eligibleMaxQuantity}
          min={0}
        />
      </div>

      <div
        className={clsx(styles.bottomContainer, {
          [styles.disabled]: hasStockError,
        })}
      >
        <ProductName as="h3">
          {hasVariants ? variantName : productName}
        </ProductName>
      </div>
    </Card>
  )
}
