import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'

import HeadingText from '../HeadingText'

import styles from './ModalHeader.module.scss'

export type ModalHeaderProps = {
  onClose?: () => void
  title?: string
}

export default function ModalHeader({ title, onClose }: ModalHeaderProps) {
  const shouldShowHeader = title || onClose

  return (
    shouldShowHeader && (
      <>
        <HeadingText as="h2">{title}</HeadingText>
        {onClose && (
          <div
            className={styles.closeButton}
            onClick={onClose}
            role="presentation"
          >
            <CloseIcon />
          </div>
        )}
      </>
    )
  )
}
