import { CheckDetails, CheckDetailsMetadata } from '@ancon/wildcat-types'
import moment from 'moment'

import { PayAndGoSession } from '../types'

import isPayAndGoSessionMetadata from './isPayAndGoSessionMetadata'

function findOthersRecentPayAndGoMetadata(
  check: Pick<CheckDetails, 'metadata'>,
  sessionId: string,
): CheckDetailsMetadata<PayAndGoSession>[] {
  return check.metadata.filter(m => {
    if (!isPayAndGoSessionMetadata(m)) {
      return false
    }

    // Limit to recent sessions only
    if (moment(m.metadata.modified).isBefore(moment().subtract(1, 'hour'))) {
      return false
    }

    return m.metadata.sessionId !== sessionId
  })
}

export default findOthersRecentPayAndGoMetadata
