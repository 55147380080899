import messagingProviders from './messagingProviders'
import customers from './customers'
import addresses from './addresses'

const user = {
  messagingProviders,
  customers,
  addresses,
}

export default user
