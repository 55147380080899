import { Store } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

import createStore from '../createStore'
import { RootState } from '../types'

const wrapper = createWrapper<Store<RootState>>(createStore, {
  // Uncomment to debug Redux hydration
  // debug: isAppDevelopment(),
  debug: false,
})

export default wrapper
