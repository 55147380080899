import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { AccordionProps } from '@ancon/wildcat-ui/web/orderweb/Accordion'

import HeadingText from '../../app/components/HeadingText'
import BodyText from '../../app/components/BodyText'
import Accordion from '../../app/components/Accordion'
import { AppTestIds } from '../../app/constants'

import styles from './ProductConfigurationAccordion.module.scss'

type AccordionSpecificProps = Pick<
  AccordionProps,
  'title' | 'expanded' | 'onToggle'
>

type ProductConfigurationAccordionProps = PropsWithChildren<
  AccordionSpecificProps & {
    subtitle?: string
    additionalInfo?: string
    hasError?: boolean
  }
>

export default function ProductConfigurationAccordion({
  title,
  subtitle,
  additionalInfo,
  hasError,
  children,
  expanded,
  onToggle,
}: ProductConfigurationAccordionProps) {
  return (
    <Accordion
      containerClassName={styles.accordion}
      headerContainerClassName={styles.headerContainer}
      caretClassName={styles.caret}
      title={
        <div
          className={styles.accordionHeader}
          data-testid={AppTestIds.ProductConfigurationAccordionHeader}
        >
          <div
            className={styles.title}
            data-testid={`${AppTestIds.ProductConfigurationAccordion}${title}`}
          >
            {typeof title === 'string' ? (
              <HeadingText as="h3">{title}</HeadingText>
            ) : (
              title
            )}
          </div>
          {subtitle && (
            <BodyText
              className={clsx(styles.subtitle, {
                [styles.error]: hasError,
              })}
              data-testid={AppTestIds.ProductConfigurationAccordionError}
            >
              <div>{subtitle}</div>
            </BodyText>
          )}
          {additionalInfo && (
            <BodyText color="body-1">{additionalInfo}</BodyText>
          )}
        </div>
      }
      expanded={expanded}
      onToggle={onToggle}
    >
      <div
        className={styles.accordionContent}
        data-testid={`${AppTestIds.ProductConfigurationAccordionChildren}${title}`}
      >
        {children}
      </div>
    </Accordion>
  )
}
