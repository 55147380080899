import { useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import {
  calculateAddonGroupsCost,
  calculateIngredientsCost,
} from '@ancon/wildcat-utils/product'
import {
  getDetailedProductVariantStock,
  isDetailedProductOutletStockOutOfStock,
} from '@ancon/wildcat-utils/inventory'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'

import Button from '../../app/components/Button'
import QuantityModifier from '../../app/components/QuantityModifier'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  productDetailsVariantByIdSelector,
  productsDetailsAddonGroupsSelector,
  productsDetailsAllowDynamicPricingSelector,
  productsDetailsIngredientsSelector,
  productsDetailsMaximumIngredientsForDynamicAddonSelector,
} from '../store/productSelectors'
import { outletSelectedOutletIdSelector } from '../../outlet/store/outletSelector'
import BodyText from '../../app/components/BodyText'
import { ConfigureProductFormState } from '../types'
import {
  checkoutCurrentCheckoutSelectedItemIdSelector,
  checkoutItemQuantityByVariantIdSelector,
  currentCheckoutAddItemPendingSelector,
} from '../../checkout/store/checkoutSelectors'
import { AppTestIds } from '../../app/constants'

import styles from './ProductFooterForm.module.scss'

export default function ProductFooterForm() {
  const { t } = useTranslation('common')
  const { setValue, watch, formState } =
    useFormContext<ConfigureProductFormState>()
  const { isValid, errors } = formState
  const [selectedVariantId, quantity, selectedIngredients, selectedAddons] =
    watch([
      'selectedVariantId',
      'quantity',
      'selectedIngredients',
      'selectedAddons',
      'quantity',
    ])

  const selectedVariant = useAppSelector(state =>
    productDetailsVariantByIdSelector(state, selectedVariantId),
  )
  const ingredients = useAppSelector(productsDetailsIngredientsSelector)
  const addonGroups = useAppSelector(productsDetailsAddonGroupsSelector)
  const selectedOutletId = useAppSelector(outletSelectedOutletIdSelector)
  const checkoutAddItemPending = useAppSelector(
    currentCheckoutAddItemPendingSelector,
  )
  const allowDynamicPricing = useAppSelector(
    productsDetailsAllowDynamicPricingSelector,
  )
  const maximumDynamicPricingIngredients = useAppSelector(
    productsDetailsMaximumIngredientsForDynamicAddonSelector,
  )
  const selectedCheckoutItemId = useAppSelector(
    checkoutCurrentCheckoutSelectedItemIdSelector,
  )

  // Pricing
  const variantPrice = selectedVariant?.variantPrice.unitPrice ?? 0

  const ingredientsPrice = ingredients
    ? calculateIngredientsCost(
        ingredients,
        selectedIngredients,
        allowDynamicPricing,
        maximumDynamicPricingIngredients,
      )
    : 0
  const addonGroupsPrice = addonGroups
    ? calculateAddonGroupsCost(addonGroups, selectedAddons)
    : 0
  const totalUnitPrice =
    variantPrice + Math.max(ingredientsPrice + addonGroupsPrice, 0)
  const subTotal = (totalUnitPrice || 0) * quantity

  // Inventory
  const alreadyInCartCount = useAppSelector(state =>
    checkoutItemQuantityByVariantIdSelector(state, selectedVariantId),
  )
  const addedToCart = alreadyInCartCount > 0
  const variantStock = getDetailedProductVariantStock(
    selectedVariant!,
    selectedOutletId!,
  )
  const eligibleMaxQuantity =
    variantStock?.stockCount && addedToCart
      ? variantStock.stockCount - alreadyInCartCount
      : variantStock?.stockCount ?? undefined
  const isVariantOutOfStock =
    isDetailedProductOutletStockOutOfStock(variantStock)
  const notEnoughStock =
    variantStock?.stockCount && alreadyInCartCount >= variantStock?.stockCount

  // Errors
  const errorMessage = errors?.selectedAddons?.find?.(
    e => e?.selectedIngredientId?.message || e?.message,
  )
  const showErrorMessage = errorMessage || isVariantOutOfStock || notEnoughStock

  function handleQuantityChange(newQuantity: number) {
    setValue('quantity', newQuantity)
  }

  function getErrorMessage() {
    if (checkoutAddItemPending) return undefined

    if (isVariantOutOfStock) {
      return t('components.productDetailsModal.variantOutOfStock')
    }
    if (notEnoughStock) {
      return t('components.productDetailsModal.notEnoughInStock')
    }

    return errorMessage?.message || errorMessage?.selectedIngredientId?.message
  }

  return (
    <div className={styles.container}>
      {showErrorMessage && <BodyText>{getErrorMessage()}</BodyText>}
      <div className={styles.footerActions}>
        <QuantityModifier
          max={eligibleMaxQuantity}
          value={quantity}
          initialValue={quantity}
          onChange={handleQuantityChange}
          className={styles.quantityChange}
          disabled={!!notEnoughStock}
        />

        <Button
          disabled={
            !isValid ||
            checkoutAddItemPending ||
            isVariantOutOfStock ||
            !!notEnoughStock
          }
          type="submit"
          data-testid={AppTestIds.Order.Modal.ProductFooterForm.AddToCartButton}
        >
          {t(
            selectedCheckoutItemId
              ? 'components.productDetailsModal.updateCart'
              : 'components.productDetailsModal.addToCart',
          )}
          <span />
          {getFormattedCurrency(
            subTotal,
            selectedVariant!.variantPrice.unitPriceCurrency,
          )}
        </Button>
      </div>
    </div>
  )
}
