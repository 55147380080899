import { useEffect, useRef, useState } from 'react'
import {
  CheckoutItem,
  CheckoutItemModification,
  ModificationType,
  ProductDetails,
} from '@ancon/wildcat-types'
import { FormProvider, useForm } from 'react-hook-form'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import isDetailedProductVariantOutOfStock from '@ancon/wildcat-utils/inventory/isDetailedProductVariantOutOfStocks'

import useAppSelector from '../../../store/hooks/useAppSelector'
import { productDetailsSelector } from '../store/productSelectors'
import { outletSelectedOutletIdSelector } from '../../outlet/store/outletSelector'
import { ConfigureProductFormState } from '../types'
import Button from '../../app/components/Button'
import {
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutCurrentCheckoutSelectedItemSelector,
} from '../../checkout/store/checkoutSelectors'
import { ProductConfigureAccordionId } from '../constants'

import ProductMetaForm from './ProductMetaForm'
import ProductVariantsForm from './ProductVariantsForm'
import ProductIngredientsForm from './ProductIngredientsForm'
import ProductAddonGroupsForm from './addonGroups/ProductAddonGroupsForm'
import ProductFooterForm from './ProductFooterForm'
import styles from './ProductConfigureForm.module.scss'

function getInitialVariantState(
  productDetails: ProductDetails,
  outletId: string,
  selectedCheckoutItem: CheckoutItem | undefined,
) {
  const { variants } = productDetails

  const inStockVariants = variants.filter(
    variant => !isDetailedProductVariantOutOfStock(variant, outletId),
  )

  const mainInStockVariant = inStockVariants.find(
    variant => variant.isMainProductVariant,
  )

  const fallBackVariant = variants.find(variant => variant.isMainProductVariant)

  const initialVariant =
    mainInStockVariant || inStockVariants[0] || fallBackVariant

  return {
    selectedVariantId:
      selectedCheckoutItem?.product.variantId || initialVariant?.id,
    selectedVariantShowingPrice:
      selectedCheckoutItem?.totalPriceInclTax.amount ||
      initialVariant.variantPrice.unitPrice,
  }
}

function getInitialIngredients(
  productDetails: ProductDetails,
  modifications?: CheckoutItemModification[],
) {
  const { ingredients } = productDetails

  const ingredientModifications = modifications?.filter(mod => !mod.groupId)

  if (ingredientModifications?.length) {
    return ingredients.map(({ ingredientId, position }) => {
      const ingredientModification = ingredientModifications.find(
        im => im.ingredientId === ingredientId,
      )

      if (ingredientModification) {
        const { modification, replaceId } = ingredientModification

        return {
          id: ingredientId,
          selected: modification !== ModificationType.Remove,
          position,
          replacementId:
            modification === ModificationType.Replace ? replaceId : undefined,
        }
      }

      return {
        id: ingredientId,
        selected: true,
        position,
      }
    })
  }

  return ingredients.map(({ ingredientId, position }) => ({
    id: ingredientId,
    selected: true,
    position,
  }))
}

function getInitialAddonGroups(
  productDetails: ProductDetails,
  modifications?: CheckoutItemModification[],
) {
  const { addOnGroups } = productDetails

  const allAddonGroupModifications = modifications?.filter(mod => mod.groupId)

  if (allAddonGroupModifications?.length) {
    return addOnGroups.map(({ addOnGroupId, ingredients, addOnSettings }) => {
      const addonGroupModifications = allAddonGroupModifications.filter(
        agm => agm.groupId === addOnGroupId,
      )

      if (addonGroupModifications.length) {
        const { allowMultiple, maximumAmount, minimumAmount } = addOnSettings
        const isRadioButtonTypeAddOn =
          !allowMultiple &&
          maximumAmount &&
          maximumAmount < 2 &&
          minimumAmount &&
          minimumAmount < 2

        const quantity = addonGroupModifications.reduce(
          (acc, agm) => acc + agm.quantity,
          0,
        )

        return {
          addonId: addOnGroupId,
          addOnSettings,
          ingredients: ingredients
            ? ingredients.map(({ id }) => {
                const addonGroupModificationIngredient =
                  addonGroupModifications.find(agm => agm.ingredientId === id)

                return {
                  id,
                  selectedCount:
                    addonGroupModificationIngredient?.quantity || 0,
                  freeAmount: addonGroupModificationIngredient?.freeAmount || 0,
                }
              })
            : [],
          selectedIngredientId: isRadioButtonTypeAddOn
            ? addonGroupModifications.find(agm => agm.groupId === addOnGroupId)
                ?.ingredientId
            : undefined,
          selectedCount: quantity,
        }
      }

      return {
        addonId: addOnGroupId,
        addOnSettings,
        ingredients: ingredients
          ? ingredients.map(({ id }) => ({
              id,
              selectedCount: 0,
              freeAmount: 0,
            }))
          : [],
        selectedIngredientId: undefined,
        selectedCount: 0,
      }
    })
  }

  return addOnGroups.map(({ addOnGroupId, ingredients, addOnSettings }) => ({
    addonId: addOnGroupId,
    addOnSettings,
    ingredients: ingredients
      ? ingredients.map(({ id }) => ({ id, selectedCount: 0, freeAmount: 0 }))
      : [],
    selectedIngredientId: undefined,
    selectedCount: 0,
  }))
}

/**
 * Get the initial active accordion id based on the product details
 * In order of priority:
 * 1. Variants
 * 2. Addon groups (first group)
 *
 * The ingredient accordion is always collapsed by default
 */
function getInitialActiveAccordionId(productDetails: ProductDetails) {
  const { hasVariants, hasAddOnGroups, variants } = productDetails

  const hasMultipleVariants = variants.length > 1

  if (hasVariants && (hasMultipleVariants || !hasAddOnGroups)) {
    return ProductConfigureAccordionId.Variants
  }

  if (hasAddOnGroups) {
    return ProductConfigureAccordionId.AddonGroup(0)
  }

  return undefined
}

type ProductConfigureFormProps = {
  onDismiss: () => void
  onSubmit: (values: ConfigureProductFormState) => void
}

export default function ProductConfigureForm({
  onDismiss,
  onSubmit,
}: ProductConfigureFormProps) {
  const contentContainerRef = useRef<HTMLDivElement>(null)

  const [showHeader, setShowHeader] = useState(false)

  const productDetails = useAppSelector(productDetailsSelector)!
  const outletId = useAppSelector(outletSelectedOutletIdSelector)!
  const checkoutOutletId = useAppSelector(
    checkoutCurrentCheckoutOutletIdSelector,
  )!
  const selectedCheckoutItem = useAppSelector(
    checkoutCurrentCheckoutSelectedItemSelector,
  )

  const { hasIngredient, hasVariants, hasAddOnGroups } = productDetails

  const hasProductConfigurations =
    hasVariants || hasIngredient || hasAddOnGroups

  const form = useForm<ConfigureProductFormState>({
    defaultValues: {
      activeAccordionId: getInitialActiveAccordionId(productDetails),
      selectedIngredients: getInitialIngredients(
        productDetails,
        selectedCheckoutItem?.modifications,
      ),
      selectedAddons: getInitialAddonGroups(
        productDetails,
        selectedCheckoutItem?.modifications,
      ),
      quantity: selectedCheckoutItem ? selectedCheckoutItem.quantity : 1,
      outletId: selectedCheckoutItem ? checkoutOutletId : outletId,
      ...getInitialVariantState(productDetails, outletId, selectedCheckoutItem),
    },
  })
  const { handleSubmit } = form

  useEffect(() => {
    const contentContainer = contentContainerRef.current

    function handleScroll() {
      if (contentContainer) {
        const scrollTop = contentContainer.scrollTop ?? 0
        setShowHeader(scrollTop > 240)
      }
    }

    if (contentContainer) {
      contentContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (contentContainer) {
        contentContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...form}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container} ref={contentContainerRef}>
          <Button variant="secondary" onClick={onDismiss}>
            <CloseIcon />
          </Button>

          <ProductMetaForm showFloatingHeader={showHeader} />
          {hasProductConfigurations && (
            <div>
              {hasVariants && <ProductVariantsForm />}
              {hasIngredient && <ProductIngredientsForm />}
              {hasAddOnGroups && <ProductAddonGroupsForm />}
            </div>
          )}
          <ProductFooterForm />
        </div>
      </form>
    </FormProvider>
  )
}
