import config from '../../config/generated.json'
import { EnvConfig } from '../../config/types'

export default function getOutletIds() {
  const envConfig = config.envConfig as EnvConfig

  if (envConfig?.outletIds?.length) {
    return [...envConfig.outletIds]
  }

  return []
}
