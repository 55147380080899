import noop from 'lodash/noop'
import omit from 'lodash/omit'
import base64URLDecode from '@ancon/wildcat-utils/codec/base64URLDecode'
import base64URLEncode from '@ancon/wildcat-utils/codec/base64URLEncode'

import localStorageUtils from '../../app/utils/localStorageUtils'
import { PreOrderUser, PreOrderUserTokenPayload } from '../types'

const DEFAULT_EXPIRE_IN = 1000 * 60 * 60 * 24 // 24 hours

class StoredPreOrderUserToken {
  private payload: PreOrderUserTokenPayload | null = null

  constructor() {
    const userToken = localStorageUtils.getItem<string>('preOrderUserToken')

    if (userToken) {
      try {
        this.payload = base64URLDecode<PreOrderUserTokenPayload>(userToken)
      } catch (error) {
        noop()
      }
    }
  }

  public create(user: PreOrderUser, expireInMs: number = DEFAULT_EXPIRE_IN) {
    try {
      this.payload = { ...user, expiresAt: Date.now() + expireInMs }

      localStorageUtils.setItem(
        'preOrderUserToken',
        base64URLEncode(this.payload),
      )
    } catch (error) {
      noop()
    }
  }

  public getAPIToken(): string {
    if (this.payload) {
      const { email, pin } = this.payload

      return base64URLEncode({ email, pin })
    }

    return ''
  }

  public getUser(): PreOrderUser | null {
    if (this.payload) {
      return omit<PreOrderUserTokenPayload>(
        this.payload,
        'expiresAt',
      ) as PreOrderUser
    }

    return null
  }

  public isExpired() {
    if (this.payload) {
      return this.payload.expiresAt < Date.now()
    }

    return true
  }

  public remove() {
    this.payload = null
    localStorageUtils.removeItem('preOrderUserToken')
    this.payload = null
  }
}

const storedPreOrderUserToken = new StoredPreOrderUserToken()

export default storedPreOrderUserToken
