import clsx from 'clsx'
import {
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'

import isValidPhoneNumber from '../utils/isValidPhoneNumber'

import BodyText from './BodyText'
import PhoneNumberInput, { PhoneNumberInputProps } from './PhoneNumberInput'
import FormInputErrorLabel from './FormInputErrorLabel'
import styles from './FormPhoneNumberInput.module.scss'

type ReactHookFormRules = UseControllerProps['rules']

interface FormPhoneNumberInputProps extends PhoneNumberInputProps {
  /* Name of the field in form state */
  fieldName: string
  /* Text label of the input */
  label?: string
  /* Validation rules of the input */
  rules?: ReactHookFormRules
  /* Whether the input is required */
  required?: boolean
  /* Whether to show error if touched */
  showErrorOnTouched?: boolean
}

function FormPhoneNumberInput({
  fieldName,
  label,
  rules,
  required,
  showErrorOnTouched = true,
  ...phoneInputProps
}: FormPhoneNumberInputProps) {
  const { t } = useTranslation('common')

  const { setValue } = useFormContext()

  function validatePhoneNumber(value: string): boolean | string {
    return isValidPhoneNumber(value) || t('validations.invalidPhoneNumber')
  }

  const {
    field: { onBlur, onChange, value },
    fieldState: { error, isTouched },
  } = useController({
    name: fieldName,
    rules: {
      ...rules,
      validate: validatePhoneNumber,
    },
  })

  const hasError = !!error && (!showErrorOnTouched || isTouched)

  function handleChange(phoneNumber: string) {
    setValue(fieldName, phoneNumber)
    onChange(phoneNumber)
  }

  return (
    <div className={styles.container}>
      {label && (
        <BodyText as="label" color="body-1" className={styles.label}>
          {label}
          {required && <span>{' *'}</span>}
        </BodyText>
      )}
      <PhoneNumberInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...phoneInputProps}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={clsx(styles.phoneInput, {
          [styles.valid]: !hasError,
          [styles.invalid]: !!hasError,
        })}
      />
      {hasError && (
        <FormInputErrorLabel htmlFor={fieldName} error={error?.message} />
      )}
    </div>
  )
}

export default FormPhoneNumberInput
