import { OrderFormat, OutletListItem } from '@ancon/wildcat-types'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'

import isGroupOrderSupportedOrderFormat from './isGroupOrderSupportedOrderFormat'

export const PreOrderUnsupportedOrderFormats = [OrderFormat.TableOrder]

function filterOrderFormat(orderFormat: OrderFormat, isEditMode: boolean) {
  // If not pre-order supported skip
  if (PreOrderUnsupportedOrderFormats.includes(orderFormat)) {
    return false
  }

  // If edit mode, order format should be supported for group order
  return !isEditMode || isGroupOrderSupportedOrderFormat(orderFormat)
}

/**
 * This function returns the order formats that are supported for pre-order in company user dashboard.
 * @param outlet The selected outlet.
 * @returns Array of order formats.
 */
export default function getCompanyDashboardPreOrderFormats(
  outlet: OutletListItem | null,
  isEditMode: boolean = false,
) {
  if (outlet) {
    const outletOrderFormats = getOutletOrderFormats(outlet)

    /**
     * Signed-in companies usually order for take-away and rarely for eat-in.
     * So, we move `TakeAway` to the first position.
     */
    const takeAwayIdx = outletOrderFormats.findIndex(
      orderFormat => orderFormat === OrderFormat.TakeAway,
    )
    if (takeAwayIdx > 0) {
      outletOrderFormats.splice(takeAwayIdx, 1)
      outletOrderFormats.unshift(OrderFormat.TakeAway)
    }

    return outletOrderFormats.filter(orderFormat =>
      filterOrderFormat(orderFormat, isEditMode),
    )
  }

  return []
}
