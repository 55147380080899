/* eslint-disable no-param-reassign */
import {
  CheckoutItemStatusUpdatedSignal,
  CheckoutStatusUpdatedSignal,
  OrderSummaryOrderItem,
  OrderTicketItemStatusUpdatedEvent,
  OrderTicketStatusUpdatedEvent,
  TicketItemStatus,
} from '@ancon/wildcat-types'
import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { CheckoutStatus } from '@ancon/wildcat-types/dist/checkout'

import { CheckOrderTicketEntityTicketItem, OrderReducerState } from '../types'
import getTicketItemStatusOfTicketStatus from '../utils/getTicketItemStatusOfTicketStatus'
import getTicketStatusOfItemStatus from '../utils/getTicketStatusOfItemStatus'
import getCheckOrderTicketMinimumItemStatus from '../utils/getCheckOrderTicketMinimumItemStatus'

import {
  activeOrdersEntityAdapter,
  checkOrderTicketsEntityAdapter,
} from './ordersEntityAdapter'

function updateOrderTicketItemsStatus(
  state: Draft<OrderReducerState>,
  ticketId: string,
  ticketItemIds: string[],
  ticketItemStatus: TicketItemStatus,
) {
  const ticketEntity = state.checkOrderSummaryTickets.entities[ticketId]

  // Order item IDs of the ticket items to be updated in STEP 2
  const orderItemIds: string[] = []

  function updateTicketItemStatus<
    T extends {
      id: string
      status: TicketItemStatus
    },
  >(ticketItem: Draft<T>) {
    const { id, status } = ticketItem

    if (ticketItemIds.includes(id) && ticketItemStatus > status) {
      ticketItem.status = ticketItemStatus
      return true
    }
    return false
  }

  // STEP 1: Update ticket items in ticket entity
  if (ticketEntity) {
    ticketEntity.ticketItems = ticketEntity.ticketItems.reduce<
      CheckOrderTicketEntityTicketItem[]
    >((acc, ticketItem) => {
      const { orderItemId } = ticketItem

      const subProductsTicketItems = ticketItem.ticketItems
      const isMultiProductTicketItem = subProductsTicketItems.length > 0

      let isTicketItemUpdated = false

      // Update parent ticket item status
      if (updateTicketItemStatus(ticketItem)) {
        isTicketItemUpdated = true
      }

      if (isMultiProductTicketItem) {
        // Update sub products ticket items
        subProductsTicketItems.forEach(item => {
          if (updateTicketItemStatus(item)) {
            isTicketItemUpdated = true
          }
        })
      }

      // If ticket item updated, store order it's order item ID to update later in STEP 2
      if (isTicketItemUpdated && !orderItemIds.includes(orderItemId)) {
        orderItemIds.push(orderItemId)
      }

      acc.push(ticketItem)

      return acc
    }, [])
  }

  // STEP 2: Update ticket items in order items of summary
  if (state.checkOrderSummary) {
    state.checkOrderSummary.items = state.checkOrderSummary.items.reduce<
      OrderSummaryOrderItem[]
    >((orderItemsAcc, orderItem) => {
      // If this order item has ticket items to be updated
      if (orderItemIds.includes(orderItem.id)) {
        const subProducts = orderItem.items
        const isMultiProductOrderItem = subProducts.length > 0

        // Update ticket items status of the order items
        orderItem.ticketItems.forEach(updateTicketItemStatus)

        if (isMultiProductOrderItem) {
          // Update sub products ticket items
          subProducts.forEach(subProduct => {
            subProduct.ticketItems.forEach(updateTicketItemStatus)
          })
        }
      }

      orderItemsAcc.push(orderItem)

      return orderItemsAcc
    }, [])
  }

  // Return updated ticket
  return ticketEntity
}

const ordersReducers = {
  ordersClearOrderDetails(state: OrderReducerState) {
    state.orderDetails = null
  },
  ordersHandleCheckoutStatusSignal(
    state: OrderReducerState,
    action: PayloadAction<CheckoutStatusUpdatedSignal>,
  ) {
    const { checkoutId, status } = action.payload
    const checkoutDetails = state.activeOrders.entities[checkoutId]
    if (checkoutDetails && !Number.isNaN(status)) {
      if (status === CheckoutStatus.Done) {
        activeOrdersEntityAdapter.removeOne(state.activeOrders, checkoutId)
      } else {
        activeOrdersEntityAdapter.updateOne(state.activeOrders, {
          id: checkoutId,
          changes: {
            status,
          },
        })
      }
    }
  },
  ordersHandleCheckoutItemStatusSignal(
    state: OrderReducerState,
    action: PayloadAction<CheckoutItemStatusUpdatedSignal>,
  ) {
    const { checkoutId, checkoutItemId, ticketItemId, ticketItemStatus } =
      action.payload
    const checkoutDetails = state.activeOrders.entities[checkoutId]

    if (checkoutDetails) {
      activeOrdersEntityAdapter.updateOne(state.activeOrders, {
        id: checkoutId,
        changes: {
          ...checkoutDetails,
          items: checkoutDetails.items.map(item => {
            if (item.id === checkoutItemId) {
              item.ticketItem = {
                ticketItemId,
                status: ticketItemStatus,
              }
            }
            return item
          }),
        },
      })
    }
  },
  checkOrderEmitTicketStatusUpdatedEvent(
    state: OrderReducerState,
    action: PayloadAction<OrderTicketStatusUpdatedEvent>,
  ) {
    function getLowerStatusTicketItemIds(
      ticketId: string,
      thresholdStatus: TicketItemStatus,
    ) {
      const ticketEntity = state.checkOrderSummaryTickets.entities[ticketId]
      if (ticketEntity) {
        return ticketEntity.ticketItems.reduce<string[]>((acc, ticketItem) => {
          if (ticketItem.status < thresholdStatus) {
            acc.push(ticketItem.id)
          }
          return acc
        }, [])
      }
      return []
    }

    if (
      state.checkOrderSummary &&
      state.checkOrderSummary.id === action.payload.orderId
    ) {
      const { ticketIds, ticketStatus } = action.payload

      // STEP 1: Update ticket statuses
      const updates = ticketIds.map(ticketId => ({
        id: ticketId,
        changes: {
          status: ticketStatus,
        },
      }))

      checkOrderTicketsEntityAdapter.updateMany(
        state.checkOrderSummaryTickets,
        updates,
      )

      // STEP 2: Update ticket items statuses of the tickets
      const ticketItemStatus = getTicketItemStatusOfTicketStatus(ticketStatus)

      ticketIds.forEach(ticketId => {
        const ticketItemIds = getLowerStatusTicketItemIds(
          ticketId,
          ticketItemStatus,
        )
        updateOrderTicketItemsStatus(
          state,
          ticketId,
          ticketItemIds,
          ticketItemStatus,
        )
      })
    }
  },
  checkOrderEmitTicketItemStatusUpdatedEvent(
    state: OrderReducerState,
    action: PayloadAction<OrderTicketItemStatusUpdatedEvent>,
  ) {
    if (
      state.checkOrderSummary &&
      state.checkOrderSummary.id === action.payload.orderId
    ) {
      const { ticketId, ticketItemId, ticketItemStatus } = action.payload

      // STEP 1: Update ticket items status
      const updatedTicketEntity = updateOrderTicketItemsStatus(
        state,
        ticketId,
        [ticketItemId],
        ticketItemStatus,
      )

      if (updatedTicketEntity) {
        const minimumTicketItemStatus =
          getCheckOrderTicketMinimumItemStatus(updatedTicketEntity)

        // STEP 2: Update ticket status to the minimum ticket item status
        checkOrderTicketsEntityAdapter.updateOne(
          state.checkOrderSummaryTickets,
          {
            id: ticketId,
            changes: {
              ...(minimumTicketItemStatus !== undefined && {
                status: getTicketStatusOfItemStatus(minimumTicketItemStatus),
              }),
            },
          },
        )
      }
    }
  },
}

export default ordersReducers
