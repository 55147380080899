import { memo, useCallback, useEffect } from 'react'
import getFullName from '@ancon/wildcat-utils/user/getFullName'

import logger, { logStrategy } from '../../../utils/logger'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { signInAnonymously } from '../store/authThunks'
import { fetchClientContext } from '../../clientContext/store/clientContextThunks'
import { clientContextCustomerSelector } from '../../clientContext/store/clientContextSelectors'
import firebaseAuth from '../../firebase/firebaseAuth'
import { setAuthHeader } from '../../../api/client/Request'
import {
  authInitializedSelector,
  authSignUpPendingSelector,
} from '../store/authSelectors'
import useAppStore from '../../../store/hooks/useAppStore'
import { setAuthClientSDKInitialized } from '../store/authSlice'

function AuthListenerComponent() {
  const dispatch = useAppDispatch()

  const store = useAppStore()

  const customer = useAppSelector(clientContextCustomerSelector)
  const authInitialized = useAppSelector(authInitializedSelector)

  const updateUserAuthorizationToken = useCallback(async () => {
    try {
      const authToken = await firebaseAuth.currentUser?.getIdToken()

      if (authToken) {
        dispatch(setAuthClientSDKInitialized())
        setAuthHeader(authToken)

        try {
          dispatch(fetchClientContext())
        } catch (error) {
          logger.error('Fetch client context', error)
          dispatch(signInAnonymously())
        }
      }
    } catch (error) {
      logger.error('Get user token', error)
    }
  }, [dispatch])

  /*
   * SSR rendered page will return fresh copy for redux store
   * This will refetch the client context and update the redux store
   */
  useEffect(() => {
    if (!authInitialized) {
      updateUserAuthorizationToken()
    }
  }, [authInitialized, updateUserAuthorizationToken])

  useEffect(() => {
    if (customer) {
      /** Set user for Bugsnag error reporting */
      logStrategy.client.setUser(
        customer.id,
        customer.email || undefined,
        getFullName(customer),
      )
    }
  }, [customer])

  useEffect(() => {
    const unsubscribeAuthObserver = firebaseAuth.onAuthStateChanged(
      async user => {
        dispatch(setAuthClientSDKInitialized())

        const authSignUpPending = authSignUpPendingSelector(store.getState())

        if (authSignUpPending) {
          return
        }

        if (user) {
          updateUserAuthorizationToken()
        } else {
          dispatch(signInAnonymously())
        }
      },
    )

    return () => {
      unsubscribeAuthObserver()
    }
  }, [dispatch, store, updateUserAuthorizationToken])

  return null
}

const AuthListener = memo(AuthListenerComponent)

export default AuthListener
