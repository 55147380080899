import { useEffect } from 'react'

import { clientContextCustomerIdSelector } from '../../clientContext/store/clientContextSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'
import useOneSignal from '../hooks/useOneSignal'

export default function OneSignalInitializer() {
  const { init } = useOneSignal()

  const customerId = useAppSelector(clientContextCustomerIdSelector)

  useEffect(() => {
    if (customerId) {
      init(customerId)
    }
  }, [init, customerId])

  return null
}
