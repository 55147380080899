import { createAsyncThunk } from '@reduxjs/toolkit'

import checkHasLocationPermission from '../utils/checkHasLocationPermission'
import getCurrentLocationPosition from '../utils/getCurrentLocationPosition'
import { GeoPosition } from '../types'

export const getCurrentGeoPosition = createAsyncThunk<
  {
    position?: GeoPosition
    permissionGranted: boolean
  },
  void
>('location/getCurrentGeoPosition', async () => {
  try {
    const { latitude, longitude } = await getCurrentLocationPosition()
    return {
      position: {
        latitude,
        longitude,
      },
      permissionGranted: true,
    }
  } catch (error) {
    return {
      permissionGranted: false,
    }
  }
})

export const checkLocationPermission = createAsyncThunk<boolean, void>(
  'location/checkLocationPermission',
  async (_, { dispatch }) => {
    const permissionHasGranted = await checkHasLocationPermission()
    if (permissionHasGranted) {
      await dispatch(getCurrentGeoPosition())
    }
    return permissionHasGranted
  },
)
