import { OrderSummaryOrderItem, TicketStatus } from '@ancon/wildcat-types'
import getMultiProductSubProductsTicketItems from '@ancon/wildcat-utils/order/getMultiProductSubProductsTicketItems'

import { CheckOrderTicketEntity } from '../types'

import getTicketStatusOfItemStatus from './getTicketStatusOfItemStatus'
import getCheckOrderTicketMinimumItemStatus from './getCheckOrderTicketMinimumItemStatus'

export default function extractCheckOrderTickets(
  orderItems: OrderSummaryOrderItem[],
) {
  // Build a map of ticket entities by ticketId
  const ticketEntitiesMap = orderItems.reduce<
    Record<string, CheckOrderTicketEntity>
  >((acc, orderItem) => {
    const { id: orderItemId, ticketItems, modifications, items } = orderItem

    const isMultiProduct = items.length > 0
    const hasModifications = modifications.length > 0

    const subProductTicketItemsMap = isMultiProduct
      ? getMultiProductSubProductsTicketItems(orderItem)
      : undefined

    ticketItems.forEach(({ id, status, ticketId }) => {
      const subProductTicketItems = subProductTicketItemsMap?.[ticketId] || []

      const ticketItem = {
        id,
        status,
        orderItemId,
        ticketItems: subProductTicketItems,
        hasModifications,
      }

      if (ticketId in acc) {
        acc[ticketId].ticketItems.push(ticketItem)
      } else {
        acc[ticketId] = {
          id: ticketId,
          // Update ticket status after `ticketItems` array is filled
          status: TicketStatus.Pending,
          ticketItems: [ticketItem],
        }
      }
    })
    return acc
  }, {})

  // Convert the map to an array of ticket entities with the correct ticketStatus
  const tickets = Object.values(ticketEntitiesMap).map<CheckOrderTicketEntity>(
    ticket => {
      const minimumTicketItemStatus =
        getCheckOrderTicketMinimumItemStatus(ticket)

      return {
        ...ticket,
        ...(minimumTicketItemStatus !== undefined && {
          status: getTicketStatusOfItemStatus(minimumTicketItemStatus),
        }),
      }
    },
  )

  return tickets
}
