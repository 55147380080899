import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CompanyMemberInvitation } from '@ancon/wildcat-types'

import { CompanyInvitationSignUpStep, CompanyReducerState } from '../types'

import {
  registerMemberWithCompanyInvitation,
  verifyCompanyMemberInvitation,
} from './companyThunks'
import companyReducers from './companyReducers'

const companyInitialState: CompanyReducerState = {
  memberInvitation: null,
  createdMemberAccount: null,
  memberInvitationSignUpStep: CompanyInvitationSignUpStep.Verification,
  memberInvitationSignUpError: null,
  verificationPendingMemberId: null,
}

const companySlice = createSlice({
  name: 'company',
  initialState: companyInitialState,
  reducers: companyReducers,
  extraReducers: builder => {
    // Company member invite verification
    builder.addCase(
      verifyCompanyMemberInvitation.fulfilled,
      (state, action: PayloadAction<CompanyMemberInvitation | undefined>) => {
        if (action.payload) {
          state.memberInvitation = action.payload
        }
      },
    )
    // Register member with company invitation
    builder.addCase(registerMemberWithCompanyInvitation.pending, state => {
      state.createdMemberAccount = null
    })
    builder.addCase(
      registerMemberWithCompanyInvitation.fulfilled,
      (state, action) => {
        state.createdMemberAccount = action.payload
      },
    )
    builder.addCase(
      registerMemberWithCompanyInvitation.rejected,
      (state, action) => {
        const { name, message } = action.error
        if (name && message) {
          state.memberInvitationSignUpError = { name, message }
        }
      },
    )
  },
})

export const {
  setInvitationSignUpStep,
  setCompanyMemberVerificationPendingModal,
} = companySlice.actions

export default companySlice.reducer
