import { CheckDetails } from '@ancon/wildcat-types'

import { PayAndGoSelectedItem } from '../types'

import findRecentPayAndGoMetadataBySessionId from './findRecentPayAndGoMetadataBySessionId'
import findOthersRecentPayAndGoMetadata from './findOthersRecentPayAndGoMetadata'

function getOwnMetadata(
  originalCheckDetails: CheckDetails | null,
  sessionId: string | null,
) {
  if (originalCheckDetails && sessionId) {
    return findRecentPayAndGoMetadataBySessionId(
      originalCheckDetails,
      sessionId,
    )
  }

  return null
}

function getOthersMetadata(
  originalCheckDetails: CheckDetails | null,
  sessionId: string | null,
) {
  if (originalCheckDetails && sessionId) {
    return findOthersRecentPayAndGoMetadata(originalCheckDetails, sessionId)
  }

  return null
}

function getPayAndGoSelectedItemsFromMetadata(
  originalCheckDetails: CheckDetails | null,
  sessionId: string | null,
) {
  // Our own metadata for selected items
  const ownMetadata = getOwnMetadata(originalCheckDetails, sessionId)

  // Other users metadata for selected items
  const othersMetadata = getOthersMetadata(originalCheckDetails, sessionId)

  const selectedItems: PayAndGoSelectedItem[] = []

  if (ownMetadata && originalCheckDetails) {
    for (const checkItem of originalCheckDetails.items) {
      const selectedItem = ownMetadata.metadata?.selectedItems.find(i =>
        i.ids.includes(checkItem.id),
      )

      if (selectedItem) {
        // Quantity selected (same item) by others
        const reservedQty =
          othersMetadata?.reduce((acc, meta) => {
            const otherSelectedItem = meta.metadata?.selectedItems.find(i =>
              i.ids.includes(checkItem.id),
            )

            if (otherSelectedItem) {
              return acc + otherSelectedItem.selectedQuantity
            }

            return acc
          }, 0) ?? 0

        const maxQty = Math.max(0, checkItem.quantity - reservedQty)

        if (selectedItem.selectedQuantity > maxQty) {
          selectedItems.push({
            ids: selectedItem.ids,
            selectedQuantity: maxQty,
          })
        } else {
          selectedItems.push(selectedItem)
        }
      }
    }
  }

  return selectedItems
}

export default getPayAndGoSelectedItemsFromMetadata
