import { ErrorCode } from '@ancon/wildcat-types'
import { AxiosResponse } from 'axios'

export enum APIType {
  Core,
  Card,
  Media,
  Google,
  User,
  Archive,
}

export interface ErrorResponseAdditionalData
  extends Record<ErrorCode, unknown> {
  /* TODO: Add any error codes with additional data types here */
}

export type APIErrorResponse<T extends ErrorCode = never> = {
  errorCode?: ErrorCode
  message?: string
  additionalData?: ErrorResponseAdditionalData[T]
}

export type ErrorInterceptor<T = APIErrorResponse> = {
  response: {
    onFulfilled: (
      value: AxiosResponse<T, any>,
    ) => AxiosResponse<T, any> | Promise<AxiosResponse<T, any>>
    onRejected: ((error: any) => any) | undefined
  }
}
