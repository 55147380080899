import Link from 'next/link'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import Container from '../container/Container'
import config from '../../../config/generated.json'
import useCartDrawerOpen from '../../../checkout/hooks/useCartDrawerOpen'
import getFooterLogoPath from '../../../app/utils/getFooterLogoPath'
import TermsOfPurchase from '../../../app/components/termsOfPurchase/TermsOfPurchase'
import isFooterBrandLogoShown from '../../../app/utils/isFooterBrandLogoShown'
import isAppWhiteLabeled from '../../../app/utils/isAppWhiteLabeled'
import getWhiteLabelClassName from '../../../app/utils/getWhiteLabelClassName'
import { WhiteLabeledAppName } from '../../../app/types'

import styles from './MainFooter.module.scss'

function MainFooter() {
  const { t } = useTranslation('generated')

  const { publicRuntimeConfig } = getConfig()
  const cartDrawerOpen = useCartDrawerOpen()

  const isWhiteLabeled = isAppWhiteLabeled()

  const showFooterBrandLogo = isFooterBrandLogoShown()

  function handleNavigateToAboutUs() {
    window.open(config.links.aboutUs, '_blank')
  }

  const footerLogoPath = getFooterLogoPath()

  return (
    <footer
      className={clsx(styles.footer, {
        [styles.cartDrawerOpen]: cartDrawerOpen,
        [styles.whiteLabeled]: isWhiteLabeled,
      })}
    >
      <Container>
        <div className={styles.primaryRow}>
          <div className={styles.primaryLeftContainer}>
            {showFooterBrandLogo && (
              <div
                className={clsx(
                  styles.footerBrand,
                  getWhiteLabelClassName({
                    [WhiteLabeledAppName.Corner]: styles.cornerApp,
                    [WhiteLabeledAppName.Lunchbox]: styles.lunchboxApp,
                    [WhiteLabeledAppName.HeatRestauranger]: styles.heatApp,
                  }),
                )}
              >
                <Image
                  height={40}
                  width={108}
                  alt="logo"
                  src={footerLogoPath}
                  priority
                />
              </div>
            )}
            {config.links.aboutUs && (
              <Button
                variant="secondary"
                size="large"
                outlined
                onClick={handleNavigateToAboutUs}
              >
                {t('common:components.footer.aboutUs')}
              </Button>
            )}
          </div>
          {!isWhiteLabeled && (
            <BodyText color="body-0" className={styles.orderingTagLine}>
              {t('footerTagline')}
            </BodyText>
          )}
        </div>
        <div className={styles.secondaryRow}>
          <BodyText color="body-1">{t('footerDescription')}</BodyText>
        </div>
        <div className={styles.linksRow}>
          <div className={styles.leftLinksContainer}>
            {config.links.aboutUs && (
              <Button
                variant="secondary"
                size="large"
                onClick={handleNavigateToAboutUs}
              >
                {t('common:components.footer.aboutUs')}
              </Button>
            )}
            <TermsOfPurchase className={styles.displayMobile} />
            <div className={styles.linksContainer}>
              <TermsOfPurchase className={styles.displayDesktop} />
              <BodyText
                className={clsx(styles.linksSeparator, styles.displayDesktop)}
              >
                &middot;
              </BodyText>
              <Link href="http://ancon.io/av.pdf" target="blank">
                <BodyText color="body-0">
                  {t('common:components.footer.termsAndConditions')}
                </BodyText>
              </Link>
              <BodyText className={styles.linksSeparator}>&middot;</BodyText>
              <Link href="https://ancon.io/privacy-policy/" target="blank">
                <BodyText color="body-0">
                  {t('common:components.footer.privacyPolicy')}
                </BodyText>
              </Link>
            </div>
            <BodyText color="body-1" className={styles.versionText}>
              © {new Date().getFullYear()} {t('copyrightBrandName')}
            </BodyText>
          </div>
          <div className={styles.rightLinksContainer}>
            <BodyText color="body-1" className={styles.versionText}>
              {publicRuntimeConfig.version}
            </BodyText>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default MainFooter
