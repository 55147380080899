import { ClientContextResponseV2 as ClientContext } from '@ancon/wildcat-types'

import { ClientContextCompanyUser } from '../types'
import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'
import getTenantIds from '../../app/utils/getTenantIds'

/**
 * Extracts company users from clientContext
 */
export default function extractCompanyUsers(
  clientContext: ClientContext | null,
): ClientContextCompanyUser[] {
  const companyUsers = clientContext?.companyUsers

  if (companyUsers?.length) {
    return companyUsers.reduce<Array<ClientContextCompanyUser>>((acc, user) => {
      const { id, name, fullName, email, emailVerified, company } = user

      // For white-labeled apps, skip company users don't belong to any app tenant
      if (isAppWhiteLabeled()) {
        const appTenantIds = getTenantIds()

        if (!appTenantIds.includes(company.tenantId)) {
          return acc
        }
      }

      acc.push({
        id,
        fullName,
        firstName: name.firstName,
        lastName: name.lastName,
        email,
        emailVerified,
        companyId: company.id,
        companyName: company.name,
        tenantName: company.tenantName,
        defaultInvoiceReferenceNote:
          company.defaultInvoiceReferenceNote ?? null,
      })

      return acc
    }, [])
  }

  return []
}
