import { useRouter } from 'next/router'

const guidRegex =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}'

export default function useRouteMatch(routes: string[]) {
  const router = useRouter()

  return routes.some(candidateRoute => {
    if (candidateRoute === '/') {
      return router.asPath === '/'
    }

    const candidateRouteRegexPattern = candidateRoute
      // Replace all variables with guid regex
      .replace(/\[[a-zA-Z_$][0-9a-zA-Z_$]*\]/g, guidRegex)
      // Replace all * with regex
      .replace(/\*/g, '.*')

    const candidateRouteRegexExp = new RegExp(`^${candidateRouteRegexPattern}`)

    return candidateRouteRegexExp.test(router.asPath)
  })
}
