import { useEffect } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../store/hooks/useAppSelector'
import Modal from '../../app/components/modal/Modal'
import {
  productDetailsPendingSelector,
  productHasDetailsSelector,
  productSelectedIdSelector,
  productsIsProductDetailsModalOpenSelector,
} from '../store/productSelectors'
import { fetchProductDetails } from '../store/productThunks'
import ProductConfigureForm from '../forms/ProductConfigureForm'
import { ConfigureProductFormState } from '../types'
import useAppStore from '../../../store/hooks/useAppStore'
import {
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutFiltersOrderFormatSelector,
  checkoutFiltersServiceTimeSelector,
} from '../../checkout/store/checkoutSelectors'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../app/constants'

import styles from './ProductDetailsModal.module.scss'
import ProductDetailsModalSkeleton from './ProductDetailsModalSkeleton'

type ProductDetailsModalProps = {
  onSubmit: (values: ConfigureProductFormState) => void
  onDismiss: () => void
}

export default function ProductDetailsModal({
  onSubmit,
  onDismiss,
}: ProductDetailsModalProps) {
  const dispatch = useAppDispatch()
  const store = useAppStore()

  const isModalVisible = useAppSelector(
    productsIsProductDetailsModalOpenSelector,
  )
  const productHasDetails = useAppSelector(productHasDetailsSelector)
  const selectedProductId = useAppSelector(productSelectedIdSelector)
  const isProductDetailsPending = useAppSelector(productDetailsPendingSelector)

  const isPreOrderManage = useRouteMatch([AppRoutes.PreOrderManage])

  useEffect(() => {
    if (selectedProductId && isModalVisible) {
      const checkoutOutletId = checkoutCurrentCheckoutOutletIdSelector(
        store.getState(),
      )
      const checkoutOrderFormat = checkoutFiltersOrderFormatSelector(
        store.getState(),
      )
      const checkoutServiceTime = checkoutFiltersServiceTimeSelector(
        store.getState(),
      )

      dispatch(
        fetchProductDetails({
          productId: selectedProductId,
          ...(isPreOrderManage && {
            outletId: checkoutOutletId,
            orderFormat: checkoutOrderFormat,
            orderDateTime: checkoutServiceTime.time,
          }),
        }),
      )
    }
  }, [dispatch, isModalVisible, isPreOrderManage, selectedProductId, store])

  return (
    <Modal
      isOpen={isModalVisible}
      onClose={onDismiss}
      className={styles.modal}
      mobileModalMode="bottom-sheet"
      hideClose
    >
      <div className={styles.modalBody}>
        {productHasDetails && !isProductDetailsPending ? (
          <ProductConfigureForm onSubmit={onSubmit} onDismiss={onDismiss} />
        ) : (
          <ProductDetailsModalSkeleton />
        )}
      </div>
    </Modal>
  )
}
