import {
  EmailAuthProvider,
  linkWithCredential,
  updateProfile,
} from 'firebase/auth'
import getT from 'next-translate/getT'
import getFullName from '@ancon/wildcat-utils/user/getFullName'
import {
  CompanyMemberEmailType,
  CompanyMemberInvitation,
  CreateCompanyMemberAccountPayload,
  ErrorCode,
} from '@ancon/wildcat-types'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import firebaseAuth from '../../firebase/firebaseAuth'
import getTranslatedError from '../../app/utils/getTranslatedError'
import { clientContextCustomerIdSelector } from '../../clientContext/store/clientContextSelectors'
import api from '../../../api'
import { fetchClientContext } from '../../clientContext/store/clientContextThunks'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { CompanyMemberAccount, MemberInvitationWithCredentials } from '../types'
import localStorageUtils from '../../app/utils/localStorageUtils'

export const verifyCompanyMemberInvitation = createAppAsyncThunk<
  CompanyMemberInvitation | undefined,
  { token: string }
>(
  'company/verifyMemberInvitation',
  async ({ token }, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      const response = await api.core.companies.get.verifyMemberInvitation({
        invitationToken: token,
      })

      return response?.data
    } catch (err) {
      const translatedError = await getTranslatedError(
        err,
        {
          title: t('errors.genericVerifyCompanyInvitationFailure.title'),
          message: t('errors.genericVerifyCompanyInvitationFailure.message'),
        },
        locale,
      )

      return rejectWithValue(translatedError)
    }
  },
)

export const registerMemberWithCompanyInvitation = createAppAsyncThunk<
  CompanyMemberAccount,
  MemberInvitationWithCredentials
>(
  'company/registerMemberWithCompanyInvitation',
  async (invitationWithCredentials, { dispatch, getState }) => {
    const locale = appLanguageSelector(getState())

    const customerId = clientContextCustomerIdSelector(getState())

    const { email, password, ...invitation } = invitationWithCredentials

    const {
      id: memberId,
      companyId,
      firstName,
      lastName,
      phoneNumber,
      department,
    } = invitation

    const memberData: CreateCompanyMemberAccountPayload = {
      firstName,
      lastName,
      password,
      phoneNumber,
      department,
    }

    let firebaseAccountLinked = false

    const isAnonymous = firebaseAuth.currentUser?.isAnonymous

    try {
      if (!customerId || isAnonymous) {
        // Create credentials for email and password
        const credentials = EmailAuthProvider.credential(email, password)

        // To avoid throwing FirebaseAuthRecentLoginRequiredException when linking credentials
        await firebaseAuth.currentUser?.getIdToken(true)

        /** Link the credentials to the anonymous user */
        const firebaseUser = firebaseAuth.currentUser
        if (firebaseUser) {
          await linkWithCredential(firebaseUser, credentials)
          firebaseAccountLinked = true
        }

        const response = await api.core.companies.put.createMemberAccount(
          memberData,
          { companyId, memberId },
        )

        // Force refresh the idToken
        await firebaseAuth.currentUser?.getIdToken(true)

        if (firebaseUser) {
          const updatedProfile = { displayName: getFullName(memberData) }

          // Update firebase user's display name
          await updateProfile(firebaseUser, updatedProfile)
        }

        const { companyMember } = response.data

        // Set the company profile id in local storage
        localStorageUtils.setItem('companyProfileId', companyMember.id)

        await dispatch(fetchClientContext())

        return companyMember
      }

      // If the user is already logged in, just create the member account
      const response = await api.core.companies.put.createMemberAccount(
        memberData,
        { companyId, memberId },
      )

      return response.data.companyMember
    } catch (err) {
      const t = await getT(locale, 'common')
      const translatedError = await getTranslatedError(
        err,
        {
          title: t('errors.genericCompanyInvitationSignUpFailure.title'),
          message: t('errors.genericCompanyInvitationSignUpFailure.message'),
        },
        locale,
      )

      if (firebaseAccountLinked) {
        firebaseAuth.currentUser?.delete().catch(error => {
          if (error?.code === ErrorCode.AuthRecentLoginRequiredException) {
            firebaseAuth.signOut()
          }
        })
      }

      const appError = {
        name: translatedError.title,
        message: translatedError.message,
      }

      throw appError as Error
    }
  },
)

export const sendCompanyMemberEmails = createAppAsyncThunk<
  void,
  { companyId: string; memberId: string; emailType: CompanyMemberEmailType }
>(
  'company/sendCompanyMemberEmails',
  async ({ companyId, memberId, emailType }, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())
    const t = await getT(locale, 'common')
    try {
      await api.core.companies.post.sendMemberEmails(
        { emailType },
        { companyId, memberId },
      )
      return undefined
    } catch (err) {
      const translatedError = await getTranslatedError(
        err,
        {
          title: t('errors.genericCompanyMemberEmailSendFailure.title'),
          message: t('errors.genericCompanyMemberEmailSendFailure.message'),
        },
        locale,
      )

      return rejectWithValue(translatedError)
    }
  },
)
