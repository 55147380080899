import React from 'react'
import { Amount } from '@ancon/wildcat-types'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'

import BodyText from '../../../app/components/BodyText'

import styles from './OrderAmountField.module.scss'

type OrderAmountFieldProps = {
  name: string
  amount: Amount
  showAsDeduction?: boolean
}

export default function OrderAmountField({
  name,
  amount,
  showAsDeduction = false,
}: OrderAmountFieldProps) {
  function getFormattedAmount() {
    const formattedAmount = getFormattedCurrency(amount.amount, amount.currency)

    if (showAsDeduction) {
      return `- ${formattedAmount}`
    }

    return formattedAmount
  }

  return (
    <div className={styles.container}>
      <BodyText color="body-1">{name}</BodyText>
      <BodyText color="body-1">{getFormattedAmount()}</BodyText>
    </div>
  )
}
