import { PayloadAction } from '@reduxjs/toolkit'

import { CompanyInvitationSignUpStep, CompanyReducerState } from '../types'

const companyReducers = {
  setInvitationSignUpStep(
    state: CompanyReducerState,
    action: PayloadAction<CompanyInvitationSignUpStep>,
  ) {
    state.memberInvitationSignUpStep = action.payload
  },
  setCompanyMemberVerificationPendingModal(
    state: CompanyReducerState,
    action: PayloadAction<{ memberId?: string }>,
  ) {
    state.verificationPendingMemberId = action.payload?.memberId ?? null
  },
}

export default companyReducers
