import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import { ProductDetails } from '@ancon/wildcat-types'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import Button from '../../../app/components/Button'
import HeadingText from '../../../app/components/HeadingText'
import { ConfigureProductUpsellGroupFormState } from '../../types'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  productDetailsSelector,
  productsDetailsUpsellGroupProductsSelector,
} from '../../store/productSelectors'

import styles from './ConfigureUpsellGroupForm.module.scss'
import UpsellProductCard from './UpsellProductCard'

function getInitialUpsellGroupState(productDetails: ProductDetails) {
  const { upsellGroups } = productDetails

  if (!!upsellGroups.length && !!upsellGroups[0].products.length) {
    const { products, id } = upsellGroups[0]

    return {
      selectedUpsellGroups: products.map(product => ({
        variantId: product.variantId,
        selectedCount: 0,
      })),
      upsellGroupId: id,
    }
  }

  return {
    selectedUpsellGroups: [],
    upsellGroupId: undefined,
  }
}

type ConfigureUpsellGroupFormProps = {
  onDismiss: () => void
  onSubmit: (data: ConfigureProductUpsellGroupFormState) => void
}

export default function ConfigureUpsellGroupForm({
  onDismiss,
  onSubmit,
}: ConfigureUpsellGroupFormProps) {
  const { t } = useTranslation('common')
  const productDetails = useAppSelector(productDetailsSelector)
  const upsellGroupProducts = useAppSelector(
    productsDetailsUpsellGroupProductsSelector,
  )
  const contentContainerRef = useRef<HTMLDivElement>(null)
  const [showHeader, setShowHeader] = useState(false)

  const form = useForm<ConfigureProductUpsellGroupFormState>({
    defaultValues: {
      ...getInitialUpsellGroupState(productDetails!),
    },
  })
  const { handleSubmit } = form

  useEffect(() => {
    const contentContainer = contentContainerRef.current

    function handleScroll() {
      if (contentContainer) {
        const scrollTop = contentContainer.scrollTop ?? 0
        setShowHeader(scrollTop > 80)
      }
    }

    if (contentContainer) {
      contentContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (contentContainer) {
        contentContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...form}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container} ref={contentContainerRef}>
          <Button variant="secondary" onClick={onDismiss}>
            <CloseIcon />
          </Button>

          <div className={styles.topContainer}>
            <div
              className={clsx(styles.floatingHeader, {
                [styles.show]: showHeader,
              })}
            >
              <HeadingText as="h2">
                {t('components.productDetailsModal.commonAdditions')}
              </HeadingText>
            </div>

            <HeadingText as="h2">
              {t('components.productDetailsModal.commonAdditions')}
            </HeadingText>

            <div className={styles.upsellListing}>
              {upsellGroupProducts.map((upsellProduct, i) => (
                <UpsellProductCard
                  key={upsellProduct.variantId}
                  upsellProduct={upsellProduct}
                  index={i}
                />
              ))}
            </div>
          </div>

          <div className={styles.footerContainer}>
            <Button type="submit">
              {t('components.productDetailsModal.continue')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
