import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useState } from 'react'

import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { LanguageOptions } from '../../constants'
import { appIsSwitchLanguageModalVisibleSelector } from '../../store/appSelectors'
import { appSetIsSwitchLanguageModalVisible } from '../../store/appSlice'
import useDeviceSize from '../../hooks/useDeviceSize'
import Modal from '../modal/Modal'
import Button from '../Button'

import styles from './SwitchLanguageModal.module.scss'

function SwitchLanguageModalContent() {
  const { lang, t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [selectedValue, setSelectedValue] = useState(lang)

  function handleSwitchLanguage() {
    const { replace, asPath } = router
    replace(router, asPath, {
      locale: selectedValue,
    })

    dispatch(appSetIsSwitchLanguageModalVisible(false))
  }

  return (
    <div className={styles.container}>
      {LanguageOptions.map(({ label, value }) => (
        <Button
          variant="secondary"
          onClick={() => setSelectedValue(value)}
          key={value}
          className={clsx(styles.languageButton, {
            [styles.selected]: selectedValue === value,
          })}
        >
          {label}
        </Button>
      ))}

      <div className={styles.footer}>
        <Button
          disabled={selectedValue === lang}
          onClick={handleSwitchLanguage}
        >
          {t('components.switchLanguageModal.save')}
        </Button>
      </div>
    </div>
  )
}

export default function SwitchLanguageModal() {
  const { t } = useTranslation('common')

  const isOpen = useAppSelector(appIsSwitchLanguageModalVisibleSelector)

  const { isMobile } = useDeviceSize()

  const dispatch = useAppDispatch()

  function handleCloseModal() {
    dispatch(appSetIsSwitchLanguageModalVisible(false))
  }

  return (
    <Modal
      mobileModalMode="bottom-sheet"
      isOpen={isOpen && isMobile}
      title={t('components.switchLanguageModal.title')}
      onClose={handleCloseModal}
    >
      <SwitchLanguageModalContent />
    </Modal>
  )
}
