import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import moment from 'moment'
import clsx from 'clsx'

import Dropdown from '../../../../app/components/dropdown/Dropdown'
import { LanguageOptions, NonOrderRoutes } from '../../../../app/constants'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { appSetLanguage } from '../../../../app/store/appSlice'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { appLanguageSelector } from '../../../../app/store/appSelectors'
import { setAcceptLanguage } from '../../../../../api/client/Request'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'

import styles from './NavLanguageSwitcher.module.scss'

function updateMomentLocale(locale: string) {
  switch (locale) {
    case 'sv':
      moment.locale('sv')
      break
    case 'nb':
      moment.locale('nb')
      break
    default:
      moment.locale('en-gb')
  }
}

export default function NavLanguageSwitcher() {
  const router = useRouter()

  const currentLocale = useAppSelector(appLanguageSelector)

  const isNonOrderRoute = useRouteMatch(NonOrderRoutes)

  const dispatch = useAppDispatch()

  const { lang } = useTranslation()

  useEffect(() => {
    if (currentLocale !== lang) {
      dispatch(appSetLanguage(lang))
      setAcceptLanguage(lang)
      updateMomentLocale(lang)
    }
  }, [dispatch, lang, currentLocale])

  useEffect(() => {
    // Set the initial language
    if (lang) {
      setAcceptLanguage(lang)
      updateMomentLocale(lang)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleLocaleChange(nextLocale: string) {
    const { replace, asPath } = router
    replace(router, asPath, {
      locale: nextLocale,
    })
  }

  return (
    <div
      className={clsx(styles.localeSwitchContainer, {
        [styles.isOrderPage]: !isNonOrderRoute,
      })}
    >
      {!isNonOrderRoute && <div className={styles.divider} />}
      <Dropdown
        label={lang}
        buttonClassName={styles.localeSwitchButton}
        popupClassName={styles.localSwitchDropdown}
        labelClassName={styles.localeSwitchLabel}
        popupPlacement="top-end"
        onClickOption={handleLocaleChange}
        options={LanguageOptions}
      />
    </div>
  )
}
