import { OAuthProvider } from 'firebase/auth'

import getLanguageCode from '../app/utils/getLanguageCode'

import { AuthProviderId } from './constants'

class AppleAuthProvider extends OAuthProvider {
  /**
   * Construct a new AppleAuthProvider with localized Auth flow
   * @param language - selected language of the app
   */
  constructor(language?: string) {
    super(AuthProviderId.APPLE)

    if (language) {
      const languageCode = getLanguageCode(language)

      this.setCustomParameters({ locale: languageCode })
    }
  }
}

export default AppleAuthProvider
