import React from 'react'
import { TicketItemStatus } from '@ancon/wildcat-types'
import TickIcon from '@ancon/wildcat-ui/shared/icons/tick.svg'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'

import styles from './CartTicketItemStatus.module.scss'

type CartTicketItemStatusProps = {
  status: TicketItemStatus
  className?: string
}

export default function CartTicketItemStatus({
  status,
  className,
}: CartTicketItemStatusProps) {
  const { t } = useTranslation('checkout')

  function getStatusText() {
    switch (status) {
      case TicketItemStatus.Ready:
        return t('ready')
      case TicketItemStatus.Closed:
        return t('pickedUp')
      default:
        return ''
    }
  }

  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.ready]: status === TicketItemStatus.Ready,
          [styles.pickedUp]: status === TicketItemStatus.Closed,
        },
        className,
      )}
    >
      <TickIcon />
      <BodyText>{getStatusText()}</BodyText>
    </div>
  )
}
