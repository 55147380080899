import { forwardRef } from 'react'
import clsx from 'clsx'
import WildcatInput, { InputProps } from '@ancon/wildcat-ui/web/orderweb/Input'

import getWhiteLabelClassName from '../../utils/getWhiteLabelClassName'
import { WhiteLabeledAppName } from '../../types'

import '@ancon/wildcat-ui/web/orderweb/Input/Input.css'
import styles from './Input.module.scss'

function Input(inputProps: InputProps, ref: React.Ref<HTMLInputElement>) {
  const { className, ...props } = inputProps

  const classValues = clsx(
    className,
    getWhiteLabelClassName({
      [WhiteLabeledAppName.HeatRestauranger]: styles.heatApp,
    }),
  )

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WildcatInput ref={ref} className={classValues} {...props} />
}

export type { InputProps }

export default forwardRef<HTMLInputElement, InputProps>(Input)
