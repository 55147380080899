import { CustomerAddress } from '@ancon/wildcat-types'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import { CreateCustomerAddressRequestBody } from '../types'
import { clientContextCustomerIdSelector } from '../../clientContext/store/clientContextSelectors'
import api from '../../../api'

export const fetchCustomerAddressesList = createAppAsyncThunk<{
  items: CustomerAddress[]
}>('addresses/fetchCustomerAddresses', async (_, { getState }) => {
  const customerId = clientContextCustomerIdSelector(getState())!

  const response = await api.user.addresses.get.list({
    customerId,
  })
  return response!.data
})

export const createCustomerAddress = createAppAsyncThunk<
  {
    customerAddress: CustomerAddress
  },
  { address: CreateCustomerAddressRequestBody }
>(
  'addresses/createCustomerAddress',
  async ({ address }, { getState, dispatch }) => {
    const customerId = clientContextCustomerIdSelector(getState())!

    const response = await api.user.addresses.post.create(address, {
      customerId,
    })
    dispatch(fetchCustomerAddressesList())
    return response!.data
  },
)

export const updateCustomerAddress = createAppAsyncThunk<
  {
    customerAddress: CustomerAddress
  },
  { address: CreateCustomerAddressRequestBody; addressId: string }
>(
  'addresses/updateCustomerAddress',
  async ({ address, addressId }, { getState, dispatch }) => {
    const customerId = clientContextCustomerIdSelector(getState())!

    const response = await api.user.addresses.put.update(address, {
      customerId,
      addressId,
    })
    dispatch(fetchCustomerAddressesList())
    return response!.data
  },
)

export const deleteCustomerAddress = createAppAsyncThunk<
  void,
  { addressId: string }
>(
  'addresses/deleteCustomerAddress',
  async ({ addressId }, { getState, dispatch }) => {
    const customerId = clientContextCustomerIdSelector(getState())!

    await api.user.addresses.delete.deleteAddress(undefined, {
      customerId,
      addressId,
    })

    dispatch(fetchCustomerAddressesList())
  },
)
