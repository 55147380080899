import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ClientContextResponseV2 } from '@ancon/wildcat-types'

import {
  AccountInformationStatus,
  AuthReducerState,
  ProfileSelectionModalType,
} from '../types'
import { fetchClientContext } from '../../clientContext/store/clientContextThunks'

import {
  anonymizeUserAccount,
  registerAsCompanyUser,
  registerSocialAuthCustomer,
  registerWithUserDetails,
  signInWith,
  signOut,
} from './authThunks'
import authReducers from './authReducers'

const authInitialState: AuthReducerState = {
  clientSDKLoaded: false,

  loggedIn: null,

  signInPending: false,
  signInProvider: null,

  signUpPending: false,

  socialSignUpPending: false,

  showAuthModal: false,
  isSignUpModal: false,
  showEditAccountModal: false,
  showGuestCustomerOnboardingModal: false,

  signOutPending: false,

  profileSelectionModalType: ProfileSelectionModalType.None,

  userAnonymizePending: false,

  showCompanySignUpModal: false,
  showReviewAccountDetailsModal: false,
  createdCompanyId: null,
  newAccountDetails: null,
  selectedAccountInfoStatus: AccountInformationStatus.Current,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: authReducers,
  extraReducers: builder => {
    // Sign Up
    builder.addCase(registerWithUserDetails.pending, state => {
      state.signUpPending = true
    })

    // Email-Password, Google, Facebook & Apple Sign In
    builder.addCase(signInWith.pending, (state, action) => {
      state.signInPending = true
      state.signInProvider = action.meta.arg.signInProvider
    })

    // Google, Facebook & Apple Sign Up
    builder.addCase(registerSocialAuthCustomer.pending, state => {
      state.socialSignUpPending = true
    })

    // Sign Out
    builder.addCase(signOut.pending, state => {
      state.signOutPending = true
    })
    builder.addCase(signOut.fulfilled, state => ({
      ...authInitialState,
      loggedIn: false,
      clientSDKLoaded: state.clientSDKLoaded,
      showCompanySignUpModal: state.showCompanySignUpModal,
    }))
    builder.addCase(signOut.rejected, state => {
      state.signOutPending = false
    })

    // Anonymize User Account
    builder.addCase(anonymizeUserAccount.pending, state => {
      state.userAnonymizePending = true
    })

    // Client Context
    builder.addCase(
      fetchClientContext.fulfilled,
      (state, action: PayloadAction<ClientContextResponseV2 | null>) => {
        state.loggedIn = !!action.payload?.customer?.customerUserId
      },
    )
    builder.addCase(fetchClientContext.rejected, state => {
      state.loggedIn = false
    })

    // Company Sign Up
    builder.addCase(registerAsCompanyUser.fulfilled, (state, action) => {
      state.createdCompanyId = action.payload.createdCompanyId

      if (action.payload.isExistingUser) {
        const { firstName, lastName, telephoneNumber } = action.meta.arg
        state.newAccountDetails = {
          firstName,
          lastName,
          telephoneNumber,
        }
        state.selectedAccountInfoStatus = AccountInformationStatus.Current
      }
    })

    // Email-Password Sign Up
    builder.addMatcher(
      isAnyOf(
        registerWithUserDetails.fulfilled,
        registerWithUserDetails.rejected,
      ),
      state => {
        state.signUpPending = false
      },
    )

    // Google, Facebook & Apple Sign Up
    builder.addMatcher(
      isAnyOf(
        registerSocialAuthCustomer.fulfilled,
        registerSocialAuthCustomer.rejected,
      ),
      state => {
        state.socialSignUpPending = false
      },
    )

    // Email-Password, Google, Facebook & Apple Sign In
    builder.addMatcher(
      isAnyOf(signInWith.fulfilled, signInWith.rejected),
      state => {
        state.signInPending = false
        state.signInProvider = null
      },
    )

    // Anonymize User Account
    builder.addMatcher(
      isAnyOf(anonymizeUserAccount.fulfilled, anonymizeUserAccount.rejected),
      state => {
        state.userAnonymizePending = false
      },
    )
  },
})

export const {
  showAuthModal,
  hideAuthModal,
  setAuthClientSDKInitialized,
  setAuthProfileSelectionModalType,
  setAuthCompanySignUpModalVisible,
  setAuthAccountEditModalVisible,
  setAuthGuestCustomerOnboardingModalVisible,
  setReviewAccountModalVisible,
  setSelectedAccountInformationStatus,
  resetNewAccountDetails,
} = authSlice.actions

export default authSlice.reducer
