import clsx from 'clsx'
import { LabelHTMLAttributes } from 'react'

import styles from './FormInputErrorLabel.module.scss'

interface FormInputErrorLabelProps
  extends LabelHTMLAttributes<HTMLLabelElement> {
  /* Error message to show */
  error?: string
}

function FormInputErrorLabel({
  className,
  error,
  htmlFor,
  ...labelProps
}: FormInputErrorLabelProps) {
  return (
    <label
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...labelProps}
      htmlFor={htmlFor}
      className={clsx(className, styles.errorLabel)}
    >
      {error ?? ''}
    </label>
  )
}

export default FormInputErrorLabel
