import { TicketItemStatus } from '@ancon/wildcat-types'
import min from 'lodash/min'

import { CheckOrderTicketEntity } from '../types'

/**
 * Get the minimum status of all ticket items in the ticket
 *
 * @param ticket Check order ticket entity containing ticket items with statuses
 * @returns the minimum status or undefined if no ticket items
 */
export default function getCheckOrderTicketMinimumItemStatus(
  ticket: CheckOrderTicketEntity,
) {
  const ticketItemStatuses = ticket.ticketItems.reduce<TicketItemStatus[]>(
    (acc, { status, ticketItems, hasModifications }) => {
      // If there are sub ticket items, then item should be a multi-product
      const isMultiProduct = ticketItems.length > 0

      if (isMultiProduct) {
        // If has modifications, need to consider parent item status
        if (hasModifications) acc.push(status)

        // Consider sub product ticket item statuses
        const subProductItemStatuses = ticketItems.map(
          subProductItem => subProductItem.status,
        )
        acc.push(...subProductItemStatuses)
      } else {
        acc.push(status)
      }

      return acc
    },
    [],
  )

  return min(ticketItemStatuses)
}
