import { CheckItemStatus } from '@ancon/wildcat-types'

/**
 * Get whether the corresponding check item is previewed
 * @param status Check item status
 */
function isCheckItemPreviewed(status: CheckItemStatus): boolean {
  return status === CheckItemStatus.Ticketed + CheckItemStatus.Preview
}

export default isCheckItemPreviewed
