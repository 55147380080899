import clsx from 'clsx'
import Image from 'next/image'
import { useFormContext } from 'react-hook-form'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import calculateIngredientsCost from '@ancon/wildcat-utils/product/calculateIngredientsCost'
import calculateAddonGroupsCost from '@ancon/wildcat-utils/product/calculateAddonGroupCost'
import ProductPlaceholderIcon from '@ancon/wildcat-ui/shared/static/placeholders/product-v2.svg'

import BodyText from '../../app/components/BodyText'
import HeadingText from '../../app/components/HeadingText'
import AdvancedPricingTag from '../components/AdvancedPricingTag'
import ProductAllergyInformation from '../components/ProductAllergyInformation'
import useAppSelector from '../../../store/hooks/useAppSelector'
import getImageUrl from '../../app/utils/getImageUrl'
import {
  productDetailsVariantByIdSelector,
  productsDetailsAddonGroupsSelector,
  productsDetailsAllowDynamicPricingSelector,
  productsDetailsDescriptionSelector,
  productsDetailsHasVariantsSelector,
  productsDetailsImagesSelector,
  productsDetailsIngredientsSelector,
  productsDetailsMaximumIngredientsForDynamicAddonSelector,
  productsDetailsProductNameSelector,
} from '../store/productSelectors'
import ProductName from '../components/ProductName'

import styles from './ProductMetaForm.module.scss'

type ProductMetaFormProps = {
  showFloatingHeader?: boolean
}

export default function ProductMetaForm({
  showFloatingHeader,
}: ProductMetaFormProps) {
  const { watch } = useFormContext()
  const [selectedVariantId, selectedIngredients, selectedAddons] = watch([
    'selectedVariantId',
    'selectedIngredients',
    'selectedAddons',
  ])

  const images = useAppSelector(productsDetailsImagesSelector)
  const hasVariants = useAppSelector(productsDetailsHasVariantsSelector)
  const productName = useAppSelector(productsDetailsProductNameSelector)
  const description = useAppSelector(productsDetailsDescriptionSelector)
  const selectedVariant = useAppSelector(state =>
    productDetailsVariantByIdSelector(state, selectedVariantId),
  )
  const addonGroups = useAppSelector(productsDetailsAddonGroupsSelector)
  const ingredients = useAppSelector(productsDetailsIngredientsSelector)
  const allowDynamicPricing = useAppSelector(
    productsDetailsAllowDynamicPricingSelector,
  )
  const maximumDynamicPricingIngredients = useAppSelector(
    productsDetailsMaximumIngredientsForDynamicAddonSelector,
  )
  const ingredientsPrice = ingredients
    ? calculateIngredientsCost(
        ingredients,
        selectedIngredients,
        allowDynamicPricing,
        maximumDynamicPricingIngredients,
      )
    : 0
  const addonGroupsPrice = addonGroups
    ? calculateAddonGroupsCost(addonGroups, selectedAddons)
    : 0

  const imageUrl = images?.[0] ? getImageUrl(images?.[0]?.id) : null
  const priceAddition = ingredientsPrice + addonGroupsPrice

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.floatingHeader, {
          [styles.show]: showFloatingHeader,
        })}
      >
        <HeadingText as="h2">
          {hasVariants
            ? selectedVariant?.customerFacingName || selectedVariant?.name
            : productName}
        </HeadingText>
      </div>

      <div className={styles.imageContainer}>
        {imageUrl && <img src={imageUrl} alt="product" />}
        {!imageUrl && (
          <Image
            alt={productName || 'product placeholder image'}
            className={styles.productPlaceholderImage}
            src={ProductPlaceholderIcon}
          />
        )}
      </div>

      <div className={styles.metaContainer}>
        <ProductName as="h2">
          {hasVariants
            ? selectedVariant?.customerFacingName || selectedVariant?.name
            : productName}
        </ProductName>
        <div className={styles.pricingContainer}>
          <HeadingText as="h3">
            {getFormattedCurrency(
              selectedVariant?.variantPrice.unitPrice,
              selectedVariant?.variantPrice.unitPriceCurrency,
            )}
          </HeadingText>
          {priceAddition > 0 && (
            <BodyText color="body-1" className={styles.priceAddition}>
              +{' '}
              {getFormattedCurrency(
                priceAddition,
                selectedVariant?.variantPrice.unitPriceCurrency,
              )}
            </BodyText>
          )}
        </div>

        {selectedVariant?.variantPrice.showPricePresetLabel &&
          selectedVariant?.variantPrice.pricePresetName && (
            <AdvancedPricingTag
              presetName={selectedVariant?.variantPrice.pricePresetName}
            />
          )}

        {description && <BodyText color="body-2">{description}</BodyText>}
        <ProductAllergyInformation />
      </div>
    </div>
  )
}
