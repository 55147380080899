import config from '../../config/generated.json'
import { EnvConfig } from '../../config/types'

function getOneSignalAppId(): string | null {
  if (process.env.NODE_ENV === 'development') {
    return null
  }

  const envConfig = config.envConfig as EnvConfig

  return envConfig?.oneSignalAppId || null
}

export default getOneSignalAppId
