import { SupportedLanguage } from '../constants'

// Get IETF tag
export default function getLocaleTag(locale?: string): string {
  switch (locale) {
    case SupportedLanguage.English:
      return 'en-US'

    case SupportedLanguage.Norwegian:
      return 'nb-NO'

    default:
      return 'sv-SE'
  }
}
