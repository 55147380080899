import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { FieldName, useFormContext } from 'react-hook-form'

import BodyText from '../../../app/components/BodyText'
import Selectable from '../../../app/components/Selectable'
import { SignUpFormState } from '../../types'

import styles from './AgreeToTermsCheckBox.module.scss'

export default function AgreeToTermsCheckBox() {
  const { t } = useTranslation('common')

  const fieldName: FieldName<SignUpFormState> = 'agreedToTerms'

  const { watch, setValue } = useFormContext<SignUpFormState>()

  const agreedToTerms = watch(fieldName)

  function handleToggleAgreeToTerms() {
    setValue(fieldName, !agreedToTerms)
  }

  return (
    <div className={styles.container}>
      <Selectable
        containerClassName={styles.checkbox}
        checked={agreedToTerms}
        onClick={handleToggleAgreeToTerms}
      />
      <span>{t('auth.agreeTo')}</span>
      <Link href="https://ancon.io/terms-and-conditions/" target="blank">
        <BodyText color="body-0">{t('auth.termsAndConditions')}</BodyText>
      </Link>
    </div>
  )
}
