import { SignalRHubName, SignalRSignalName } from '@ancon/wildcat-types'
import { useCallback, useEffect } from 'react'
import noop from 'lodash/noop'

import useSignalR from '../features/signalr/hooks/useSignalR'
import useAppDispatch from '../store/hooks/useAppDispatch'
import useAppSelector from '../store/hooks/useAppSelector'
import { clientContextCustomerIdSelector } from '../features/clientContext/store/clientContextSelectors'
import { signOut } from '../features/auth/store/authThunks'

export default function CustomerEvents() {
  const dispatch = useAppDispatch()
  const { isHubConnected, subscribe, join, leave } = useSignalR(
    SignalRHubName.CustomerHub,
  )

  const customerId = useAppSelector(clientContextCustomerIdSelector)!

  useEffect(() => {
    async function joinGroup() {
      await join(customerId)
    }

    if (customerId && isHubConnected) {
      joinGroup()
    }

    return () => {
      leave(customerId)
    }
  }, [customerId, isHubConnected, join, leave])

  const handleCustomerAnonymized = useCallback(async () => {
    dispatch(signOut()).unwrap().catch(noop)
  }, [dispatch])

  useEffect(() => {
    const unsubscribe = subscribe(
      SignalRSignalName.CustomerAnonymizedEvent,
      handleCustomerAnonymized,
    )

    return unsubscribe
  }, [handleCustomerAnonymized, subscribe])

  return null
}
