import { OrderFormat } from '@ancon/wildcat-types'

export const outletsFilterOrderFormats =
  OrderFormat.EatIn |
  OrderFormat.TakeAway |
  OrderFormat.Delivery |
  OrderFormat.TableOrder |
  OrderFormat.BoxPickup

export const defaultPaginatedResponse = {
  items: [],
  meta: {
    count: 0,
    offset: 0,
  },
}

export const orderFormatTextMap = {
  [OrderFormat.EatIn]: 'orderFormat.eatIn',
  [OrderFormat.TakeAway]: 'orderFormat.takeAway',
  [OrderFormat.Delivery]: 'orderFormat.delivery',
  [OrderFormat.TableOrder]: 'orderFormat.tableOrder',
  [OrderFormat.BoxPickup]: 'orderFormat.boxPickup',
}

export const AllOrderFormatsArray = [
  OrderFormat.EatIn,
  OrderFormat.TakeAway,
  OrderFormat.Delivery,
  OrderFormat.TableOrder,
  OrderFormat.BoxPickup,
]

export const OutletsPaginationLimit = {
  Initial: 30,
  LoadMore: 20,
}
