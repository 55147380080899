import { TicketItemStatus } from '@ancon/wildcat-types'
import isTicketItemReady from '@ancon/wildcat-utils/ticketItem/isTicketItemReady'

export default function isCheckoutItemReady(item: {
  ticketItem: {
    status: TicketItemStatus
  } | null
}) {
  return isTicketItemReady(item.ticketItem)
}
